import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { WhisperSpinner } from "react-spinners-kit";
import { FiInfo } from "react-icons/fi";

import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import Button from "../../../components/Button";
import { Alert, Container, LoadingContainer } from "./styles";

export default function DialogSendWarning({ handleClose, open }) {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [areaTypes, setAreaTypes] = useState([]);
  const [areaType, setAreaType] = useState(null);
  const [cycleId, setCycleId] = useState(null);
  const [cycles, setCycles] = useState([]);
  const [filteredCycles, setFilteredCycles] = useState(null);

  const loadCyclesAndAreaTypes = useCallback(async () => {
    setLoading(true);
    const [areaTypesResponse, cyclesResponse] = await Promise.all([
      await api.get("api/area-types"),
      await api.get("api/promotioncycle"),
    ]);
    setAreaTypes(areaTypesResponse.data);
    setCycles(
      cyclesResponse.data.map((c) => {
        return {
          ...c,
          displayName: `(${c.areaType.description}) - ${c.period}Q - ${c.currentYear}`,
        };
      })
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    loadCyclesAndAreaTypes();
  }, [loadCyclesAndAreaTypes]);

  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await api.post(`/api/promotion-actions/${cycleId}/emails`);
      addToast({
        type: "success",
        title: "Emails has been sent!",
      });
      setLoading(false);
      handleClose();
    } catch {
      addToast({
        type: "error",
        title: "Something went wrong...",
      });
    }
  }, [cycleId, addToast, handleClose]);

  return (
    <Container anchor="right" open={open} onClose={handleClose}>
      {loading && (
        <LoadingContainer>
          <WhisperSpinner size={50} color="#8b0304" backColor="#8b0304" />
        </LoadingContainer>
      )}
      <Box m={2} minWidth={500}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: "15px" }}
        >
          Send action plans emails
        </Typography>

        <Alert>
          <FiInfo size={18} />
          <p>
            <span>Caution!</span>
            <br />
            If you confirm it, a email message will be sent to everyone
            <br />
            involved in action plans for this cycle.
          </p>
        </Alert>

        <FormControl style={{ width: "100%", marginTop: 15 }}>
          <InputLabel id="area-type-label">
            Click to select a area type
          </InputLabel>
          <Select
            labelId="area-type-label"
            id="area-type-select"
            value={areaType}
            onChange={(e) => {
              setAreaType(e.target.value);
              setFilteredCycles(
                cycles?.filter((c) => c.idAreaType === e.target.value)
              );
              setCycleId(null);
            }}
          >
            {areaTypes?.map((area) => (
              <MenuItem key={area.id} value={area.id}>
                {area.description}
                {areaType === area.id && " - selected"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          style={{ width: "100%", marginTop: 15 }}
          disabled={!areaType}
        >
          <InputLabel id="cycle-label">Click to select a cycle</InputLabel>
          <Select
            labelId="cycle-label"
            id="cycle-select"
            value={cycleId}
            onChange={(e) => setCycleId(e.target.value)}
          >
            {filteredCycles?.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.displayName}
                {cycleId === status.id && " - selected"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ position: "absolute", bottom: 15, right: 15 }}>
          <Button onClick={handleClose} style={{ marginRight: 15 }}>
            Cancel
          </Button>

          {!confirmed ? (
            <Button
              onClick={() =>
                cycleId
                  ? setConfirmed(true)
                  : addToast({
                      type: "error",
                      title: "Select a cycle",
                    })
              }
              disabled={loading || !cycleId}
              primary
            >
              Send all emails
            </Button>
          ) : (
            <Button
              onClick={handleConfirm}
              primary
              style={{ backgroundColor: "#ffc107" }}
            >
              <FiInfo size={16} style={{ marginRight: 12 }} />
              Do you confirm it?
            </Button>
          )}
        </div>
      </Box>
    </Container>
  );
}
