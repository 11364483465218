import styled from "styled-components";

export const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 40px;

  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
  }

  h1.title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    filter: brightness(0.9);
  }

  .headline-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p {
    color: #333;
    font-weight: 300;
    line-height: 1;
  }
  p strong {
    font-weight: bold;
  }

  .red {
    color: #8b0304;
  }

  .headline {
    font-size: 1rem;
  }

  .subtitle {
    font-size: 0.75rem;
  }

  .hideable {
    overflow: hidden;
    transition: width 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: flex;
    gap: 24px;
    padding: 1px;
  }
`;

export const PopoverDiv = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .wrapper {
    text-align: center;
    line-height: 1;
  }
  .period {
    font-weight: bold;
  }
`;
