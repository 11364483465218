import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Button from "../../../../../components/Button";
import api from "../../../../../services/api";

export default function DialogLockUnlockEvaluations({
  open,
  handleClose,
  cycleId,
  areaTypeId,
}) {
  console.log(cycleId, areaTypeId);
  const [lockOption, setLockOption] = useState("lock");

  const handleLockOptionChange = (event) => {
    setLockOption(event.target.value);
  };

  const handleLockUnlockAll = async () => {
    try {
      const isLock = lockOption === "lock";
      await api.put(
        `/api/evaluations/lock-unlock/${cycleId}/${areaTypeId}/${isLock}`
      );

      console.log(
        `All evaluations ${isLock ? "locked" : "unlocked"} for cycle:`,
        cycleId
      );

      handleClose();
    } catch (error) {
      console.error("Error during lock/unlock action:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Lock/Unlock All Evaluations</DialogTitle>
      <DialogContent>
        <RadioGroup value={lockOption} onChange={handleLockOptionChange}>
          <FormControlLabel
            value="lock"
            control={<Radio />}
            label="Lock all evaluations"
          />
          <FormControlLabel
            value="unlock"
            control={<Radio />}
            label="Unlock all evaluations"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleLockUnlockAll} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
