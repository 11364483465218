import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";

import master from "../services/master";

import { useToast } from "../hooks/toast";

import { useAuth } from "../hooks/auth";
import pdsGroups from "../config/pdsGroups";

export const ValueContext = createContext();

export const ValueProvider = ({ children }) => {
  const { addToast } = useToast();

  const [masterData, setMasterData] = useState({
    data: [],

    isLoading: false,
  });

  const { hasScope } = useAuth();

  const governanceAreas = useMemo(
    () => [
      { Name: "Co-Management" },

      { Name: "Sales Cell" },

      { Name: "Delivery Cell" },

      { Name: "Professional Development Cell" },

      { Name: "Tech & Digital Lab" },
    ],

    []
  );

  const loadMasterData = useCallback(async () => {
    try {
      setMasterData({ isLoading: true });

      const [professionals, projects, offices, practices] = await Promise.all([
        await master.get("master/professionals", {
          params: {
            where: {
              IsAnyStatus:
                hasScope(pdsGroups.Admin) ||
                window.location.pathname.includes("toevaluate"),
            },
          },
        }),

        await master.get("master/projects"),

        await master.get("master/offices"),

        await master.get("master/practices"),
      ]);

      const projectsWithDisplayName = projects.data.map((project) => ({
        ...project,
        displayName: `${project.ProjectCode} - ${project.ProjectName}`,
      }));

      setMasterData({
        professionals: professionals.data,

        projects: projectsWithDisplayName,

        offices: offices.data,

        practices: practices.data,

        governanceAreas: governanceAreas,

        isLoading: false,
      });
    } catch (err) {
      addToast({
        type: "error",

        title: "Master data couldn't be loaded. Try again later",
      });

      throw err;
    }
  }, [hasScope, governanceAreas, addToast]);

  const memoizedLoadMasterData = useMemo(
    () => loadMasterData,

    [loadMasterData]
  );

  useEffect(() => {
    memoizedLoadMasterData();
  }, [memoizedLoadMasterData]);

  const contextValue = {
    master: masterData,
  };

  return (
    <ValueContext.Provider value={contextValue}>
      {children}
    </ValueContext.Provider>
  );
};
