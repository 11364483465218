import React, { useState, useMemo, useEffect } from "react";
import { Container } from "./styles";
import Report from "./Report";
import Cycles from "./Evaluation/Cycles/AdminEvaluationsCycles";
import Structure from "./Evaluation/Structure";
import AreaType from "./Evaluation/AdminEvaluationsAreaType";
import EvaluationStatus from "./Evaluation/AdminEvaluationStatus";
import Field from "./Promotion/AdminPromotionField";
import Cycle from "./Promotion/AdminPromotionCycle";
import Status from "./Promotion/AdminPromotionStatus";
import AdminDoDontClassifications from "./DoDont/AdminDoDontClassifications";
import AdminDoDontSkillTypes from "./DoDont/AdminDoDontSkillTypes";
import AdminDoDontVaccine from "./DoDont/AdminDoDontVaccine";
import AdminCounselingFieldTypes from "./Counseling/AdminCounselingFieldTypes";
import CounselingConclusions from "./Counseling/CounselingConclusions";
import pdsGroups from "../../config/pdsGroups";
import { useAuth } from "../../hooks/auth";
export default function Admin({ path }) {
  const [activeTab, setActiveTab] = useState(0);
  const { user, hasScope } = useAuth();
  const [allocationPermission, setAllocationPermission] = useState(false);
  const [adminPermission, setAdminPermission] = useState(false);

  useEffect(() => {
    setAllocationPermission(hasScope(pdsGroups.Allocation));
    setAdminPermission(hasScope(pdsGroups.Admin));
  }, [hasScope]);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabs = useMemo(() => {
    switch (path) {
      case "evaluations":
        return [
          {
            label: "Reports",
            component: <Report section="evaluations" />,
            visible: adminPermission,
          },
          {
            label: "Cycles",
            component: <Cycles />,
            visible: adminPermission,
          },
          {
            label: "Structure",
            component: <Structure />,
            visible: adminPermission,
          },
          {
            label: "Area Types",
            component: <AreaType />,
            visible: adminPermission,
          },
          {
            label: "Status Configuration",
            component: <EvaluationStatus />,
            visible: adminPermission,
          },
        ];
      case "promotions":
        return [
          {
            label: "Reports",
            component: <Report section="promotions" />,
            visible: adminPermission,
          },
          {
            label: "Field Configuration",
            component: <Field />,
            visible: adminPermission,
          },
          {
            label: "Cycles",
            component: <Cycle />,
            visible: adminPermission,
          },
          {
            label: "Status Configuration",
            component: <Status />,
            visible: adminPermission,
          },
        ];
      case "dosanddonts":
        return [
          {
            label: "Reports",
            component: <Report section="dosanddonts" />,
            visible: allocationPermission || adminPermission,
          },
          {
            label: "Classifications",
            component: <AdminDoDontClassifications />,
            visible: adminPermission,
          },
          {
            label: "Skill Types",
            component: <AdminDoDontSkillTypes />,
            visible: adminPermission,
          },
          {
            label: "Vaccines",
            component: <AdminDoDontVaccine />,
            visible: adminPermission,
          },
        ];
      case "counseling":
        return [
          {
            label: "Reports",
            component: <Report section="counseling" />,
            visible: adminPermission,
          },
          {
            label: "Field Configuration",
            component: <AdminCounselingFieldTypes />,
            visible: adminPermission,
          },
          {
            label: "Counseling Conclusions",
            component: <CounselingConclusions />,
            visible: adminPermission,
          },
        ];
      case "mentorint":
        return [
          {
            label: "Reports",
            component: <Report section="mentorint" />,
            visible: allocationPermission || adminPermission,
          },
        ];
      case "tutorship":
        return [
          {
            label: "Reports",
            component: <Report section="tutorship" />,
            visible: allocationPermission || adminPermission,
          },
        ];
      default:
        return [];
    }
  }, [path, allocationPermission, adminPermission]);

  return (
    <Container>
      <div className="tabs">
        {tabs
          .filter((x) => x.visible)
          .map((tab, index) => (
            <div
              key={index}
              onClick={() => handleTabClick(index)}
              className={`tab ${activeTab === index ? "active" : ""}`}
            >
              {tab.label}
            </div>
          ))}
      </div>

      {tabs
        .filter((x) => x.visible)
        .map((tab, index) => (
          <div
            className={`tab-content ${activeTab === index ? "active" : ""}`}
            key={index}
          >
            {tab.component}
          </div>
        ))}
    </Container>
  );
}
