import styled, { css } from "styled-components";

export const Container = styled.div`
  font-weight: lighter;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;

  ${({ isSidePanelFullScreen }) =>
    isSidePanelFullScreen &&
    css`
      flex-direction: row;
      justify-content: space-between;
    `}

  .int-text-area {
    flex: 1;
  }

  h3 {
    font-size: 0.75rem;
    margin-bottom: 1em;
  }

  h3.conclusion {
    font-size: 0.875rem;
    margin-bottom: 1em;
  }

  strong {
    font-weight: bold;
  }

  .key-messages {
    display: flex;
    justify-content: center;
    flex: 1;
    gap: 20px;

    .key-message {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 7px;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      padding: 10px 15px;
    }

    strong {
      font-size: 1rem;
    }

    h3 {
      font-size: 0.875rem;
      color: #ccc;
      margin: 0;
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border-color: #f5f5f5;
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }

  .pillars {
    flex: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .overview-grade {
    background-color: white;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    line-height: 1;
    padding: 15px;
    flex: 200px;

    &.grouped {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      flex: 1 0 100%;
    }

    .int-select-box {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
    }

    label {
      font-size: 0.75rem;
      margin: 0;
      flex: 1 0 70%;
      font-weight: bold;
    }

    select {
      width: auto;
    }

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: bold;
    }

    .header label {
      font-size: 1rem;
    }

    .competences {
      width: 100%;
      gap: 20px;
      display: flex;
      flex-direction: column;
    }

    .competence {
      width: 100%;
      gap: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .competence label {
      margin: 0;
      flex: 1 0 80%;
    }
  }
`;

export const GridContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100px;
  & > div {
    box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
    border-radius: 7px;
    overflow: hidden;
  }
  .dx-datagrid {
    color: #333;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
    border: none;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    color: #999;
    font-size: 0.75rem;
    font-weight: 300;
  }

  .dx-datagrid-rowsview .dx-row {
    cursor: pointer;

    :hover {
      background-color: #f5f5f5ff;
    }
    :active {
      background-color: #d9d9d9ff;
    }
  }

  .dx-datagrid-rowsview .dx-row .dx-freespace-row {
    cursor: default;

    :hover {
      background-color: transparent;
    }
    :active {
      background-color: transparent;
    }
  }

  .dx-datagrid .dx-row > td {
    font-size: 0.75rem;
    font-weight: 400;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #f5f5f5;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-data-row > td {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 15px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 0;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0 0 30px rgba(0 0 0 / 15%);
    background: #8b0304;
    color: white;

    :hover {
      background: #b80304;
    }
    :active {
      background: #a80304;
    }
  }
  .office-icon {
    width: 24px;
    height: 24px;
  }
`;
