import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, GridContainer } from "./styles";
import { DataGrid } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import api from "../../../services/api";
import master from "../../../services/master";
import DataSource from "devextreme/data/data_source";
import {
  Column,
  Editing,
  Form,
  HeaderFilter,
  Lookup,
  Paging,
  Popup,
  Sorting,
} from "devextreme-react/data-grid";
import { PromotionStatusCell } from "../../EvaluationList/styles";
import PhotoCell from "../../EvaluationList/CellRender/PhotoCell";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { FiPlus, FiSearch } from "react-icons/fi";
import Button from "../../../components/Button";
import { Item } from "devextreme-react/form";
import { TextArea } from "devextreme-react";
import { useAuth } from "../../../hooks/auth";
import pdsGroups from "../../../config/pdsGroups";

export default function ActionPlans({ promotion, isSidePanelFullScreen }) {
  const dataGridRef = useRef(null);
  const { hasScope, user } = useAuth();
  const [actionPlansSource, setActionPlansSource] = useState([]);
  const [openActionPlan, setOpenActionPlan] = useState(false);
  const [actionPlanText, setActionPlanText] = useState("");
  const [openResponsibleComments, setOpenResponsibleComments] = useState(false);
  const [responsibleCommentsText, setResponsibleCommentsText] = useState("");

  const isAdmin = useMemo(() => hasScope(pdsGroups.Admin), [hasScope]);

  const loadActionPlans = useCallback(async () => {
    const store = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get(
          `api/promotion-actions?idPromotion=${promotion.id}`
        );
        return data;
      },
      insert: async (values) => {
        const { data } = await api.post("api/promotion-actions", {
          ...values,
          idPromotion: promotion.id,
        });
        return data;
      },
      update: async (key, values) => {
        const { data } = await api.put(`api/promotion-actions/${key}`, {
          ...values,
          idPromotion: promotion.id,
        });
        return data;
      },
    });

    setActionPlansSource(
      new DataSource({
        store: store,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [promotion.id]);

  const [promotionActionStatuses] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/promotion-action-status");
        return data;
      },
    }),
    paginate: true,
  });

  const [professionals] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await master.get("master/professionals");
        return data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    loadActionPlans();
  }, [loadActionPlans]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <GridContainer>
        <DataGrid
          ref={dataGridRef}
          dataSource={actionPlansSource}
          height="100%"
          allowColumnResizing
          onRowUpdating={(options) => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
          onInitNewRow={(e) => {
            e.data.idStatus = 1;
          }}
          onEditorPreparing={(e) => {
            if (
              e.dataField === "idStatus" ||
              e.dataField === "responsibleComments"
            ) {
              e.editorOptions.disabled =
                !isAdmin && user.userId !== e.row.data.idActionResponsible;
            }
          }}
        >
          <Paging defaultPageSize={10} enabled />
          <HeaderFilter visible allowSearch />
          <Sorting mode="multiple" />
          <Column
            dataField="idStatus"
            caption="Status"
            editorType="dxSelectBox"
            width={120}
            cellRender={(e) => (
              <PromotionStatusCell status={e.data.promotionActionStatus?.id}>
                <PhotoCell data={promotion.professional} />
                {e.data.promotionActionStatus?.description}
              </PromotionStatusCell>
            )}
            alignment="left"
            editorOptions={{
              disabled: !isAdmin && user.userId !== promotion.idResponsible,
            }}
          >
            <Lookup
              dataSource={promotionActionStatuses}
              valueExpr="id"
              displayExpr="description"
            />
          </Column>
          <Column
            dataField="isGroupResponsible"
            dataType="boolean"
            alignment="center"
            editorOptions={{ disabled: !isAdmin }}
          />
          <Column
            dataField="idActionResponsible"
            caption="Responsible"
            alignment="center"
            editorType="dxSelectBox"
            cellRender={(e) => <PhotoCell data={e.data.professional} />}
            editorOptions={{ disabled: !isAdmin }}
          >
            <Lookup
              dataSource={professionals}
              valueExpr="ID"
              displayExpr="Name"
            />
          </Column>

          <Column
            dataField="actionText"
            caption="Action Plan"
            alignment="center"
            allowFiltering={false}
            allowSorting={false}
            editorOptions={{ disabled: !isAdmin }}
            cellRender={(e) => {
              return isSidePanelFullScreen ? (
                <div style={{ marginBottom: "12px" }}>
                  <TextArea
                    className="styled-textarea"
                    style={{ backgroundColor: "#EFEFEF" }}
                    defaultValue={e.data.actionText}
                    allow
                  />
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setActionPlanText(e.data.actionText);
                    setOpenActionPlan(true);
                  }}
                >
                  <FiSearch size={14} />
                </IconButton>
              );
            }}
          />

          <Column
            dataField="responsibleComments"
            caption="Responsible Comments"
            alignment="center"
            allowFiltering={false}
            allowSorting={false}
            editorOptions={{
              disabled: !isAdmin && user.userId !== promotion.idResponsible,
            }}
            cellRender={(e) => {
              return isSidePanelFullScreen ? (
                <div style={{ marginBottom: "12px" }}>
                  <TextArea
                    className="styled-textarea"
                    style={{ backgroundColor: "#EFEFEF" }}
                    defaultValue={e.data.responsibleComments}
                  />
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setResponsibleCommentsText(e.data.responsibleComments);
                    setOpenResponsibleComments(true);
                  }}
                >
                  <FiSearch size={14} />
                </IconButton>
              );
            }}
          />

          <Editing mode="popup" allowUpdating={true} allowAdding={isAdmin}>
            <Popup
              title="Action Plan"
              showTitle={true}
              width={1000}
              height={550}
            />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="idStatus" />
                <Item dataField="idActionResponsible" />
              </Item>
              <Item dataField="isGroupResponsible" colSpan={2} />
              <Item
                dataField="actionText"
                editorType="dxTextArea"
                colSpan={2}
              />
              <Item
                dataField="responsibleComments"
                editorType="dxTextArea"
                colSpan={2}
              />
            </Form>
          </Editing>
        </DataGrid>
      </GridContainer>

      {openActionPlan && (
        <Dialog
          open={openActionPlan}
          onClose={() => setOpenActionPlan(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Action Plan</DialogTitle>
          <DialogContent>
            <strong style={{ fontWeight: 500 }}>
              {actionPlanText || "No action plans"}
            </strong>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenActionPlan(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {openResponsibleComments && (
        <Dialog
          open={openResponsibleComments}
          onClose={() => setOpenResponsibleComments(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Responsible Comments</DialogTitle>
          <DialogContent>
            <strong style={{ fontWeight: 500 }}>
              {responsibleCommentsText || "No comments"}
            </strong>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenResponsibleComments(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}
