import styled, { css } from "styled-components";

const statusesColors = {
  1: "#ffc107",
  2: "#F44336",
  3: "#4CAF50",
  4: "#673AB7",
  5: "#00BCD4",
  6: "#795548",
};

export const Container = styled.div`
  position: relative;
  flex: 1;
  height: 100px;
  & > div {
    box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
    border-radius: 7px;
    overflow: hidden;
  }
  .dx-datagrid {
    color: #333;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
    border: none;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    color: #999;
    font-size: 0.75rem;
    font-weight: 300;
  }

  /* .dx-datagrid-rowsview .dx-row {

    :hover {
      background-color: #f5f5f5ff;
    }
    :active {
      background-color: #d9d9d9ff;
    }
  } */

  .dx-datagrid .dx-row > td {
    font-size: 0.75rem;
    font-weight: 400;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #f5f5f5;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-data-row > td {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 0;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0 0 30px rgba(0 0 0 / 15%);
    background: #8b0304;
    color: white;

    :hover {
      background: #b80304;
    }
    :active {
      background: #a80304;
    }
  }
  .office-icon {
    width: 24px;
    height: 24px;
  }
`;

export const Button = styled.button`
  outline: none;
  border: none;
  border-radius: 0;
  min-width: 100%;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  background-color: #8b0304;
  min-height: 28px;
  padding: 0;
`;

export const StatusCell = styled.div`
  ${(props) => css`
    background-color: ${statusesColors[props.status]};
  `};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 300px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  gap: 5px;

  & img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 3px;
    filter: grayscale(1) brightness(0.95);
  }
`;

export const Photo = styled.img`
  max-width: 48px;
  border-radius: 50%;
`;
