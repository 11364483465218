import React, { useState, useCallback } from "react";

export const TriggerContext = React.createContext();

export const TriggerProvider = ({ children }) => {
  const [trigger, setTrigger] = useState(false);

  const triggerFunction = useCallback(() => {
    setTrigger((prevTrigger) => !prevTrigger);
  }, []);

  const [triggers, setTriggers] = useState({});

  const triggersFunction = useCallback((triggerName) => {
    setTriggers((prevTriggers) => ({
      ...prevTriggers,
      [triggerName]: !prevTriggers[triggerName],
    }));
  }, []);

  return (
    <TriggerContext.Provider
      value={{ trigger, triggerFunction, triggers, triggersFunction }}
    >
      {children}
    </TriggerContext.Provider>
  );
};
