import React, { useEffect, useState } from "react";

import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import api from "../../../services/api";
import { DataGridWrapper } from "../styles";

export default function AdminEvaluationsAreaType() {
  const [areaTypeSource, setAreaTypeSource] = useState();

  useEffect(() => {
    const areaTypeStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/area-types");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/area-types", data);
      },
      update: async (id, data) => {
        await api.put(`/api/area-types/${id}`, data);
      },
      remove: async (id) => {
        await api.delete(`/api/area-types/${id}`);
      },
    });

    setAreaTypeSource(
      new DataSource({
        store: areaTypeStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, []);

  return (
    <DataGridWrapper>
      <DataGrid
        dataSource={areaTypeSource}
        showBorders={false}
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
        height={550}
      >
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="description" />
        <Column dataField="isWeightedAverage" dataType="boolean" />
      </DataGrid>
    </DataGridWrapper>
  );
}
