import React, { useCallback, useEffect, useState } from "react";

import DataGrid, {
  Column,
  Editing,
  MasterDetail,
  RowDragging,
  Sorting,
  Summary,
  TotalItem,
  Button,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import api from "../../../../../services/api";

import CompetenceMasterDetail from "../CompetenceGrid";
import DialogPermission from "../DialogPermission";
import { DataGridWrapper } from "../../../styles";

export default function PillarGrid({
  idAreaType: _idAreaType,
  isWeighted: _isWeighted,
}) {
  const [pillarSource, setPillarSource] = useState();
  const [pillarStore, setPillarStore] = useState();
  const [idAreaType] = useState(_idAreaType);
  const [isWeighted] = useState(_isWeighted);
  const [openPermission, setOpenPermission] = useState(false);
  const [selectedKey, setSelectedKey] = useState(0);

  useEffect(() => {
    setSelectedKey(0);

    const pillarStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("api/pillars", {
          params: {
            idAreaType,
          },
        });
        return response.data;
      },
      insert: async (data) => {
        await api.post("api/pillars", {
          ...data,
          idAreaType,
        });
      },
      update: async (id, data) => {
        await api.put(`api/pillars/${id}`, data);
      },
      remove: async (id) => {
        await api.delete(`api/pillars/${id}`);
      },
    });

    setPillarStore(pillarStore);

    setPillarSource(
      new DataSource({
        store: pillarStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [idAreaType]);

  const handleDrag = useCallback(
    async (e) => {
      await pillarStore.update(e.itemData.id, { order: e.toIndex + 1 });
      await e.component.refresh();
    },
    [pillarStore]
  );

  const percentCell = useCallback((e) => <p>{e.value}%</p>, []);

  const handleOpenPermission = useCallback((e) => {
    setOpenPermission(true);
    setSelectedKey(e.row.key);
  }, []);
  return (
    <DataGridWrapper>
      <DataGrid
        dataSource={pillarSource}
        showBorders={false}
        key="id"
        height={550}
      >
        <RowDragging
          allowReordering
          onReorder={handleDrag}
          showDragIcons
          dropFeedbackMode="push"
        />
        <Sorting mode="none" />
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="name" caption="Pillar" />
        <Column dataField="isActive" dataType="boolean" />
        <Column dataField="tooltip" />
        <Column dataField="order" visible={false} />
        <Column dataField="idAreaType" visible={false} />
        <Column
          dataField="fieldWeight"
          visible={isWeighted}
          cellRender={percentCell}
        />
        <Column type="buttons" width={110}>
          <Button name="edit" />
          {isWeighted && (
            <Button
              hint="Permissions"
              icon="key"
              onClick={handleOpenPermission}
            />
          )}
        </Column>
        <MasterDetail
          enabled
          component={(data) => (
            <CompetenceMasterDetail data={data.data} isWeighted={isWeighted} />
          )}
        />
        <Summary>
          <TotalItem
            column="fieldWeight"
            summaryType="sum"
            displayFormat="Sum: {0}%"
          />
        </Summary>
      </DataGrid>

      {openPermission && isWeighted && (
        <DialogPermission
          open={openPermission}
          handleClose={() => setOpenPermission(false)}
          id={selectedKey}
          type="idPillar"
        />
      )}
    </DataGridWrapper>
  );
}
