import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TriggerContext } from "../../context/TriggerProvider";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import SidePanelLoader from "../ContentLoader";
import SidePanelCounselingHeader from "./Header";
import ActionFields from "./Tabs/ActionFields";
import Conclusion from "./Tabs/Conclusion";
import { Container } from "./styles";
import { useMemo } from "react";
import pdsGroups from "../../config/pdsGroups";
import { useAuth } from "../../hooks/auth";

export default function CounselingSidePanel({
  path,
  isSidePanelOpen,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
}) {
  const { id, year } = useParams();
  const { user, hasScope } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);
  const [actionFieldTypes, setActionFieldTypes] = useState([]);
  const [counselingYears, setCounselingYears] = useState([]);
  const [counselingData, setCounselingData] = useState([]);
  const [activeCounselingConclusions, setActiveCounselingConclusions] =
    useState([]);
  const [isCounselingLoading, setCounselingIsLoading] = useState(false);
  const [
    isCounselingYearsActionFieldTypesConclusionsLoading,
    setIsCounselingYearsActionFieldTypesConclusionsLoading,
  ] = useState(true);

  const { trigger } = useContext(TriggerContext);

  const { addToast } = useToast();
  const history = useHistory();

  const loadCounseling = useCallback(async () => {
    try {
      setCounselingIsLoading(true);
      const { data } = await api.get(`/api/counseling/${id}`, {
        params: { year: year },
      });

      setCounselingData(data);
    } catch (err) {
      addToast({
        type: "error",
        title: "Something went wrong when loading data.",
      });
      history.pushState(`/${path}/${id}/${year}}`);
      setIsSidePanelOpen(false);
      throw err;
    } finally {
      setCounselingIsLoading(false);
    }
  }, [addToast, history, id, path, setIsSidePanelOpen, year]);

  const loadCounselingYearsActionFieldTypesConclusions =
    useCallback(async () => {
      try {
        setIsCounselingYearsActionFieldTypesConclusionsLoading(true);
        const [
          responseActionFieldTypes,
          responseYears,
          responseCounselingConclusions,
        ] = await Promise.all([
          api.get("/api/action-field-types"),
          api.get(`/api/counseling/${id}/years`),
          api.get(`/api/counseling-conclusions`),
        ]);

        setActionFieldTypes(responseActionFieldTypes.data);
        setCounselingYears(responseYears.data);
        setActiveCounselingConclusions(
          responseCounselingConclusions.data.filter((x) => x.isActive)
        );
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong when loading data.",
        });
        throw err;
      } finally {
        setIsCounselingYearsActionFieldTypesConclusionsLoading(false);
      }
    }, [addToast, id]);

  useEffect(() => {
    loadCounseling();
    setIsSidePanelOpen(true);
  }, [id, loadCounseling, setIsSidePanelOpen, year, trigger]);

  useEffect(() => {
    loadCounselingYearsActionFieldTypesConclusions();
  }, [id, loadCounselingYearsActionFieldTypesConclusions]);

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      visible: true,
      label: "Conclusions",
      content: (
        <Conclusion
          isAdmin={isAdmin}
          counselingData={counselingData}
          isSidePanelFullScreen={isSidePanelFullScreen}
          activeCounselingConclusions={activeCounselingConclusions}
        />
      ),
    },
    {
      visible: true,
      label: "Counselee",
      content: (
        <ActionFields
          counselingData={counselingData}
          actionFields={actionFieldTypes.flatMap((actionFieldType) =>
            !actionFieldType.isCounselorField
              ? actionFieldType.actionFields
                  .filter((field) => field.isActive)
                  .map((field) =>
                    !field.description
                      ? { ...field, description: actionFieldType.description }
                      : field
                  )
              : []
          )}
          isSidePanelFullScreen={isSidePanelFullScreen}
          allowEdit={
            counselingData.idCounselingStatus === 1 &&
            (counselingData.idProfessional === user.userId || isAdmin)
          }
        />
      ),
    },
    {
      visible: counselingData.idCounselor === user.userId || isAdmin,
      label: "Counselor",
      content: (
        <ActionFields
          counselingData={counselingData}
          actionFields={actionFieldTypes.flatMap((actionFieldType) =>
            actionFieldType.isCounselorField
              ? actionFieldType.actionFields
                  .filter((field) => field.isActive)
                  .map((field) =>
                    !field.description
                      ? { ...field, description: actionFieldType.description }
                      : field
                  )
              : []
          )}
          isSidePanelFullScreen={isSidePanelFullScreen}
          allowEdit={
            counselingData.idCounselingStatus === 1 &&
            (counselingData.idCounselor === user.userId || isAdmin)
          }
        />
      ),
    },
  ];

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {isCounselingLoading ||
      isCounselingYearsActionFieldTypesConclusionsLoading ? (
        <SidePanelLoader width="100%" />
      ) : (
        <>
          <SidePanelCounselingHeader
            isSidePanelOpen={isSidePanelOpen}
            setIsSidePanelOpen={setIsSidePanelOpen}
            isSidePanelFullScreen={isSidePanelFullScreen}
            setIsSidePanelFullScreen={setIsSidePanelFullScreen}
            counselingData={counselingData}
            counselingYears={counselingYears}
            isAdmin={isAdmin}
            userId={user.userId}
            path={path}
            loadCounseling={loadCounseling}
          />

          {!isSidePanelFullScreen && (
            <div className="tabs-menu">
              {tabs
                .filter((x) => x.visible)
                .map((tab, index) => (
                  <div
                    key={index}
                    className={activeTab === index ? "active" : ""}
                    onClick={() => {
                      setActiveTab(index);
                    }}
                  >
                    {tab.label}
                  </div>
                ))}
            </div>
          )}

          <div
            className={`content-wrapper ${
              !isSidePanelFullScreen ? "no-scroll" : ""
            }`}
          >
            {tabs
              .filter((x) => x.visible)
              .map((tab, index) => (
                <div
                  key={index}
                  className="content"
                  style={{
                    display: !isSidePanelFullScreen
                      ? activeTab === index
                        ? ""
                        : "none"
                      : "",
                  }}
                >
                  {isSidePanelFullScreen && (
                    <h2 className="separator">{tab.label}</h2>
                  )}

                  {tab.content}
                </div>
              ))}
          </div>
        </>
      )}
    </Container>
  );
}
