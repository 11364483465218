import DataGrid, {
  Column,
  HeaderFilter,
  Lookup,
} from "devextreme-react/data-grid";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import pdsGroups from "../../config/pdsGroups";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import PhotoCell from "../CounselingList/CellRender/PhotoCell";
import ProfessionalLookup from "../ProfessionalLookup";
import DataSource from "devextreme/data/data_source";
import { useHistory } from "react-router-dom";
import { TriggerContext } from "../../context/TriggerProvider";
import { ValueContext } from "../../context/ValueProvider";
import { useToast } from "../../hooks/toast";
import DialogImport from "../../pages/Counseling/List/DialogImport";
import DialogImportKeyMessage from "../../pages/Counseling/List/DialogImportKeyMessage";
import { Container, StatusCell, YearCell } from "../CounselingList/styles";
import CounselingVideoDialog from "./Dialog/CounselingVideoDialog";

export default function CounselingList({ path }) {
  const { master } = useContext(ValueContext);
  const { addToast } = useToast();
  const history = useHistory();
  const { hasScope, user } = useAuth();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);
  const dataGridRef = useRef(null);
  const [openImportCounseling, setOpenImportCounseling] = useState(false);
  const [openImportKeyMessage, setOpenImportKeyMessage] = useState(false);
  const [statuses, setStatuses] = useState();
  const [hasCounselingCurrentYear, setHasCounselingCurrentYear] =
    useState(false);
  const [currentYear] = useState(new Date().getFullYear());
  const [openMoreInformation, setOpenMoreInformation] = useState(false);
  const { trigger } = useContext(TriggerContext);

  const loadStatuses = useCallback(async () => {
    const { data } = await api.get("/api/counseling-status");
    setStatuses(data);
  }, []);

  const counselingSource = useMemo(() => {
    return new DataSource({
      key: "id",
      loadMode: "raw",
      paginate: true,
      load: async () => {
        const { data } = await api.get("/api/counseling/");
        const hasCounseling = data.find(
          (x) => x.idProfessional === user.userId && x.year === currentYear
        );
        setHasCounselingCurrentYear(!!hasCounseling);
        return data;
      },
      insert: async (values) => {
        try {
          if (isAdmin) {
            await api.post("/api/counseling", values);
          } else {
            await api.post("/api/counseling", {
              year: currentYear,
              idProfessional: user.userId,
              idCounselingStatus: 1,
              conclusionKeyMessage: "",
              ...values,
            });
          }
          addToast({
            type: "success",
            title: "Counseling created",
          });
        } catch (err) {
          throw err;
        }
      },
      update: async (key, values) => {
        try {
          await api.put(`/api/counseling/${key}`, values);
          addToast({
            type: "success",
            title: "Counseling updated",
          });
        } catch (err) {
          throw err;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear, trigger]);

  useEffect(() => {
    loadStatuses();
  }, [loadStatuses]);

  const onChangedCounseling = useCallback(() => {
    counselingSource.reload();
  }, [counselingSource]);

  const handleRowClick = useCallback(
    (e) => {
      history.push(`/${path}/${e?.data?.idProfessional}/${e?.data?.year}`);
    },
    [history, path]
  );

  const yearCell = useCallback((e) => {
    return <YearCell>{e.data.year}</YearCell>;
  }, []);

  const statusCell = useCallback((e) => {
    return (
      <StatusCell status={e.data.idCounselingStatus}>
        <PhotoCell data={e.data.professional} />
        {e.data.counselingStatus.description}
      </StatusCell>
    );
  }, []);

  const counselorPhotoCell = useCallback((e) => {
    return <PhotoCell data={e.data.counselor} />;
  }, []);

  const [yearsSource] = useState(
    Array.from({ length: 11 }, (_, i) => currentYear - 5 + i)
  );

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: "Clear Filters",
          elementAttr: { class: "clear-filter" },
          icon: "filter",
          onClick: () => {
            e.component.clearFilter();
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          visible: hasScope([pdsGroups.Admin]),
          hint: "More Information",
          icon: "help",
          onClick: () => {
            setOpenMoreInformation(true);
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          visible: hasScope([pdsGroups.Admin]),
          hint: "Import Counselor",
          icon: "user",
          onClick: () => {
            setOpenImportCounseling(true);
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          visible: hasScope([pdsGroups.Admin]),
          hint: "Import Key Message",
          icon: "detailslayout",
          onClick: () => {
            setOpenImportKeyMessage(true);
          },
        },
      }
    );
  };

  return (
    <Container>
      <DataGrid
        dataSource={counselingSource}
        ref={dataGridRef}
        width="100%"
        height="100%"
        headerFilter={{ visible: true, allowSearch: true }}
        columnAutoWidth
        sorting={{ mode: "multiple" }}
        editing={{
          mode: "popup",
          allowUpdating: isAdmin,
          allowAdding: isAdmin || !hasCounselingCurrentYear,
        }}
        onRowClick={(e) => handleRowClick(e)}
        onToolbarPreparing={onToolbarPreparing}
      >
        <HeaderFilter visible={true} allowSearch={true} />

        <Column
          dataField="idCounselingStatus"
          caption="Status"
          cellRender={statusCell}
          width={230}
          alignment="left"
          editorType="dxSelectBox"
          allowEditing={isAdmin}
        >
          <Lookup
            dataSource={statuses}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField="idProfessional"
          caption="Professional"
          alignment="center"
          editorType="dxSelectBox"
          allowEditing={isAdmin}
          cellRender={(e) => (
            <ProfessionalLookup
              data={e.data.professional}
              name={e.data.professional?.name}
              style={{
                cursor: "pointer",
                placeContent: "center",
                marginLeft: "25%",
              }}
            />
          )}
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          dataField="idCounselor"
          caption="Counselor"
          alignment="center"
          minWidth={200}
          cellRender={counselorPhotoCell}
          editorType="dxSelectBox"
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField="year"
          alignment="center"
          caption="Year"
          allowEditing={isAdmin}
          cellRender={yearCell}
          editorType="dxSelectBox"
          defaultFilterValues={[currentYear]}
        >
          <Lookup
            dataSource={yearsSource}
            displayExpr={(year) => year}
            valueExpr={(year) => year}
          />
        </Column>

        {/* <Column
          dataField="isFilled"
          alignment="center"
          width={120}
          cellRender={hasStatusCell}
          formItem={{ visible: false }}
          allowEditing={false}
        /> */}
        <Column
          dataField="conclusionKeyMessage"
          allowEditing={isAdmin}
          visible={false}
          editorType="dxTextArea"
        />
      </DataGrid>

      <DialogImport
        open={openImportCounseling}
        handleClose={() => setOpenImportCounseling(false)}
        onSubmitted={onChangedCounseling}
      />

      <DialogImportKeyMessage
        open={openImportKeyMessage}
        handleClose={() => setOpenImportKeyMessage(false)}
        onSubmitted={onChangedCounseling}
      />

      <CounselingVideoDialog
        open={openMoreInformation}
        onClose={() => setOpenMoreInformation(false)}
      />
    </Container>
  );
}
