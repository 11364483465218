import styled from "styled-components";

export const RadioGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  & > div {
    display: flex;
    gap: 0.5rem;
  }

  input {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? "default" : "inherit")};
  }
`;
