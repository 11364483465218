import { CircularProgress } from "@material-ui/core";
import { SelectBox, TagBox, CheckBox } from "devextreme-react";
import React, { useMemo } from "react";
import Button from "../../../components/Button";
import { Alert } from "@material-ui/lab";

const ReportGenerator = ({
  reportTypes,
  selectedType,
  setSelectedType,
  section,
  yearsSource,
  year,
  setYear,
  areaTypeSource,
  areaType,
  setAreaType,
  cycleSource,
  cycle,
  setCycle,
  cycles,
  setCycles,
  skillTypeSource,
  skillType,
  setSkillType,
  lastCycleSource,
  lastCycles,
  setLastCycles,
  handleGenerate,
  isGenerating,
}) => {
  const isButtonDisabled = useMemo(() => {
    switch (section) {
      case "evaluations":
        return (
          !selectedType ||
          !areaType ||
          (selectedType === "Evaluation Legacy" ||
          selectedType === "Senior Projects" ||
          selectedType === "Senior Evaluation Delivery" ||
          selectedType === "All Evaluations"
            ? !cycle
            : !cycles)
        );
      case "promotions":
        return (
          !selectedType ||
          !areaType ||
          !cycle ||
          (selectedType === "Pre-Promotion" &&
            (!lastCycles || lastCycles.length < 2))
        );
      case "dosanddonts":
        return selectedType === "Do's & Don'ts skills"
          ? !skillType
          : !selectedType;
      case "counseling":
        return !selectedType || !year;
      case "mentorint":
      case "tutorship":
        return !selectedType;
      default:
        return false;
    }
  }, [
    section,
    selectedType,
    areaType,
    cycle,
    cycles,
    lastCycles,
    skillType,
    year,
  ]);

  return (
    <div className="report-generator">
      <div
        className="fields"
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <SelectBox
          style={{ flex: 1 }}
          dataSource={reportTypes}
          displayExpr="type"
          valueExpr="type"
          placeholder="Select a report type"
          value={selectedType}
          onValueChanged={(e) => setSelectedType(e.value)}
        />
        {section === "counseling" && (
          <SelectBox
            dataSource={yearsSource}
            placeholder="Select year"
            itemRender={(data) => <p>{data}</p>}
            required={true}
            value={year}
            onValueChanged={(e) => setYear(e.value)}
            disabled={!selectedType}
          />
        )}

        {(section === "evaluations" || section === "promotions") && (
          <SelectBox
            style={{ flex: 1 }}
            dataSource={areaTypeSource}
            displayExpr="description"
            valueExpr="id"
            placeholder="Select area"
            searchExpr={["description"]}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={areaType}
            onValueChanged={(e) => setAreaType(e.value)}
            disabled={!selectedType}
          />
        )}

        {section === "evaluations" &&
          selectedType !== "All Evaluations" &&
          selectedType !== "Evaluation Legacy" &&
          selectedType !== "Senior Projects" &&
          selectedType !== "Senior Evaluation Delivery" &&
          selectedType !== "Pre-Promotion" && (
            <TagBox
              style={{ flex: 1 }}
              dataSource={cycleSource}
              displayExpr="displaytype"
              valueExpr="id"
              placeholder="Select cycle"
              searchExpr={["year"]}
              minSearchLength={2}
              showDataBeforeSearch={true}
              searchEnabled={true}
              value={cycles}
              onValueChanged={(e) => setCycles(e.value)}
              disabled={!selectedType || !areaType}
              maxDisplayedTags={1}
            />
          )}

        {(section === "promotions" ||
          (section === "evaluations" &&
            (selectedType === "Evaluation Legacy" ||
              selectedType === "All Evaluations" ||
              selectedType === "Senior Projects" ||
              selectedType === "Senior Evaluation Delivery"))) && (
          <SelectBox
            style={{ flex: 1 }}
            dataSource={cycleSource}
            displayExpr="displaytype"
            valueExpr="id"
            placeholder="Select cycle"
            searchExpr={["year"]}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={cycle}
            onValueChanged={(e) => setCycle(e.value)}
            disabled={!selectedType || !areaType}
          />
        )}

        {selectedType === "Do's & Don'ts skills" && (
          <SelectBox
            dataSource={skillTypeSource}
            displayExpr="description"
            valueExpr="id"
            placeholder="Select skill"
            searchExpr={["description"]}
            minSearchLength={2}
            required={true}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={skillType}
            onValueChanged={(e) => setSkillType(e.value)}
          />
        )}

        {selectedType === "Pre-Promotion" && (
          <TagBox
            style={{ flex: 1 }}
            dataSource={lastCycleSource}
            displayExpr="displaytype"
            valueExpr="id"
            placeholder="Last cycle (select 2 at least)"
            searchExpr={["year"]}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={lastCycles}
            onValueChanged={(e) => setLastCycles(e.value)}
            disabled={!selectedType || !areaType || !cycle}
            maxDisplayedTags={1}
          />
        )}

        <Button
          onClick={handleGenerate}
          primary
          disabled={isGenerating || isButtonDisabled}
        >
          {isGenerating ? (
            <CircularProgress size="1.5em" color="secondary" />
          ) : (
            "Generate"
          )}
        </Button>
      </div>
    </div>
  );
};

export default ReportGenerator;
