import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import { Container } from "./styles";
import {
  BiChat,
  BiCog,
  BiCommentCheck,
  BiGroup,
  BiLogOut,
  BiMedal,
  BiStar,
  BiUser,
  BiListCheck,
  BiBookOpen,
  BiUserVoice,
  BiHome,
} from "react-icons/bi";
import { AdminSubmenu } from "./styles";
import pdsGroups from "../../config/pdsGroups";

export default function Surface({
  setIsSidePanelFullScreen,
  setIsSidePanelOpen,
}) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { signOut, hasScope } = useAuth();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);

  const isPDC = useMemo(() => {
    return hasScope([pdsGroups.PDC]);
  }, [hasScope]);

  const isCorporateLeader = useMemo(() => {
    return hasScope([pdsGroups.CorporateLeaders]);
  }, [hasScope]);

  const isSales = useMemo(() => {
    return hasScope([pdsGroups.Sales]);
  }, [hasScope]);

  const isSenior = useMemo(() => {
    return hasScope([pdsGroups.Manager, pdsGroups.Director, pdsGroups.Partner]);
  }, [hasScope]);
  const isAllocation = useMemo(() => {
    return hasScope([pdsGroups.Allocation]);
  }, [hasScope]);

  const NAVIGATION_ITEMS = [
    {
      title: "Home",
      path: "/home/",
      icon: BiHome,
    },
    {
      title: "My Evaluations",
      path: "/myevaluations/",
      icon: BiUser,
    },
    isSenior || isAdmin || isPDC || isCorporateLeader || isSales
      ? {
          title: "All Evaluations",
          path: "/allevaluations/",
          icon: BiGroup,
        }
      : null,
    { title: "To Evaluate", path: "/toevaluate/", icon: BiStar },
    isSenior || isAdmin || isPDC || isCorporateLeader
      ? {
          title: "Promotions",
          path: "/promotions/",
          icon: BiMedal,
        }
      : null,
    isSenior || isAdmin || isPDC || isCorporateLeader
      ? {
          title: "Action Plans",
          path: "/actionplans/",
          icon: BiListCheck,
        }
      : null,
    { title: "Do's and Don'ts", path: "/dosanddonts/", icon: BiCommentCheck },
    { title: "Counseling", path: "/counseling/", icon: BiUserVoice },
    { title: "Mentorint", path: "/mentorint/", icon: BiChat },
    { title: "Tutorship", path: "/tutorship/", icon: BiBookOpen },
  ];

  const ADMIN_SUBMENU = [
    {
      title: "Evaluations",
      path: "/admin/evaluations/",
      icon: BiGroup,
      visible: isAdmin,
    },
    {
      title: "Promotions",
      path: "/admin/promotions/",
      icon: BiMedal,
      visible: isAdmin,
    },
    {
      title: "Do's and Don'ts",
      path: "/admin/dosanddonts/",
      icon: BiCommentCheck,
      visible: isAdmin || isAllocation,
    },
    {
      title: "Counseling",
      path: "/admin/counseling/",
      icon: BiUserVoice,
      visible: isAdmin,
    },
    {
      title: "Mentorint",
      path: "/admin/mentorint/",
      icon: BiChat,
      visible: isAdmin || isAllocation,
    },
    {
      title: "Tutorship",
      path: "/admin/tutorship/",
      icon: BiBookOpen,
      visible: isAdmin || isAllocation,
    },
  ];

  const handlePush = (link) => {
    history.push(link);
    setIsSidePanelOpen(false);
    setIsSidePanelFullScreen(false);
  };

  const checkCurrentUrl = (urlString) => {
    return history.location.pathname.startsWith(urlString);
  };

  const handleAdminMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAnchorEl(null);
  };

  const isAdminMenuOpen = Boolean(anchorEl);

  return (
    <Container>
      {NAVIGATION_ITEMS.filter((item) => item !== null).map(
        ({ title, path, icon: Icon }, index) => (
          <React.Fragment key={title}>
            <Tooltip title={title} placement="right">
              <div className={`navigation`} onClick={() => handlePush(path)}>
                <Icon color={checkCurrentUrl(path) ? "#8b0304" : ""} />
              </div>
            </Tooltip>
          </React.Fragment>
        )
      )}
      {(isAdmin || isAllocation) && (
        <>
          <Tooltip title="Admin Menu" placement="right">
            <div className={`navigation`} onClick={handleAdminMenuClick}>
              <BiCog color={checkCurrentUrl("/admin/") ? "#8b0304" : ""} />
            </div>
          </Tooltip>
          <Popover
            open={isAdminMenuOpen}
            anchorEl={anchorEl}
            onClose={handleAdminMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{ marginLeft: 20 }}
          >
            <AdminSubmenu>
              {ADMIN_SUBMENU.filter((x) => x.visible).map(
                ({ title, path, icon: Icon }) => (
                  <div
                    key={title}
                    className={`navigation`}
                    onClick={() => {
                      handleAdminMenuClose();
                      handlePush(path);
                    }}
                    style={{
                      backgroundColor: checkCurrentUrl(path) ? "#EEE" : "",
                      color: checkCurrentUrl(path) ? "#8b0304" : "",
                    }}
                  >
                    <Icon color={checkCurrentUrl(path) ? "#8b0304" : ""} />
                    {title}
                  </div>
                )
              )}
            </AdminSubmenu>
          </Popover>
        </>
      )}
      <Tooltip title="Sign out" placement="right">
        <div className={`navigation bottom`} onClick={signOut}>
          <BiLogOut />
        </div>
      </Tooltip>
    </Container>
  );
}
