import styled from "styled-components";

export const Fieldset = styled.fieldset`
  margin-bottom: 28px;
`;

export const Label = styled.label`
  font-weight: 700;
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
