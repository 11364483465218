import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import SignIn from "../../pages/SignIn";

export const PrivateRoute = ({
  component: Component,
  scopes = null,
  title = null,
  ...rest
}) => {
  const { isAuthenticated, hasScope } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && (scopes ? hasScope(scopes) : true) ? (
          <Component {...props} {...rest} title={title} />
        ) : (
          <SignIn {...props} />
        )
      }
    />
  );
};
