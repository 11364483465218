import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  legend,
  label {
    font-weight: bold;
  }

  fieldset.textarea {
    font-size: 0.875rem;
    text-align: justify;
    flex: 1;
    color: #333;
    border-radius: 7px;
    border-width: 1px;
    border-style: solid;
    border-color: #eee;
    margin-top: -10px;
    transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &:focus-within {
      box-shadow: 0 0 15px rgba(0 0 0 / 20%);
      outline: none;
    }

    legend {
      width: auto;
      color: #666;
      display: block;
      padding: 0 0.5rem;
      margin-left: 0.5rem;
      font-size: 0.75rem;
      text-align: left;
    }

    textarea {
      font-size: 0.875rem;
      font-weight: lighter;
      line-height: 1.5;
      color: #333;
      min-height: 12rem;
      border: none;
      padding: 1rem;
      width: 100%;
      height: 100%;
      resize: vertical;
      outline: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  fieldset.textarea.disabled {
    background-color: #f5f5f5;
    cursor: default;

    div.faux-textarea {
      padding: 1rem;
    }

    p {
      margin-bottom: 1em;
      line-height: 1.5;

      &.list-item {
        display: list-item;
        list-style-type: square;
        margin-left: 1em;
        margin-bottom: 1em;
      }
    }
  }
`;
