import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  width: 100%;

  legend {
    text-align: ${(props) => (props.reverse ? "inherit" : "right !important")};
    margin-right: 0.5rem;
  }

  textarea {
    min-height: 8rem !important;
  }

  .text-field {
    border-radius: 7px 0 7px 7px;
    padding: 1rem;
    flex: 1;
    background-color: #f5f5f5;
  }

  .text-field::before {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #f5f5f5;
  }

  .timeline-item-commands {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex: 0 0 38px;
  }
`;
