import DataGrid, { Column, Lookup } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import pdsGroups from "../../config/pdsGroups";
import { TriggerContext } from "../../context/TriggerProvider";
import { ValueContext } from "../../context/ValueProvider";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import OfficeIcon from "../OfficeIcons";
import ProfessionalLookup from "../ProfessionalLookup";
import PhotoCell from "./CellRender/PhotoCell";
import { Container, StatusCell } from "./styles";

export default function MentorintList({ setListData }) {
  const { user, hasScope } = useAuth();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);
  const { master } = useContext(ValueContext);
  const { trigger } = useContext(TriggerContext);
  const { addToast } = useToast();
  const history = useHistory();
  const dataGridRef = useRef(null);
  const { id } = useParams();

  const dataSource = useMemo(() => {
    return new DataSource({
      key: "id",
      loadMode: "raw",
      cacheRawData: true,
      load: async () => {
        try {
          const { data } = await api.get("api/professional-mentor");

          return data.filter((x) => x.isActive);
        } catch (err) {
          addToast({
            type: "error",
            title: "Data couldn't be loaded.",
          });
          throw err;
        }
      },
      update: async (key, values) => {
        try {
          const currentItem = dataSource.items().find((i) => i.id === key);

          const idMentor =
            values.professionalMentor && values.professionalMentor.idMentor
              ? values.professionalMentor.idMentor
              : currentItem.professionalMentor &&
                currentItem.professionalMentor.idMentor
              ? currentItem.professionalMentor.idMentor
              : null;

          if (!idMentor) {
            addToast({
              type: "error",
              title: "Failed to save",
              description: "Please select a mentor.",
            });
            throw new Error("Mentor selection is required.");
          }

          const startDate =
            values.professionalMentor && values.professionalMentor.startDate
              ? values.professionalMentor.startDate
              : currentItem.professionalMentor &&
                currentItem.professionalMentor.startDate
              ? currentItem.professionalMentor.startDate
              : null;

          if (!startDate) {
            addToast({
              type: "error",
              title: "Failed to save",
              description: "The start date is required.",
            });
            throw new Error("The start date is required.");
          }

          const updatedData = {
            idProfessional: key,
            idMentor: idMentor,
            startDate: startDate,
          };

          await api.post(`api/professional-mentor`, updatedData);
          addToast({
            type: "success",
            title: "Saved",
          });
        } catch (error) {
          throw error;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleRowClick = useCallback(
    (e) => {
      history.push(`${e?.data?.id}`);
      setListData(e?.data);
    },
    [history, setListData]
  );
  useEffect(() => {
    if (id) {
      dataSource.load().then((data) => {
        const professional = data.find((x) => x.id == id);
        setListData(professional);
      });
    }
  }, [id, dataSource, setListData]);

  return (
    <Container>
      <DataGrid
        dataSource={dataSource}
        ref={dataGridRef}
        key="professionalMentor.idProfessional"
        width="100%"
        height="100%"
        headerFilter={{ visible: true, allowSearch: true }}
        columnAutoWidth
        sorting={{ mode: "multiple" }}
        onToolbarPreparing={(e) => {
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
              text: "Clear Filters",
              elementAttr: { class: "clear-filter" },
              icon: "filter",
              onClick: () => {
                e.component.clearFilter();
              },
            },
          });
        }}
        editing={{
          mode: "popup",
          allowUpdating: isAdmin,
          form: {
            items: [
              {
                dataField: "professionalMentor.idMentor",
                validationRules: [{ type: "required" }],
              },
              {
                dataField: "professionalMentor.startDate",
                editorOptions: { type: "date", useMaskBehavior: true },
                validationRules: [{ type: "required" }],
              },
            ],
          },
        }}
        onRowClick={(e) => handleRowClick(e)}
      >
        <Column
          dataField="professionalMentor.wasGoodStatus"
          caption="Status"
          cellRender={(e) =>
            isAdmin || user.userId === e.data.professionalMentor?.idMentor ? (
              <StatusCell
                status={e.data.professionalMentor?.wasGoodStatus ?? undefined}
              >
                <PhotoCell data={e.data} />
                {e.data.professionalMentor?.wasGoodStatus === true &&
                  "Good Conversation"}
                {e.data.professionalMentor?.wasGoodStatus === false &&
                  "Bad Conversation"}
                {e.data.professionalMentor?.wasGoodStatus === undefined &&
                  "No Conversation"}
              </StatusCell>
            ) : null
          }
          width={175}
          formItem={{ visible: false }}
          allowEditing={false}
          alignment="left"
        />

        <Column
          dataField="id"
          caption="Professional"
          cellRender={(e) => (
            <ProfessionalLookup
              data={e.data.name}
              name={e.data.name}
              style={{
                cursor: "pointer",
                placeContent: "center",
              }}
            />
          )}
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          caption="Office"
          dataField="offices[0].name"
          alignment="center"
          cellRender={(e) => (
            <OfficeIcon
              office={e.data.offices[0]?.name ? e.data.offices[0].name : null}
              className="office-icon"
            />
          )}
          formItem={{ visible: false }}
          allowEditing={false}
        />

        <Column
          caption="Practice"
          dataField="practices[0].name"
          alignment="center"
          allowEditing={false}
          cellRender={(e) => {
            const practices = e.data.practices[0].name;
            return (
              <p
                style={{
                  color: "#333",
                  textAlign: "center",
                  fontFamily: "Roboto Condensed",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "0.81038rem",
                }}
              >
                {practices}
              </p>
            );
          }}
        />

        <Column
          caption="Current Mentor"
          dataField="professionalMentor.idMentor"
          cellRender={(e) => {
            if (master.professionals) {
              const professional = master.professionals.find(
                (x) => x.ID === e.data.professionalMentor?.idMentor
              );

              return <PhotoCell data={professional} />;
            }
          }}
          alignment="center"
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          caption="Start Date"
          dataField="professionalMentor.startDate"
          dataType="date"
          format="dd MMM yyyy"
          editorType="dxDateBox"
        />

        <Column
          caption="Last Talk"
          dataField="professionalMentor.lastTalk"
          dataType="date"
          alignment="left"
          format="dd MMM yyyy"
        />
      </DataGrid>
    </Container>
  );
}
