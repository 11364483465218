import React, { useEffect, useState } from "react";

import DataGrid, { Lookup, Column, Editing } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import api from "../../../services/api";
import { DataGridWrapper } from "../styles";

export default function AdminDoDontSkillTypes() {
  const [skillTypesSource, setSkillTypesSource] = useState();

  useEffect(() => {
    const skillTypesStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/skill-type");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/skill-type", data);
      },
      update: async (id, data) => {
        await api.put(`/api/skill-type/${id}`, data);
      },
    });

    setSkillTypesSource(
      new DataSource({
        store: skillTypesStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, []);

  return (
    <DataGridWrapper>
      <DataGrid
        height={550}
        dataSource={skillTypesSource}
        showBorders={false}
        key="id"
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="description" />
        <Column dataField="isActive" dataType="boolean" />
        <Column dataField="captionDescription1" />
        <Column dataField="captionDescription2" />
        <Column dataField="captionComment" />
        <Column dataField="captionExpiredAt" />
        <Column dataField="captionUpdatedAt" />
        <Column dataField="isList" dataType="boolean" />
        <Column dataField="listType">
          <Lookup
            dataSource={[
              "PRACTICES",
              "OFFICES",
              "GOVERNANCE",
              "CLIENTS",
              "COUNTRIES",
              "LANGUAGES",
              "SKILLS",
              "VACCINES",
            ]}
          />
        </Column>
      </DataGrid>
    </DataGridWrapper>
  );
}
