import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  .tabs {
    display: flex;
    gap: 5px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .tab {
    background-color: white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    color: #ccc;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: -5px;
    border-radius: 5px;
  }

  .tab.active {
    color: #8b0304;
    transition: all 0.2s ease-in-out;
  }

  .tab-content {
    position: relative;
    display: none;
    z-index: 5;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
  }
  .tab-content.active {
    display: block;
  }
`;

export const DataGridWrapper = styled.div`
  position: relative;
  flex: 1;

  & > div {
    box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
    border-radius: 7px;
    overflow: hidden;
  }
  .dx-datagrid {
    color: #333;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
    border: none;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    color: #999;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .dx-datagrid-rowsview .dx-row {
    cursor: pointer;

    :hover {
      background-color: #f5f5f5ff;
    }
    :active {
      background-color: #d9d9d9ff;
    }
  }

  .dx-datagrid-rowsview .dx-row.dx-freespace-row {
    cursor: default;

    :hover {
      background-color: transparent;
    }
    :active {
      background-color: transparent;
    }
  }

  .dx-datagrid .dx-row > td {
    font-size: 0.75rem;
    font-weight: 500;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #f5f5f5;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-data-row > td {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 0;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0 0 30px rgba(0 0 0 / 15%);
    background: #8b0304;
    color: white;

    :hover {
      background: #b80304;
    }
    :active {
      background: #a80304;
    }
  }
  .office-icon {
    width: 24px;
    height: 24px;
  }

  textarea {
    background-color: #dedede !important;
    font-size: 12px !important;
    padding-top: 1px !important;
    padding-left: 5px !important;
    border-radius: 5px !important;
    height: 150px !important;
  }

  button {
    width: fit-content;
    margin: 10px;
  }
`;
