import React, { useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import { WhisperSpinner } from "react-spinners-kit";
import { Container } from "./styles";

import { useAuth } from "../../hooks/auth";

export default function SignIn(props) {
  const { signInAuthorize, redirectMicrosoft } = useAuth();
  const hash = useState(props.location.hash);

  const signIn = useCallback(
    async (accessToken) => {
      try {
        await signInAuthorize(accessToken);

        // Needs to redirect to get localStorage
        window.location.href = "/";
      } catch (err) {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Error on logon";
        //history.replace('/', { error: errorMessage });
      }
    },
    [signInAuthorize]
  );

  useEffect(() => {
    const params = queryString.parse(hash[0] || hash);

    if (params.access_token) {
      signIn(params.access_token);
    } else {
      redirectMicrosoft();
    }
  }, [hash, redirectMicrosoft, signIn]);

  return (
    <Container>
      <WhisperSpinner size={80} color="#8b0304" backColor="#8b0304" />
    </Container>
  );
}
