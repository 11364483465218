import React, { useMemo, useState } from "react";
import DialogEditCycle from "./DialogEditCycle";
import DialogRefreshEvaluations from "./DialogRefreshEvaluations";
import DialogLockUnlockEvaluations from "./DialogLockUnlockEvaluations";
import DataGrid, { Column, Lookup, Button } from "devextreme-react/data-grid";
import api from "../../../../services/api";
import { FiLock } from "react-icons/fi";
import { DataGridWrapper } from "../../styles";
import CustomStore from "devextreme/data/custom_store";

export default function AdminEvaluationsCycles() {
  const [openCycleEdit, setOpenCycleEdit] = useState(false);
  const [openRefreshEvaluation, setOpenRefreshEvaluation] = useState(false);
  const [openLockUnlockDialog, setOpenLockUnlockDialog] = useState(false);
  const [areaTypeId, setAreaTypeId] = useState(0);
  const [cycleEditId, setCycleEditId] = useState(0);
  const [isWeightedAverage, setIsWeightedAverage] = useState(false);
  const dataGridRef = React.useRef(null);

  const areaTypeSource = useMemo(() => {
    return new CustomStore({
      key: "ID",
      loadMode: "raw",
      paginate: true,
      load: async () => {
        const response = await api.get("api/area-types");
        return response.data;
      },
    });
  }, []);

  const cycleSource = useMemo(() => {
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        try {
          const response = await api.get("/api/cycles");
          return response.data;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      insert: async (data) => {
        try {
          await api.post("/api/cycles", data);
        } catch (err) {
          console.error(err.response.data);
          throw err.response.data;
        }
      },
      update: async (id, data) => {
        try {
          await api.put(`/api/cycles/${id}`, {
            ...data,
            id,
            period: data.period,
            year: data.year,
            isActive: data.isActive,
            idAreaType: data.idAreaType,
            startDate: data.startDate,
            endDate: data.endDate,
          });
        } catch (err) {
          console.error(err.response.data);
          throw err.response.data;
        }
      },
      remove: async (id) => {
        try {
          await api.delete(`/api/cycles/${id}`);
        } catch (err) {
          console.error(err.response.data);
          throw err.response.data;
        }
      },
    });
  }, []);

  return (
    <DataGridWrapper>
      <DataGrid
        height={550}
        ref={dataGridRef}
        dataSource={cycleSource}
        showBorders={false}
        key="id"
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
        headerFilter={{ visible: true, allowSearch: true }}
        editing={{ mode: "row", allowUpdating: true, allowAdding: true }}
      >
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="idAreaType" caption="Area Type">
          <Lookup
            dataSource={areaTypeSource}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="isActive"
          allowFiltering={false}
          dataType="boolean"
        />
        <Column dataField="year" allowFiltering={false} dataType="int" />
        <Column dataField="period" allowFiltering={false} dataType="int" />
        <Column dataField="startDate" allowFiltering={false} dataType="date" />
        <Column dataField="endDate" allowFiltering={false} dataType="date" />
        <Column
          dataField="lastCycleRefreshDate"
          allowFiltering={false}
          dataType="date"
        />
        <Column
          type="buttons"
          width={130}
          buttons={[
            {
              hint: "Edit",
              icon: "edit",
              name: "edit",
            },
            {
              hint: "Edit Cycle",
              icon: "doc",
              onClick: (e) => {
                setIsWeightedAverage(e.row.data.areaType.isWeightedAverage);
                setCycleEditId(e.row.key);
                setOpenCycleEdit(true);
              },
            },
            {
              hint: "Lock/Unlock all evaluations in this cycle",
              icon: "key",
              onClick: (e) => {
                setCycleEditId(e.row.key);
                setAreaTypeId(e.row.data.areaType.id);
                setOpenLockUnlockDialog(true);
              },
            },
            {
              hint: "Refresh evaluations",
              icon: "refresh",
              visible: (e) =>
                [2, 3, 4, 5, 9].includes(e.row.data.areaType?.id) &&
                e.row.data.isActive,
              onClick: (e) => {
                setAreaTypeId(e.row.data.areaType.id);
                setOpenRefreshEvaluation(true);
              },
            },
          ]}
        ></Column>
      </DataGrid>

      <DialogEditCycle
        open={openCycleEdit}
        handleClose={() => setOpenCycleEdit(false)}
        cycleId={cycleEditId}
        isWeightedAverage={isWeightedAverage}
      />
      <DialogRefreshEvaluations
        open={openRefreshEvaluation}
        handleClose={() => setOpenRefreshEvaluation(false)}
        areaTypeId={areaTypeId}
      />
      <DialogLockUnlockEvaluations
        open={openLockUnlockDialog}
        cycleId={cycleEditId}
        areaTypeId={areaTypeId}
        handleClose={() => setOpenLockUnlockDialog(false)}
      />
    </DataGridWrapper>
  );
}
