import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80px;
  border-left: #4cb56f solid 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
  padding: 15px;
  font-size: 24px;

  .navigation {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.bottom {
      margin-top: auto;
    }

    &:hover {
      background-color: #ddd;
    }

    &:active {
      background-color: #ccc;
    }

    svg {
      font-size: 20px;
      color: #9d9d9d;
    }
  }

  .admin-wrapper {
    position: relative;
  }

  hr {
    width: 75%;
  }
`;

export const AdminSubmenu = styled.div`
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  color: #9d9d9d;
  gap: 5px;

  .navigation {
    cursor: pointer;
    font-weight: 500;
    padding: 5px 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 7px;
  }

  .navigation:hover {
    background: #eee;
    color: #333;
  }

  .navigation:active {
    color: #aaa;
  }
`;
