import React, { useCallback, useState, useEffect } from "react";

import CustomStore from "devextreme/data/custom_store";
import { SelectBox, TextArea, TextBox } from "devextreme-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { WhisperSpinner } from "react-spinners-kit";
import Button from "../../../../../components/Button";
import api from "../../../../../services/api";

import { useToast } from "../../../../../hooks/toast";

import { Label, Fieldset, ContainerLoading } from "./styles";

export default function DialogEditConclusion({
  open,
  handleClose,
  conclusionId,
}) {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [answer, setAnswer] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [idActionField, setIdActionField] = useState(0);
  const [decision, setDecision] = useState(false);

  const [actionFields] = useState({
    store: new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/action-fields");
        return data.filter((x) => x.actionFieldType?.isCounselorField);
      },
    }),
    paginate: true,
  });

  const loadConclusion = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `/api/counseling-conclusions/${conclusionId}`
    );
    setTitle(data.title);
    setAnswer(data.answer);
    setIdActionField(data.idActionField);
    setIsActive(data.isActive);
    setDecision(data.decision);
    setLoading(false);
  }, [conclusionId]);

  useEffect(() => {
    loadConclusion();
  }, [loadConclusion]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setLoading(true);

      try {
        await api.put(`/api/counseling-conclusions/${conclusionId}`, {
          title,
          answer,
          idActionField,
          isActive,
          decision,
        });

        addToast({
          type: "success",
          title: "Success",
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          title: "Ops",
          description: "Something went wrong",
        });
      }
      setLoading(false);
    },
    [
      addToast,
      answer,
      conclusionId,
      decision,
      handleClose,
      idActionField,
      isActive,
      title,
    ]
  );

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>+ Edit Conclusion</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {loading && (
            <ContainerLoading>
              <WhisperSpinner size={50} color="#8b0304" backColor="#8b0304" />
            </ContainerLoading>
          )}
          <Fieldset>
            <Label>Title</Label>
            <TextBox value={title} onValueChanged={(e) => setTitle(e.value)} />
          </Fieldset>

          <Fieldset>
            <Label>Answer</Label>
            <TextArea
              value={answer}
              onValueChanged={(e) => setAnswer(e.value)}
            />
          </Fieldset>

          <Fieldset>
            <Label>Action Field</Label>
            <SelectBox
              dataSource={actionFields}
              valueExpr="id"
              displayExpr="description"
              placeholder="Select action field"
              value={idActionField}
              onValueChanged={(e) => setIdActionField(e.value)}
            />
          </Fieldset>

          <Fieldset style={{ display: "flex", gap: 10 }}>
            <div style={{ width: "80%" }}>
              <Label>Is Active</Label>
              <SelectBox
                dataSource={[
                  { value: true, name: "Yes" },
                  { value: false, name: "No" },
                ]}
                value={isActive}
                onValueChanged={(e) => setIsActive(e.value)}
                displayExpr="name"
                valueExpr="value"
              />
            </div>
            <div style={{ width: "80%" }}>
              <Label>Decision</Label>
              <SelectBox
                dataSource={[
                  { value: true, name: "Approve" },
                  { value: false, name: "Not Approve" },
                ]}
                value={decision}
                onValueChanged={(e) => setDecision(e.value)}
                displayExpr="name"
                valueExpr="value"
              />
            </div>
          </Fieldset>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <div>
            <Button
              primary
              type="submit"
              disabled={loading}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Edit
              {loading && (
                <WhisperSpinner
                  size={20}
                  color="#8b0304"
                  backColor="#8b0304"
                  frontColor="#fff"
                />
              )}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
