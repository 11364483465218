import React, { useCallback, useEffect, useState } from "react";

import DataGrid, {
  Column,
  Editing,
  RowDragging,
  Scrolling,
  Sorting,
  Summary,
  TotalItem,
  Button,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DialogCompetenceEdit from "../DialogCompetenceEdit";
import DialogPermission from "../DialogPermission";

import api from "../../../../../services/api";
import { DataGridWrapper } from "../../../styles";

export default function CompetenceGrid({ data, isWeighted: _isWeighted }) {
  const [seletedItem, setSelectedItem] = useState();
  const [seletedItemName, setSelectedItemName] = useState("");
  const [openModal, setOpenModal] = useState();
  const [competenceSource, setCompetenceSource] = useState();
  const [competenceStore, setCompetenceStore] = useState();
  const [idPillar] = useState(data.data.id);
  const [isWeighted] = useState(_isWeighted);
  const [openPermission, setOpenPermission] = useState(false);
  const [selectedKey, setSelectedKey] = useState(0);

  useEffect(() => {
    const competenceStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/competences", {
          params: {
            idPillar,
          },
        });
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/competences", {
          ...data,
          idPillar,
        });
      },
      update: async (id, data) => {
        await api.put(`/api/competences/${id}`, data);
      },
      remove: async (id) => {
        await api.delete(`/api/competences/${id}`);
      },
    });

    setCompetenceStore(competenceStore);

    setCompetenceSource(
      new DataSource({
        store: competenceStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [idPillar]);

  const handleDrag = useCallback(
    async (e) => {
      await competenceStore.update(e.itemData.id, { order: e.toIndex + 1 });
      await e.component.refresh();
    },
    [competenceStore]
  );

  const handleOpenEdit = useCallback((e) => {
    setSelectedItem(e.row.key);

    setSelectedItemName(e.row.data.description);
    setOpenModal(true);
  }, []);

  const percentCell = useCallback((e) => <p>{e.value}%</p>, []);

  const handleOpenPermission = useCallback((e) => {
    setOpenPermission(true);
    setSelectedKey(e.row.key);
  }, []);

  return (
    <DataGridWrapper>
      <DataGrid dataSource={competenceSource} showBorders={false}>
        <RowDragging
          allowReordering
          onReorder={handleDrag}
          showDragIcons
          dropFeedbackMode="push"
        />
        <Scrolling mode="virtual" />
        <Sorting mode="none" />
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="description" caption="Competence" />
        <Column dataField="order" visible={false} />
        <Column dataField="isActive" dataType="boolean" />
        <Column dataField="idPillar" visible={false} />
        <Column dataField="tooltip" />
        <Column
          dataField="fieldWeight"
          visible={isWeighted}
          cellRender={percentCell}
        />

        <Column type="buttons" width={110}>
          <Button name="edit" />
          {isWeighted && (
            <Button
              hint="Sub-competences"
              icon="mediumiconslayout"
              onClick={handleOpenEdit}
            />
          )}
          {isWeighted && (
            <Button
              hint="Permissions"
              icon="key"
              onClick={handleOpenPermission}
            />
          )}
        </Column>

        <Summary>
          <TotalItem
            column="fieldWeight"
            summaryType="sum"
            displayFormat="Sum: {0}%"
          />
        </Summary>
      </DataGrid>

      {openModal && isWeighted && (
        <DialogCompetenceEdit
          open={openModal}
          handleClose={() => setOpenModal(false)}
          idCompetence={seletedItem}
          competenceName={seletedItemName}
        />
      )}

      {openPermission && isWeighted && (
        <DialogPermission
          open={openPermission}
          handleClose={() => setOpenPermission(false)}
          id={selectedKey}
          type="idCompetence"
        />
      )}
    </DataGridWrapper>
  );
}
