import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";

import { Container, StatusCell } from "./styles";

import CustomStore from "devextreme/data/custom_store";
import DataGrid, {
  Column,
  Editing,
  Lookup,
  RequiredRule,
  Form,
  Paging,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react";
import { Item } from "devextreme-react/form";

import api from "../../../services/api";
import master from "../../../services/master";
import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";

import pdsGroups from "../../../config/pdsGroups";
import PhotoCell from "../../../components/EvaluationList/CellRender/PhotoCell";
import { FiSend } from "react-icons/fi";
import DialogSendWarning from "../DialogSendWarning";

export default function ActionPlan({ title }) {
  const { addToast } = useToast();
  const { user, hasScope } = useAuth();
  const [cycles, setCycles] = useState();
  const dataGridRef = useRef(null);
  const [openSendEmails, setOpenSendEmails] = useState(false);
  const [actionPlanResponsible, setActionPlanResponsible] = useState();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);

  useEffect(() => {
    setCycles({
      store: new CustomStore({
        key: "ID",
        loadMode: "raw",
        load: async () => {
          const response = await api.get("/api/promotioncycle");
          const cycles = response.data.filter((x) => x.isActive);

          const cyclesWithDisplayName = cycles.map((cycle) => ({
            ...cycle,
            displayName: `${cycle.period}Q - ${cycle.currentYear} (${cycle.areaType.description})`,
          }));

          return cyclesWithDisplayName;
        },
      }),
      paginate: true,
    });
  }, [title]);

  const actionPlansSource = useMemo(() => {
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      reshapeOnPush: true,
      load: async () => {
        const response = await api.get(`/api/promotion-actions`);
        const actionPlans = response.data;
        const isResponsible = actionPlans.some(
          (plan) => plan.idActionResponsible === user.userId
        );

        setActionPlanResponsible(isResponsible);
        return actionPlans;
      },
      update: async (id, data) => {
        await api.put(`api/promotion-actions/${id}`, data);
        addToast({
          type: "success",
          title: "Saved",
        });
      },
    });
  }, [addToast, user.userId]);

  const isPracticeLeader = useMemo(() => {
    return user.userId === actionPlanResponsible;
  }, [actionPlanResponsible, user.userId]);

  const [professionalsSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await master.get("/master/professionals", {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        const professionals = [...response.data];
        return professionals;
      },
    }),
    paginate: true,
  });

  const [actionStatuses] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/promotion-action-status");
        return response.data;
      },
    }),
    paginate: true,
  });

  const cycleCell = useCallback((e) => {
    const period = e.data.promotion.promotionCycle.period;
    const year = e.data.promotion.promotionCycle.currentYear;

    return (
      <p
        style={{
          color: "#333",
          textAlign: "center",
          fontFamily: "Roboto Condensed",
          fontSize: "0.75rem",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "0.81038rem",
        }}
      >
        {period}Q - {year}
      </p>
    );
  }, []);

  const textCell = useCallback((value) => {
    return (
      <textarea
        style={{
          height: 250,
          width: "100%",
          resize: "none",
          border: "0",
          outline: "0",
          backgroundColor: "#EFEFEF",
          textAlign: "left",
          padding: 20,
          borderRadius: 7,
          fontFamily: "Roboto Condensed",
        }}
        value={value}
        readOnly={true}
      />
    );
  }, []);

  const notesEditorOptions = { height: 250 };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: "Clear Filters",
          elementAttr: { class: "clear-filter" },
          icon: "filter",
          onClick: () => {
            e.component.clearFilter();
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          hint: "Send Emails",
          icon: "email",
          onClick: () => {
            setOpenSendEmails(true);
          },
          visible: isAdmin,
        },
      }
    );
  };

  return (
    <Container style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <DataGrid
        width="100%"
        height="100%"
        ref={dataGridRef}
        allowColumnReordering
        allowColumnResizing
        onToolbarPreparing={onToolbarPreparing}
        dataSource={actionPlansSource}
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
        keyExpr="id"
        headerFilter={{ visible: true, allowSearch: true }}
        sorting={{ mode: "multiple" }}
      >
        <Paging defaultPageSize={20} />
        <Column
          dataField="idStatus"
          caption="Status"
          alignment="center"
          width={140}
          cellRender={(e) => (
            <StatusCell status={e.data.promotionActionStatus.id}>
              <PhotoCell data={e.data.promotion.professional} />
              {e.data.promotionActionStatus.description}
            </StatusCell>
          )}
        >
          <Lookup
            dataSource={actionStatuses}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="promotion.professional.name"
          caption="Professional"
          alignment="left"
          width={120}
          allowEditing={false}
          cellRender={(e) => {
            const promotion = e.data.promotion?.professional?.name;
            return (
              <p
                style={{
                  color: "#333",
                  textAlign: "center",
                  fontFamily: "Roboto Condensed",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "0.81038rem",
                }}
              >
                {promotion}
              </p>
            );
          }}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="promotion.promotionCycle.areaType.description"
          caption="Area Type"
          alignment="center"
          width={110}
          allowEditing={false}
        />
        <Column
          alignment="center"
          dataField="promotion.promotionCycle.id"
          caption="Cycle"
          width={90}
          cellRender={cycleCell}
          allowEditing={false}
        >
          <Lookup
            dataSource={cycles}
            displayExpr="displayName"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="promotion.professional.practices[0].name"
          caption="Practice"
          alignment="center"
          width={120}
          allowEditing={false}
        />

        <Column
          dataField="idActionResponsible"
          editorType="dxSelectBox"
          caption="Responsible"
          alignment="center"
          cellRender={(e) => <PhotoCell data={e.data.professional} />}
          width={100}
          allowEditing={hasScope([pdsGroups.Admin])}
        >
          <RequiredRule />
          <Lookup
            dataSource={professionalsSource}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          alignment="center"
          dataField="isGroupResponsible"
          width={160}
          caption="Group Responsible?"
          dataType="boolean"
        />
        <Column
          dataField="responsibleComments"
          caption="Responsible Comments"
          cellRender={(e) => textCell(e.data.responsibleComments)}
          editorOptions={notesEditorOptions}
          colSpan={2}
          editorType="dxTextArea"
          alignment="center"
        />

        <Column
          dataField="actionText"
          caption="Action Plan"
          cellRender={(e) => textCell(e.data.actionText)}
          editorOptions={notesEditorOptions}
          colSpan={2}
          editorType="dxTextArea"
          alignment="center"
        >
          <RequiredRule />
        </Column>

        <Column type="buttons" width={100} />
        <Editing
          allowUpdating={(e) =>
            isAdmin || e.row.data.idActionResponsible === user.userId
          }
          mode="popup"
        >
          <Popup
            title="Edit Action Plan"
            showTitle={true}
            width={1080}
            height={560}
            style={{
              backgroundColor: "#efefef",
            }}
          />
          <Form>
            <Item dataField="idStatus" />
            <Item dataField="idActionResponsible" />

            <Item
              dataField="actionText"
              editorType="dxTextArea"
              colCount={2}
              colSpan={2}
            />
            <Item
              dataField="responsibleComments"
              editorType="dxTextArea"
              colCount={2}
              colSpan={2}
            />
            <Item
              editorType="dxCheckBox"
              dataField="isGroupResponsible"
              colCount={1}
            />
          </Form>
        </Editing>
      </DataGrid>
      {openSendEmails && (
        <DialogSendWarning
          open={openSendEmails}
          handleClose={() => setOpenSendEmails(false)}
        />
      )}
    </Container>
  );
}
