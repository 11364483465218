import Tooltip from "@material-ui/core/Tooltip";
import { SelectBox } from "devextreme-react";
import React from "react";
import { GrContract, GrExpand, GrFormClose } from "react-icons/gr";
import { useHistory, useParams } from "react-router-dom";
import NoIcon from "../../../assets/images/no-icon.svg";
import DialogProjectStatus from "../../../pages/Counseling/View/DialogChangeStatus";
import OfficeIcon from "../../OfficeIcons";
import { TopMenu } from "./styles";

export default function SidePanelCounselingHeader({
  counselingData,
  path,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  counselingYears,
  isAdmin,
  userId,
}) {
  const history = useHistory();
  const { id, year } = useParams();

  const handleChangeYear = (event) => {
    history.push(`/${path}/${id}/${event.value}`);
  };

  return (
    <>
      <TopMenu status={counselingData.counselingStatus?.id}>
        <div>
          <div className="evaluators">
            <div>
              <Tooltip
                title={
                  counselingData.counselor?.name || "No counselor assigned"
                }
                placement="bottom"
              >
                <span>
                  <img
                    src={
                      counselingData.counselor?.login
                        ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${counselingData.counselor?.login}_192_192.png`
                        : NoIcon
                    }
                    alt={counselingData.counselor?.name}
                  />
                </span>
              </Tooltip>
              Counselor
            </div>
          </div>
          <div className="status">
            {counselingData.counselingStatus?.description}
          </div>
          <div className="tools">
            <div onClick={() => setIsSidePanelFullScreen((x) => !x)}>
              {!isSidePanelFullScreen ? (
                <GrExpand size={12} />
              ) : (
                <GrContract size={12} />
              )}
            </div>
            <div
              onClick={() => {
                history.push("/" + path + "/");
                setIsSidePanelFullScreen(false);
                setIsSidePanelOpen(false);
              }}
            >
              <GrFormClose size={20} />
            </div>
          </div>
        </div>
        <div className="profile">
          <div>
            <img
              className="professional"
              src={
                counselingData.professional?.login
                  ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${counselingData.professional?.login}_192_192.png`
                  : NoIcon
              }
              alt={counselingData.professional?.name}
            />
            <div>
              <div>
                <h2>{counselingData.professional?.name}</h2>
                <div className="profile-info">
                  <h3>
                    <b>{counselingData.professional?.jobTitle.name}</b> |{" "}
                    {counselingData.practice?.name}
                  </h3>

                  <OfficeIcon
                    className="office-icon"
                    office={counselingData.professional?.offices[0]?.name}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-wrapper">
            <SelectBox
              dataSource={counselingYears}
              value={parseInt(year)}
              onValueChanged={(e) => handleChangeYear(e)}
            />
            {counselingData.idCounselingStatus !== 3 &&
              (isAdmin || counselingData.idProfessional !== userId) && (
                <DialogProjectStatus counselingData={counselingData} />
              )}
          </div>
        </div>
      </TopMenu>
    </>
  );
}
