import React, { useContext, useEffect, useMemo } from "react";
import { Container } from "./styles";
import { ValueContext } from "../../../context/ValueProvider";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useAuth } from "../../../hooks/auth";
import { useCallback } from "react";
import { useState } from "react";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "@material-ui/core";

export default function Overview({
  evaluation,
  pillars,
  isSidePanelFullScreen,
  isLoadingGrades,
  isEvaluationSenior,
}) {
  const { master } = useContext(ValueContext);
  // @ts-ignore
  const { user } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTooltip, setDialogTooltip] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  const professional = useMemo(() => {
    return master?.professionals?.find(
      (professional) => professional.ID === evaluation?.idProfessional
    );
  }, [evaluation, master]);

  const [canViewGrades, setCanViewGrades] = useState(false);
  const checkIfUserCanViewGrades = useCallback(() => {
    if (evaluation?.idProfessional === user.userId) {
      if (
        evaluation?.idEvaluationStatus === 4 ||
        evaluation?.idEvaluationStatus === 5
      ) {
        setCanViewGrades(true);
      } else {
        setCanViewGrades(false);
      }
    } else {
      setCanViewGrades(true);
    }
  }, [evaluation, user.userId]);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleClick = (value) => {
    setOpenDialog(true);
    setDialogTooltip(value.tooltip);
    setDialogTitle(value.title);
  };

  useEffect(() => {
    checkIfUserCanViewGrades();
  }, [checkIfUserCanViewGrades]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <div className="overview">
        <div className="key-messages">
          {professional?.effectivationDate !== undefined && (
            <div className="key-message">
              <h3>Effective Date</h3>
              <strong>{professional.effectivationDate}</strong>
            </div>
          )}
          {evaluation?.cycle !== undefined && (
            <div className="key-message">
              <h3>Period</h3>
              <strong>
                {evaluation.cycle.period}Q - {evaluation.cycle.year}
              </strong>
              ({evaluation?.areaType?.description})
            </div>
          )}
          {evaluation?.project !== undefined &&
            evaluation?.idAreaType === 2 && (
              <div className="key-message">
                <h3>Project</h3>
                <strong>{evaluation.project.projectCode ?? "NOCODE"}</strong>(
                {evaluation.project.projectName})
              </div>
            )}
          {evaluation?.project !== undefined &&
            evaluation?.idAreaType === 2 && (
              <div className="key-message">
                <h3>Client</h3>
                <strong>{evaluation.project.client.name}</strong>
              </div>
            )}
          {evaluation?.totalAverage !== undefined && (
            <div className="key-message">
              <h3>Total Grade</h3>
              <strong>
                {isLoadingGrades ? (
                  <CircularProgress size="0.85em" />
                ) : canViewGrades ? (
                  evaluation.totalAverage.toFixed(2)
                ) : (
                  "-"
                )}
              </strong>
            </div>
          )}
        </div>

        {pillars?.map((p) => {
          const pillarGrade = evaluation?.evaluationPillars?.find(
            (x) => x.idPillar === p.id
          );

          return (
            <div key={p.id} className="overview-grade grouped">
              <div className="header">
                <h4>
                  {p.name}
                  {p.tooltip && (
                    <Tooltip title={p.tooltip}>
                      <span style={{ marginLeft: ".5em" }}>
                        <FiInfo />
                      </span>
                    </Tooltip>
                  )}
                </h4>
                <strong>
                  {isLoadingGrades ? (
                    <CircularProgress size="0.85em" />
                  ) : !canViewGrades || !isEvaluationSenior ? (
                    ""
                  ) : pillarGrade?.fieldValue === -1 ? (
                    "N/A"
                  ) : (
                    pillarGrade?.fieldValue.toFixed(2) ?? "—"
                  )}
                </strong>
              </div>
              {p.competences
                .filter((c) => c.isActive)
                .map((c) => {
                  const competenceGrade = evaluation.evaluationCompetences.find(
                    (info) => info.idCompetence === c.id
                  );
                  return (
                    <div className="competences" key={c.id}>
                      <div className="competence">
                        <p>
                          {c.description}
                          {c.tooltip && (
                            <button
                              onClick={() => handleClick(c)}
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                            >
                              <FiInfo color="#8b0304" />
                            </button>
                          )}
                        </p>

                        <strong>
                          {isLoadingGrades ? (
                            <CircularProgress size="0.85em" />
                          ) : !canViewGrades ? (
                            "-"
                          ) : competenceGrade ? (
                            competenceGrade.isNA ? (
                              "N/A"
                            ) : (
                              competenceGrade.fieldValue.toFixed(2)
                            )
                          ) : (
                            "—"
                          )}
                        </strong>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>

          <iframe
            src={dialogTooltip}
            style={{
              width: "100%", // Set the width to 100% of the parent's width
              height: "100%", // Set the height to 100% of the parent's height
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleClose}
              color="inherit"
              style={{
                backgroundColor: "#8b0304",
                color: "#ffffff",
                borderRadius: "5px",
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      )}
    </Container>
  );
}
