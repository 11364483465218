import DataGrid, { Column, Lookup } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useMemo } from "react";
import pdsGroups from "../../../config/pdsGroups";
import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import master from "../../../services/master";
import { Container } from "./styles";
import DataSource from "devextreme/data/data_source";

export default function Skills({
  skills,
  skillType,
  doDont,
  isSidePanelFullScreen,
  reload,
}) {
  const { user, hasScope } = useAuth();
  const dataGridRef = React.useRef(null);

  const dataSource = useMemo(() => {
    return new DataSource({
      key: "id",
      loadMode: "raw",
      cacheRawData: true,
      paginate: true,
      load: () => {
        return skills
          .filter((x) => x.idSkillType === skillType.id)
          .map((item) => ({
            ...item,
            description1:
              item.description1 && (item.skillType.isList || false)
                ? Number(item.description1)
                : item.description1,
            description2:
              item.description2 && (item.skillType.isList || false)
                ? Number(item.description2)
                : item.description2,
          }));
      },
      insert: async (data) => {
        await api.post("/api/skill", {
          ...data,
          IDSkillType: skillType.id,
          IDProfessional: doDont.idProfessional,
          IsActive: true,
        });
        reload();
      },
      update: async (id, data) => {
        await api.put(`/api/skill/${id}`, data);
        reload();
      },
      remove: async (id) => {
        await api.delete(`/api/skill/${id}`);
        reload();
      },
    });
  }, [doDont.idProfessional, reload, skillType.id, skills]);

  const createCustomStore = (key, loadFunction) => {
    return {
      store: new CustomStore({
        key,
        loadMode: "raw",
        load: loadFunction,
        paginate: true,
        cacheRawData: true,
      }),
    };
  };

  const customStore = useMemo(() => {
    switch (skillType.listType) {
      case "GOVERNANCE":
        return createCustomStore("ID", () => [
          { ID: 1, Name: "Co-Management" },
          { ID: 2, Name: "Sales Cell" },
          { ID: 3, Name: "Delivery Cell" },
          { ID: 4, Name: "Professional Development Cell" },
          { ID: 5, Name: "Tech & Digital Lab" },
        ]);
      case "VACCINES":
        return createCustomStore("id", async () => {
          const { data } = await api.get("api/vaccines");
          return data;
        });
      case "COUNTRIES":
        return createCustomStore("ID", async () => {
          const { data } = await master.get("master/geographics");
          return data;
        });
      default:
        return createCustomStore("ID", async () => {
          const { data } = await master.get(
            `master/${skillType.listType.toLowerCase()}`
          );
          return data.map((item) => ({
            ...item,
            Name: !item.IsActive ? `(Inactive) ${item.Name}` : item.Name,
          }));
        });
    }
  }, [skillType.listType]);

  const allowEdit =
    hasScope([pdsGroups.Admin]) || user.userId === doDont.idProfessional;

  return (
    <>
      <Container isSidePanelFullScreen={isSidePanelFullScreen}>
        <div className="grid">
          <DataGrid
            ref={dataGridRef}
            dataSource={dataSource}
            showBorders={false}
            key={skillType.id}
            onRowUpdating={(options) => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
            editing={{
              allowUpdating:
                skillType.id === 6
                  ? allowEdit || hasScope(pdsGroups.Allocation)
                  : allowEdit,
              allowDeleting:
                skillType.id === 6
                  ? allowEdit || hasScope(pdsGroups.Allocation)
                  : allowEdit,
              allowAdding:
                skillType.id === 6
                  ? allowEdit || hasScope(pdsGroups.Allocation)
                  : allowEdit,
            }}
          >
            <Column
              dataField="description1"
              visible={skillType.captionDescription1 !== undefined}
              caption={skillType?.captionDescription1}
            >
              {skillType.isList && skillType.listType === "VACCINES" ? (
                <Lookup
                  dataSource={customStore}
                  displayExpr="name"
                  valueExpr="id"
                  searchEnabled={true}
                />
              ) : (
                <Lookup
                  dataSource={customStore}
                  displayExpr="Name"
                  valueExpr="ID"
                />
              )}
            </Column>
            {skillType.listType === "CLIENTS" ? (
              <Column
                dataField="description2"
                visible={skillType.captionDescription2 !== undefined}
                caption={skillType?.captionDescription2}
              >
                <Lookup
                  valueExpr="id"
                  displayExpr="name"
                  dataSource={[
                    { id: 1, name: "Exclusivity" },
                    { id: 0, name: "Information Barrier" },
                  ]}
                />
              </Column>
            ) : (
              <Column
                dataField="description2"
                visible={skillType.captionDescription2 !== undefined}
                caption={
                  skillType.captionDescription2 && skillType.captionDescription2
                }
              >
                {skillType.isList && skillType.listType === "LANGUAGES" ? (
                  <Lookup
                    dataSource={[
                      { id: 1, name: "Basic" },
                      { id: 2, name: "Intermediate" },
                      { id: 3, name: "Advanced" },
                      { id: 4, name: "Fluent" },
                      { id: 5, name: "Mother language" },
                    ]}
                    displayExpr="name"
                    valueExpr="id"
                  />
                ) : skillType.description.toLowerCase() === "visa" ? (
                  <Lookup
                    dataSource={[
                      { id: 1, name: "Work/Residence" },
                      { id: 2, name: "Tourism/Business" },
                    ]}
                    displayExpr="name"
                    valueExpr="id"
                  />
                ) : skillType.isList && skillType.listType === "SKILLS" ? (
                  <Lookup
                    dataSource={[
                      { id: 1, name: "Basic" },
                      { id: 2, name: "Intermediate" },
                      { id: 3, name: "Advanced" },
                    ]}
                    displayExpr="name"
                    valueExpr="id"
                  />
                ) : (
                  <Lookup
                    dataSource={customStore}
                    displayExpr="Name"
                    valueExpr="ID"
                  />
                )}
              </Column>
            )}
            <Column
              dataField="comment"
              visible={skillType.captionComment !== undefined}
              caption={skillType.captionComment}
            />
            <Column
              dataField="expiredAt"
              dataType="date"
              visible={skillType.captionExpiredAt !== undefined}
              caption={skillType.captionExpiredAt}
              format="dd MMM yyyy"
              sortOrder="desc"
            />
            <Column
              dataField="updatedAt"
              dataType="date"
              visible={skillType.captionUpdatedAt !== undefined}
              caption={skillType.captionUpdatedAt}
            />
          </DataGrid>
        </div>
      </Container>
    </>
  );
}
