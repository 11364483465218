import DataGrid, { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/data_source";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { TriggerContext } from "../../context/TriggerProvider";
import { ValueContext } from "../../context/ValueProvider";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import PhotoCell from "./CellRender/PhotoCell";
import { Container } from "./styles";
import { useAuth } from "../../hooks/auth";
import pdsGroups from "../../config/pdsGroups";

export default function EvaluationByProjectsList({
  type,
  isSidePanelFullScreen,
  idEvaluation,
  idAreaType,
  evaluation,
}) {
  const { master } = useContext(ValueContext);
  const isPartnerEvaluationByProject = type === "partner";
  const { addToast } = useToast();
  const dataGridRef = useRef(null);
  const { triggersFunction } = useContext(TriggerContext);
  const [isNewRow, setIsNewRow] = useState(null);
  const { hasScope, user } = useAuth();

  const isAdmin = useMemo(() => hasScope([pdsGroups.Admin]), [hasScope]);

  const evaluationByProjectsDataSource = useMemo(() => {
    return new CustomStore({
      load: async () => {
        try {
          const { data } = await api.get(
            `api/evaluations/${idEvaluation}/senior-projects`
          );
          return data.filter(
            (item) => item.isPartner === isPartnerEvaluationByProject
          );
        } catch (err) {
          addToast({
            type: "error",
            title: "Evaluations couldn't be loaded. Try again later",
          });
          throw err;
        }
      },

      insert: async (values) => {
        try {
          await api.post(`api/evaluations/${idEvaluation}/senior-projects`, {
            ...values,
            isPartner: isPartnerEvaluationByProject,
          });
          triggersFunction("updateGrade");
        } catch (err) {
          addToast({
            type: "error",
            title: "Error inserting evaluation",
          });
          throw err;
        }
      },
      update: async (key, values) => {
        try {
          await api.put(
            `api/evaluations/${idEvaluation}/senior-projects/${key.id}`,
            {
              ...values,
              isPartner: isPartnerEvaluationByProject,
            }
          );
          triggersFunction("updateGrade");
        } catch (err) {
          addToast({
            type: "error",
            title: "Error updating evaluation",
          });
          throw err;
        }
      },

      reshapeOnPush: true,
      loadMode: "raw",
      cacheRawData: true,
    });
  }, [addToast, idEvaluation, isPartnerEvaluationByProject, triggersFunction]);

  const renderPhotoCell = useCallback(
    (e) => {
      const matchedProfessional = master?.professionals?.find(
        (professional) => professional.ID === e.data.idEvaluator
      );

      return <PhotoCell data={matchedProfessional} />;
    },
    [master]
  );

  return (
    <Container
      style={{ width: !isSidePanelFullScreen ? "560px" : "100%", height: 450 }}
    >
      <DataGrid
        ref={dataGridRef}
        dataSource={evaluationByProjectsDataSource}
        height="100%"
        width="100%"
        onRowUpdating={(options) => {
          options.newData = {
            ...options.oldData,
            ...options.newData,
          };
        }}
        onRowClick={(e) => {
          if (e.rowType === "data") {
            dataGridRef.current.instance.editRow(e.rowIndex);
          }
        }}
        onEditingStart={() => {
          setIsNewRow(false);
        }}
        onInitNewRow={() => {
          setIsNewRow(true);
        }}
        headerFilter={{ visible: true, allowSearch: true }}
        editing={{
          mode: "popup",
          allowAdding: isAdmin,
          form: {
            items: [
              {
                dataField: "idEvaluator",
                isRequired: true,
                editorOptions: {
                  disabled: !(
                    hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                    evaluation?.idPartner === user.userId
                  ),
                },
              },
              {
                dataField: "workDays",
                isRequired: true,
                editorOptions: {
                  disabled: !(
                    hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                    evaluation?.idPartner === user.userId
                  ),
                },
              },
              {
                dataField: "idProject",
                isRequired: true,
                colSpan: 2,
                editorOptions: {
                  disabled: !(
                    hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                    evaluation?.idPartner === user.userId
                  ),
                },
              },
              ...(isNewRow
                ? []
                : [
                    ...(isPartnerEvaluationByProject
                      ? idAreaType !== 10 && idAreaType !== 11
                        ? [
                            {
                              itemType: "group",
                              colCount: 2,
                              colSpan: 2,
                              items: [
                                {
                                  dataField: "fieldValue",
                                  editorOptions: {
                                    disabled: !(
                                      hasScope([
                                        pdsGroups.Admin,
                                        pdsGroups.Partner,
                                      ]) ||
                                      evaluation?.idPartner === user.userId
                                    ),
                                  },
                                },
                                {
                                  dataField: "fieldValue2",
                                  editorOptions: {
                                    disabled: !(
                                      hasScope([
                                        pdsGroups.Admin,
                                        pdsGroups.Partner,
                                      ]) ||
                                      evaluation?.idPartner === user.userId
                                    ),
                                  },
                                },
                              ],
                            },
                          ]
                        : [
                            {
                              dataField: "fieldValue",
                              colSpan: 2,
                              editorOptions: {
                                disabled: !(
                                  hasScope([
                                    pdsGroups.Admin,
                                    pdsGroups.Partner,
                                  ]) || evaluation?.idPartner === user.userId
                                ),
                              },
                            },
                          ]
                      : [
                          {
                            dataField: "isApplicable",
                            colSpan: 2,
                            disabled: !(
                              hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                              evaluation?.idPartner === user.userId
                            ),
                            label: {
                              text: "Do you feel comfortable to evaluate this professional?",
                            },
                          },
                        ]),
                    {
                      itemType: "group",
                      colCount: 2,
                      colSpan: 2,
                      items: [
                        {
                          dataField: "fieldText",
                          editorType: "dxTextArea",
                          caption:
                            idAreaType === 10 || idAreaType === 11
                              ? "Strong Points Key Message"
                              : "Building Relationship Key Message",
                          editorOptions: {
                            height: 200,
                            disabled: !(
                              hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                              evaluation?.idPartner === user.userId
                            ),
                          },
                        },
                        {
                          dataField: "fieldText2",
                          editorType: "dxTextArea",
                          caption:
                            idAreaType === 10 || idAreaType === 11
                              ? "Development Points Key Message"
                              : "Project Key Message",
                          editorOptions: {
                            height: 200,
                            disabled: !(
                              hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                              evaluation?.idPartner === user.userId
                            ),
                          },
                        },
                      ],
                    },
                    {
                      dataField: "isActive",
                      colSpan: 2,
                      disabled: !(
                        hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                        evaluation?.idPartner === user.userId
                      ),
                    },
                  ]),
            ],
          },
        }}
      >
        <Column
          dataField="idEvaluator"
          caption="Evaluator"
          alignment="center"
          cellRender={renderPhotoCell}
          lookup={{
            dataSource: master.professionals,
            displayExpr: "Name",
            valueExpr: "ID",
          }}
        />
        <Column
          dataField="workDays"
          dataType="number"
          caption="Work Days"
          alignment="center"
        />
        <Column
          dataField="idProject"
          caption="Project"
          dataType="number"
          cellRender={(e) => (
            <>
              {e.data.projectCode} - {e.data.projectName}
            </>
          )}
          alignment="left"
          lookup={{
            dataSource: master.projects,
            displayExpr: "displayName",
            valueExpr: "ID",
          }}
        />
        <Column
          dataField="isActive"
          caption="Considered?"
          dataType="boolean"
          alignment="center"
        />
        {idAreaType !== 10 && idAreaType !== 11 && (
          <Column
            dataField="fieldValue2"
            caption="Project Grade"
            dataType="number"
            alignment="center"
            visible={isPartnerEvaluationByProject}
            disabled={
              !(
                hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
                evaluation?.idPartner === user.userId
              )
            }
          />
        )}
        <Column
          dataField="fieldValue"
          caption={
            idAreaType === 10 || idAreaType === 11
              ? "Overall Grade"
              : "Building Relationship Grade"
          }
          alignment="center"
          visible={isPartnerEvaluationByProject}
        />
        <Column
          dataField="fieldText"
          caption={
            idAreaType === 10 || idAreaType === 11
              ? "Strong Points Key Message"
              : !isPartnerEvaluationByProject
              ? "Has the professional contributed in your professional development during this project? Has this professional invested time and provided tools and environment on your development? Please, give examples."
              : "Building Relationship Key Message"
          }
          alignment="center"
          visible={false}
        />
        <Column
          dataField="fieldText2"
          caption={
            idAreaType === 10 || idAreaType === 11
              ? "Development Points Key Message"
              : !isPartnerEvaluationByProject
              ? "What are the development points of this professional in this pillar (Professional Development)?"
              : "Project Key Message"
          }
          alignment="center"
          visible={false}
        />
        <Column
          dataField="isApplicable"
          caption="Applicable?"
          dataType="boolean"
          alignment="center"
          visible={!isPartnerEvaluationByProject}
        />
      </DataGrid>
    </Container>
  );
}
