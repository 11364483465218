import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  margin-bottom: 32px;
`;

export const Label = styled.label`
  font-weight: 700;
`;

export const LoadingContainer = styled.label`
  padding: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;