//
import React, { useCallback, useEffect, useState } from "react";
import { WhisperSpinner } from "react-spinners-kit";
import { useHistory } from "react-router-dom";
import { Box, Headline, Row, Subtitle, ContainerLoading } from "./styles";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";

export default function Home() {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});

  const loadDashboardData = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get("api/dashboard");
    setDashboardData(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadDashboardData();
  }, [loadDashboardData]);

  const handlePush = (link) => {
    history.push(link);
  };

  return (
    <>
      {loading ? (
        <ContainerLoading>
          <WhisperSpinner size={50} color="#8b0304" backColor="#8b0304" />
        </ContainerLoading>
      ) : (
        <div>
          <h1
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#333",
              marginBottom: 25,
            }}
          >
            Welcome {user?.userName ?? "to the Professional Development System"}
            !
          </h1>
          <Row>
            <Box onClick={() => handlePush("/toevaluate/")}>
              <Subtitle>Pending Evaluations</Subtitle>
              <Headline>{dashboardData?.evaluationsCount || 0}</Headline>
            </Box>

            <Box onClick={() => handlePush("/toevaluate/")}>
              <Subtitle>Inputs for Senior Evaluation</Subtitle>
              <Headline>{dashboardData.seniorEvaluationsCount || 0}</Headline>
            </Box>

            <Box onClick={() => handlePush("/mentorint/")}>
              <Subtitle>Mentorint</Subtitle>
              <Subtitle
                style={{
                  lineHeight: "8px",
                  fontSize: "12px",
                  marginTop: "-6px",
                }}
              >
                (last talk)
              </Subtitle>
              <Headline>
                {dashboardData?.lastMentorintTalk
                  ? new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }).format(new Date(dashboardData?.lastMentorintTalk))
                  : ""}
              </Headline>
            </Box>

            <Box onClick={() => handlePush("/mentorint/")}>
              <Subtitle>My Mentees</Subtitle>
              <Headline>{dashboardData?.mentoreesCount || 0}</Headline>
            </Box>
          </Row>
          <Row>
            <Box onClick={() => handlePush("/dosanddonts/")}>
              <Subtitle>Dos & Don’ts</Subtitle>
              <Subtitle
                style={{
                  lineHeight: "8px",
                  fontSize: "12px",
                  marginTop: "-6px",
                }}
              >
                (last update)
              </Subtitle>
              <Headline>
                {dashboardData?.dosDontsLastUpdate
                  ? new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }).format(new Date(dashboardData?.dosDontsLastUpdate))
                  : ""}
              </Headline>
            </Box>

            <Box onClick={() => handlePush("/counseling/")}>
              <Subtitle>Current Year's Counseling</Subtitle>
              <Subtitle
                style={{
                  lineHeight: "8px",
                  fontSize: "12px",
                  marginTop: "-6px",
                }}
              >
                (is filled?)
              </Subtitle>
              <Headline>
                {(dashboardData?.isCurrentYearCounselingFilled || false) ===
                true
                  ? "Yes"
                  : "No"}
              </Headline>
            </Box>
            <Box onClick={() => handlePush("/tutorship/")}>
              <Subtitle>Tutorship</Subtitle>
              <Subtitle
                style={{
                  lineHeight: "8px",
                  fontSize: "12px",
                  marginTop: "-6px",
                }}
              >
                (last talk)
              </Subtitle>
              <Headline>
                {dashboardData?.lastTutorshipTalkDate
                  ? new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }).format(new Date(dashboardData?.lastTutorshipTalkDate))
                  : ""}
              </Headline>
            </Box>
          </Row>
        </div>
      )}
    </>
  );
}
