import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import PopoverData from "./PopoverData";
import { MainHeader } from "./styles";

export default function HeaderCycle({ isSidePanelOpen }) {
  const { user } = useAuth();
  const [activeCyclesInfo, setActiveCyclesInfo] = useState([]);
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [title, setTitle] = useState("");
  const location = useLocation();

  // const normalizeKey = (str) => {
  //   return str
  //     .replace(/&/g, "And")
  //     .replace(/[|]/g, "")
  //     .replace(/\s+/g, "")
  //     .replace(/[^a-zA-Z0-9]/g, "");
  // };

  const loadCycle = useCallback(async () => {
    try {
      const { data } = await api.get("/api/cycles");
      const allowedAreaTypes = [1, 2, 3, 4, 5, 6];
      setActiveCyclesInfo(
        data.filter(
          (x) => x.isActive && allowedAreaTypes.includes(x.idAreaType)
        )
      );
    } catch (error) {
      console.error("Failed to load cycles:", error);
    }
  }, []);

  useEffect(() => {
    loadCycle();
  }, [loadCycle]);

  useEffect(() => {
    let title = "";

    switch (true) {
      case location.pathname.includes("admin/evaluations"):
        title = "Admin — Evaluations";
        break;
      case location.pathname.includes("admin/promotions"):
        title = "Admin — Promotions";
        break;
      case location.pathname.includes("admin/dosanddonts"):
        title = "Admin — Do's and Don'ts";
        break;
      case location.pathname.includes("admin/counseling"):
        title = "Admin — Counseling";
        break;
      case location.pathname.includes("admin/mentorint"):
        title = "Admin — Mentorint";
        break;
      case location.pathname.includes("admin/tutorship"):
        title = "Admin — Tutorship";
        break;
      case location.pathname.includes("myevaluations"):
        title = "My Evaluations";
        break;
      case location.pathname.includes("home"):
        title = "Welcome";
        break;
      case location.pathname.includes("allevaluations"):
        title = "All Evaluations";
        break;
      case location.pathname.includes("toevaluate"):
        title = "To Evaluate";
        break;
      case location.pathname.includes("businessunit"):
        title = "Business Unit";
        break;
      case location.pathname.includes("promotions"):
        title = "Promotions";
        break;
      case location.pathname.includes("counseling"):
        title = "Counseling";
        break;
      case location.pathname.includes("mentorint"):
        title = "Mentorint";
        break;
      case location.pathname.includes("dosanddonts"):
        title = "Do's and Don'ts";
        break;
      case location.pathname.includes("tutorship"):
        title = "Tutorship";
        break;
      case location.pathname.includes("actionplans"):
        title = "Action Plans";
        break;
      default:
        title = "Professional Development";
        break;
    }

    document.title =
      title === "Professional Development" ? title : "PD - " + title;
    setTitle(title);
  }, [location]);

  return (
    <MainHeader>
      <div>
        <h1 className="title">{title}</h1>
      </div>
      <div>
        {!location.pathname.includes("home") &&
          (isNarrow || isSidePanelOpen ? (
            <div style={{ gap: "10px" }}>
              <PopoverData activeCyclesInfo={activeCyclesInfo} />
            </div>
          ) : (
            <div>
              <div className="hideable">
                {activeCyclesInfo.map((c) => {
                  return (
                    <div className="headline-wrapper" key={c.id}>
                      <p className="headline">
                        <strong>
                          {c.period}Q - {c.year}
                        </strong>
                      </p>

                      <p className="subtitle">{c.areaType?.description}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        <img
          src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${user.login}_192_192.png`}
          alt={user.userName}
        />
      </div>
    </MainHeader>
  );
}
