import React, { useState, useEffect, useCallback } from "react";

import CustomStore from "devextreme/data/custom_store";
import { DataGrid } from "devextreme-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import Button from "../../../../../components/Button";
import api from "../../../../../services/api";
import { Column, Editing } from "devextreme-react/data-grid";

export default function DialogEditCycle({
  open,
  handleClose,
  cycleId,
  isWeightedAverage,
}) {
  const [cycleSource, setCycleSource] = useState(null);

  const loadCycle = useCallback(async () => {
    if (!isWeightedAverage) {
      setCycleSource({
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: async () => {
            const { data } = await api.get(`/api/cycles/${cycleId}`);
            return [data];
          },
          update: async (id, data) => {
            await api.put(`/api/cycles/${cycleId}`, data);
          },
        }),
        paginate: true,
      });
    } else {
      setCycleSource({
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: async () => {
            const { data } = await api.get(
              `/api/cycles/${cycleId}/senior-deadline`
            );
            return [data];
          },
          update: async (id, data) => {
            await api.put(`/api/cycles/${cycleId}/senior-deadline/${id}`, data);
          },
        }),
        paginate: true,
      });
    }
  }, [isWeightedAverage, cycleId]);

  useEffect(() => {
    loadCycle();
  }, [loadCycle]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>Edit Cycle</DialogTitle>
      <DialogContent>
        <DataGrid
          dataSource={cycleSource}
          visible={!isWeightedAverage}
          showBorders={false}
          onRowUpdating={(options) => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
        >
          <Editing mode="row" allowUpdating />
          <Column dataField="id" visible={false} />
          <Column
            dataField="draftDeadline"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="validateDeadline"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="discussionDeadline"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="completeDeadline"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="preparationDeadline"
            allowFiltering={false}
            dataType="date"
          />
        </DataGrid>
        <DataGrid
          dataSource={cycleSource}
          visible={isWeightedAverage}
          showBorders={false}
          key="id"
        >
          <Editing mode="row" allowUpdating />
          <Column dataField="id" visible={false} />
          <Column
            dataField="projectDefinitionStart"
            caption="Project Definition Start"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="projectDefinitionEnd"
            caption="Project Definition End"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="inputsStart"
            caption="Inputs Start"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="inputsEnd"
            caption="Inputs End"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="cellInputsStart"
            caption="Cell Inputs Start"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="cellInputsEnd"
            caption="Cell Inputs End"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="passEvaluationStart"
            caption="Pass Evaluation Start"
            allowFiltering={false}
            dataType="date"
          />
          <Column
            dataField="passEvaluationEnd"
            caption="Pass Evaluation End"
            allowFiltering={false}
            dataType="date"
          />
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
