import React, { useState, useCallback, useEffect } from "react";

import CustomStore from "devextreme/data/custom_store";

import api from "../../../services/api";
import { DataGrid } from "devextreme-react";
import { Column, Editing } from "devextreme-react/data-grid";
import { DataGridWrapper } from "../styles";

export default function AdminPromotionStatus() {
  const [promotionStatusSource, setPromotionStatusSource] = useState([]);

  const loadPromotionStatus = useCallback(async () => {
    const promotionStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/promotion-status");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/promotion-status", data);
      },
      update: async (id, data) => {
        await api.put(`/api/promotion-status/${id}`, {
          id,
          description: data.description,
          order: data.order,
        });
      },
    });

    setPromotionStatusSource(promotionStore);
  }, []);

  useEffect(() => {
    loadPromotionStatus();
  }, [loadPromotionStatus]);

  return (
    <DataGridWrapper>
      <DataGrid
        dataSource={promotionStatusSource}
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
        height={550}
      >
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="description" caption="Description" />
        <Column dataField="order" caption="Order" />
      </DataGrid>
    </DataGridWrapper>
  );
}
