import DataGrid, { Column, Lookup } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import pdsGroups from "../../config/pdsGroups";
import { ValueContext } from "../../context/ValueProvider";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import OfficeIcon from "../OfficeIcons";
import PhotoCell from "./CellRender/PhotoCell";
import { Container } from "./styles";

export default function TutorshipList({ path, setListData }) {
  const { addToast } = useToast();
  const history = useHistory();
  const { master } = useContext(ValueContext);
  const { user, hasScope } = useAuth();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin, pdsGroups.Tutorship]);
  }, [hasScope]);
  const { id } = useParams();

  const dataSource = useMemo(() => {
    return new DataSource({
      key: "id",
      loadMode: "raw",
      cacheRawData: true,
      load: async () => {
        try {
          const { data } = await api.get("api/tutorship/");
          return data;
        } catch (err) {
          addToast({
            type: "error",
            title: "Tutorship data couldn't be loaded",
          });
          throw err;
        }
      },

      update: async (key, values) => {
        try {
          const currentItem = dataSource.items().find((i) => i.id === key);

          const idTutor =
            values.professionalTutor && values.professionalTutor.idTutor
              ? values.professionalTutor.idTutor
              : currentItem.professionalTutor &&
                currentItem.professionalTutor.idTutor
              ? currentItem.professionalTutor.idTutor
              : null;

          if (!idTutor) {
            addToast({
              type: "error",
              title: "Failed to save",
              description: "Please select a tutor.",
            });
            throw new Error("Tutor selection is required.");
          }

          const startDate =
            values.professionalTutor && values.professionalTutor.startDate
              ? values.professionalTutor.startDate
              : currentItem.professionalTutor &&
                currentItem.professionalTutor.startDate
              ? currentItem.professionalTutor.startDate
              : null;

          if (!startDate) {
            addToast({
              type: "error",
              title: "Failed to save",
              description: "The start date is required.",
            });
            throw new Error("The start date is required.");
          }

          const updatedData = {
            idProfessional: key,
            idTutor: idTutor,
            startDate: startDate,
          };

          await api.post(`api/tutorship`, updatedData);
          addToast({
            type: "success",
            title: "Saved",
          });
        } catch (error) {
          addToast({
            type: "error",
            title: "Something went wrong, try again later",
            description: error.message || "",
          });
          throw error;
        }
      },

      remove: async (key) => {
        try {
          const item = dataSource.items().find((i) => i.id === key);
          await api.delete(`api/tutorship/${item.professionalTutor.id}`);
          addToast({
            type: "success",
            title: "Deleted successfully",
          });
        } catch (error) {
          addToast({
            type: "error",
            title: "Something went wrong, try again later",
            description: "",
          });
        }
      },
    });
  }, [addToast]);

  useEffect(() => {
    if (id) {
      dataSource.load().then((data) => {
        const professional = data.find((x) => x.id == id);
        setListData(professional);
      });
    }
  }, [id, dataSource, setListData]);

  return (
    <Container>
      <DataGrid
        dataSource={dataSource}
        key="id"
        width="100%"
        height="100%"
        headerFilter={{ visible: true, allowSearch: true }}
        columnAutoWidth
        sorting={{ mode: "multiple" }}
        editing={{
          mode: "popup",
          allowUpdating: isAdmin,
          allowDeleting: (options) => {
            return options.row.data.professionalTutor !== undefined && isAdmin;
          },
        }}
        onRowClick={(e) => {
          history.push(`${e.data?.id}`);
          setListData(e?.data);
        }}
        onToolbarPreparing={(e) => {
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
              text: "Clear Filters",
              elementAttr: { class: "clear-filter" },
              icon: "filter",
              onClick: () => {
                e.component.clearFilter();
              },
            },
          });
        }}
      >
        <Column
          dataField="name"
          caption="Professional"
          cellRender={(e) => (
            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
              <PhotoCell
                data={master.professionals?.find((p) => p.ID === e.data.id)}
              />
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                {e.data.name}
                <span style={{ color: "#8b0304" }}>
                  {e.key === user.userId
                    ? "(You)"
                    : e.data.isMentoree
                    ? "(Mentee)"
                    : e.data.isTutoree
                    ? "(Tutoree)"
                    : ""}
                </span>
              </div>
            </div>
          )}
          formItem={{ visible: false }}
          allowEditing={false}
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="Name"
          />
        </Column>

        <Column
          dataField="jobTitle.name"
          caption="Position"
          formItem={{ visible: false }}
          allowEditing={false}
        />

        <Column
          dataField="offices[0].name"
          caption="Office"
          cellRender={(e) => (
            <OfficeIcon
              office={e.data.offices[0]?.name ? e.data.offices[0].name : null}
              className="office-icon"
            />
          )}
          formItem={{ visible: false }}
          allowEditing={false}
        />
        <Column
          dataField="practices[0].name"
          caption="Practice"
          alignment="left"
          formItem={{ visible: false }}
          allowEditing={false}
        />
        <Column
          dataField="professionalTutor.idTutor"
          caption="Tutor"
          cellRender={(e) => (
            <PhotoCell
              data={master.professionals?.find(
                (p) => p.ID === e.data.professionalTutor?.idTutor
              )}
            />
          )}
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          caption="Start Date"
          alignment="center"
          dataField="professionalTutor.startDate"
          dataType="date"
          format="dd MMM yyyy"
          editorOptions={{ openOnFieldClick: true }}
        />
      </DataGrid>
    </Container>
  );
}
