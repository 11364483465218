import styled from "styled-components";

export const Container = styled.div`
  background-color: #efefef;
  position: relative;
  width: 100%;
  height: 100%;

  h1 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 0.2em;
    color: #333;
  }

  .selectDiv {
    position: absolute;
    z-index: 10;
    padding: 5px;
  }
`;
