import React, { useState } from "react";
import { Popover } from "@material-ui/core";
import { PopoverDiv } from "./styles";
import { FiChevronDown } from "react-icons/fi";

export default function PopoverData({ activeCyclesInfo }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FiChevronDown
        onClick={handleClick}
        color="#333"
        size={25}
        style={{ cursor: "pointer" }}
      />

      <Popover
        id="popover-cycles"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PopoverDiv>
          {activeCyclesInfo.map((c) => {
            return (
              <div className="wrapper" key={c.id}>
                <p className="period">
                  {c.period}Q - {c.year}
                </p>
                <p className="description">{c.areaType?.description}</p>
              </div>
            );
          })}
        </PopoverDiv>
      </Popover>
    </>
  );
}
