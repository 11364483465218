import styled, { css } from "styled-components";

export const AddDoDont = styled.button`
  width: 100%;
  text-align: center;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #ffffff;
  font-weight: 700;
  border: none;
  outline: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
  transition: box-shadow 0.2s;
  color: #8b0304;

  &:hover {
    box-shadow: 0px 1px 3px 0px rgb(60 64 67 / 30%),
      0px 4px 8px 3px rgb(60 64 67 / 15%);
  }
`;
export const SectionBody = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;

  .welcomeDoDont {
    font-weight: 700;
    font-size: 24px;
    margin: 15px 0;
  }

  .terms {
    font-weight: 500;
    font-size: 14px;
  }

  .travelSelectDiv {
    margin-top: 15px;
    margin-bottom: 35px;
    width: 100%;

    p {
      font-weight: 700;
      margin-bottom: 10px;
    }

    select {
      width: 100%;
    }

    .dx-placeholder {
      color: black;
      font-size: 16px;
    }
  }

  .dosdonts {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
    align-items: flex-start;

    .dosDontsList {
      width: 50%;
      display: flex;
      flex-direction: column;

      h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }
    }
  }

  .counters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    margin-bottom: 30px;

    .counter {
      width: 15%;
      text-align: center;
      .square {
        background-color: white;
        text-align: center;
        padding: 15px;

        p {
          color: #999999;
          font-weight: bold;
          font-size: 22px;
        }
      }
      p {
        color: #999999;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.1em;
      }
    }
  }

  .overview {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #999999;
    }
  }

  hr {
    border: 2px solid #dddddd;
    margin-bottom: 10px;
  }
`;
export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
`;

export const TimelineItem = styled.div`
  display: flex;
  padding: 0.625rem 1.25rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.3125rem;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 0.4375rem;
  background: #fff;
  box-shadow: 0px 0px 38.36283px 0px rgba(0, 0, 0, 0.1);

  & > div:first-child {
    flex: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }

  .icon {
    background-color: #8b0304;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item__title {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    margin-bottom: 0;

    .project__tag {
      display: flex;
      align-items: center;
      gap: 0.3125rem;
      flex-direction: row;
      color: #8b0304;
    }

    .icons {
      width: auto;
      height: 0.75rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .project__title {
      color: #333;

      text-align: right;
      font-family: Roboto Condensed;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.03625rem;
    }

    span {
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      font-weight: 300;
    }

    span:not(:last-child) {
      margin-right: 10px;
    }

    .link {
      color: #8b0304;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.65, 0.02, 0.28, 0.96) 0s;
    }

    .link:hover {
      text-decoration: underline;
    }

    .title {
      /* background-color: #fff; */
      /* border-radius: 5px; */
      /* padding: 5px 15px; */

      h4 {
        font-size: 14px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
      }
    }
  }
  .updateAt__dodont {
    color: #ccc;
    text-align: right;
    font-family: Roboto Condensed;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.03625rem; /* 138.165% */
  }
  .item__text {
    display: flex;
    align-self: stretch;
    overflow: hidden;
    color: #333;
    line-break: anywhere;
    text-overflow: ellipsis;
    font-family: Roboto Condensed;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }
  .item__text.closed {
    max-height: 8.4375rem;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }
`;

export const Container = styled.div`
  font-weight: lighter;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 50px;
  }

  ${({ isSidePanelFullScreen }) =>
    isSidePanelFullScreen &&
    css`
      flex-direction: row;
      justify-content: space-between;
    `}

  .int-text-area {
    flex: 1;
  }

  h3 {
    font-size: 0.75rem;
    margin-bottom: 1em;
  }

  h3.conclusion {
    font-size: 0.875rem;
    margin-bottom: 1em;
  }

  strong {
    font-weight: bold;
  }

  .key-messages {
    display: flex;
    justify-content: center;
    flex: 1;
    gap: 20px;

    .key-message {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 7px;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      padding: 10px 15px;
    }

    strong {
      font-size: 1rem;
    }

    h3 {
      font-size: 0.875rem;
      color: #ccc;
      margin: 0;
    }
  }

  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border-color: #f5f5f5;
  }
  .grid {
    box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
    border-radius: 7px;
    overflow: hidden;
  }

  .dx-datagrid {
    color: #333;
  }

  .dx-editor-cell:first-of-type > .dx-texteditor .dx-texteditor-input {
    padding-left: 0.8rem !important;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
    border: none;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    color: #999;
    font-size: 0.75rem;
    font-weight: 300;
  }

  /* .dx-datagrid-rowsview .dx-row {
    cursor: pointer;

    :hover {
      background-color: #f5f5f5ff;
    }
    :active {
      background-color: #d9d9d9ff;
    }
  } */

  .dx-datagrid-rowsview .dx-row.dx-freespace-row {
    cursor: default;

    :hover {
      background-color: transparent;
    }
    :active {
      background-color: transparent;
    }
  }

  .dx-datagrid .dx-row > td {
    font-size: 0.75rem;
    font-weight: 400;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #f5f5f5;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-data-row > td {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 0;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0 0 30px rgba(0 0 0 / 15%);
    background: #8b0304;
    color: white;

    :hover {
      background: #b80304;
    }
    :active {
      background: #a80304;
    }
  }
  .office-icon {
    width: 24px;
    height: 24px;
  }

  .updateDate {
    display: flex;
    padding: 0.625rem 1.25rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.4375rem;
    background: rgba(237, 206, 97, 0.5);
    width: 100%;
  }
  .h3__updateDate {
    color: #c7a634;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.03625rem; /* 138.165% */
    height: auto;
    margin-bottom: 0;
  }
`;
