import styled from "styled-components";

export const Container = styled.div`
  & > .dx-texteditor.dx-editor-outlined {
    background-color: #dedede;
    box-shadow: none;
  }

  /* & > .dx-texteditor.dx-editor-underlined {
    background-color: white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding: 0 10px;
  } */

  .dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    padding: 0 !important;
    font-size: 0.8rem;
  }

  .dx-texteditor.dx-editor-underlined::after {
    border: none !important;
  }

  /* & > .dx-tagbox.dx-editor-filled .dx-tag-container,
  .dx-tagbox.dx-editor-outlined .dx-tag-container {
    padding: 9px 10px 10px !important;
    min-height: 42px !important;
  } */

  & > .dx-placeholder {
    font-size: 0.8rem;
  }

  & > .dx-tagbox.dx-editor-filled .dx-texteditor-input,
  .dx-tagbox.dx-editor-outlined .dx-texteditor-input {
    margin-top: 0;
  }

  & > .dx-textbox {
    line-height: 0;
    font-size: 0.8rem;

    input {
      font-size: 0.8rem;
      padding: 0 !important;
    }
  }

  & > .dx-tagbox .dx-texteditor-input {
    height: auto !important;
  }

  .dx-tag {
    margin: 0;
  }

  .dx-tag-container.dx-texteditor-input-container {
    gap: 10px 5px;
    margin: 0 !important;
    padding: 10px 0;
  }

  .dx-tag-content {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #eee !important;
    color: #666;
    font-weight: bold;
  }
`;
