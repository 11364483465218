import styled, { css } from "styled-components";

const statusesColors = {
  1: "#cccccc",
  2: "#d15c56",
  3: "#edce61",
  4: "#52b9d9",
  5: "#4cb56f",
  6: "#333333",
  true: "#8b0304",
  false: "#666666",
};

export const TopMenu = styled.div`
  ${(props) => css`
    background-color: ${statusesColors[props.status]};
  `};
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 10px 20px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  flex: 0 0 85px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    filter: saturate(0) brightness(0.95);
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
  }

  .evaluators {
    display: flex;
    gap: 10px;
    & > div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .tools {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;

    & > div {
      cursor: pointer;
      mix-blend-mode: overlay;

      :hover {
        opacity: 0.5;
      }
      :active {
        mix-blend-mode: normal;
      }
    }
  }

  .profile {
    display: flex;
    padding: 15px 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    background-color: white;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    position: absolute;
    top: 45px;
    left: 20px;
    width: calc(100% - 40px);
    z-index: 10;

    h2 {
      font-size: 1rem;
      font-weight: bold;
      color: #333333;
    }

    h3 {
      color: #999;
      font-weight: 400;

      & > b {
        font-weight: bold;
      }
    }

    & > div {
      display: flex;
      gap: 10px;
    }

    img.professional {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      filter: brightness(0.95);
    }

    img.office-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      filter: brightness(0.95);
    }

    .profile-info {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      h3 {
        color: #333;
        text-align: center;
        font-family: Roboto Condensed;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .buttons-wrapper {
      width: 1.25rem;
      height: 1.25rem;
      fill: #8b0304;

      & > path {
        stroke: #999;
      }
    }
  }
`;
