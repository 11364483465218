import React, { useContext, useMemo } from "react";
import { Container } from "./styles";
import TextArea from "../../TextArea";
import { SelectBox } from "devextreme-react";
import useUpdateField from "../../../hooks/useUpdateField";
import { useAuth } from "../../../hooks/auth";

import { useEffect } from "react";
import { useCallback } from "react";
import api from "../../../services/api";
import { ValueContext } from "../../../context/ValueProvider";
import { useState } from "react";
import pdsGroups from "../../../config/pdsGroups";
import ContentLoader from "react-content-loader";

export default function Overall({ promotion, isSidePanelFullScreen }) {
  const { updateField } = useUpdateField();
  const [pillars, setPillars] = useState([]);
  const [totalGrade, setTotalGrade] = useState(0);
  const [loading, setLoading] = useState(false);
  const { master } = useContext(ValueContext);
  const { user, hasScope } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);
  const loadPillarsAndGrades = useCallback(async () => {
    setLoading(true);

    try {
      const evaluations = promotion.evaluation || [];
      const pillarsMap = {};
      let totalGrades = [];

      for (const evaluation of evaluations) {
        const [pillarsResponse, totalGradeResponse] = await Promise.all([
          api.get(`/api/pillars`, {
            params: {
              idEvaluation: evaluation.id,
            },
          }),
          api.get(`/api/evaluations/${evaluation.id}/grade`),
        ]);
        const activePillars =
          pillarsResponse.data.filter((x) => x.isActive) || [];

        const evaluationPillars = evaluation.evaluationPillars || [];

        activePillars.forEach((pillar) => {
          const pillarId = pillar.id;

          const evaluationPillar = evaluationPillars.find(
            (ep) => ep.idPillar === pillarId
          );

          const pillarValue = evaluationPillar?.fieldValue || 0;

          if (!pillarsMap[pillarId]) {
            pillarsMap[pillarId] = {
              ...pillar,
              totalValue: pillarValue,
              count: 1,
            };
          } else {
            pillarsMap[pillarId].totalValue += pillarValue;
            pillarsMap[pillarId].count += 1;
          }
        });

        const grade = totalGradeResponse.data?.totalAverage ?? 0;
        totalGrades.push(grade);
      }

      const averagedPillars = Object.values(pillarsMap).map((pillar) => {
        const averageValue =
          pillar.count > 0 ? pillar.totalValue / pillar.count : 0;
        return {
          ...pillar,
          value: averageValue,
        };
      });

      setPillars(averagedPillars);

      const combinedTotalGrade =
        totalGrades.reduce((sum, grade) => sum + grade, 0) /
          totalGrades.length || 0;

      setTotalGrade(combinedTotalGrade);
    } catch (error) {
      console.error("Erro ao carregar pilares e notas:", error);
    } finally {
      setLoading(false);
    }
  }, [promotion.evaluation]);

  useEffect(() => {
    if (promotion.evaluation) loadPillarsAndGrades();
  }, [loadPillarsAndGrades, promotion.evaluation]);

  const handleTextChange = (key, value) => {
    updateField(
      "put",
      `/api/promotion/${promotion.id}`,
      {
        [key]: value,
      },
      "promotion"
    );
  };

  const handleSelectBoxChange = (key, value) => {
    updateField("put", `/api/promotion/${promotion.id}`, {
      [key]: value,
    });
  };

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <div className="key-messages">
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Mentor
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.mentor?.name}
          </p>
        </div>
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Entry Date
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.entryDate
              ? new Date(promotion.entryDate).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "N/A"}
          </p>
        </div>
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Graduation Date
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.professional?.graduationDate
              ? new Date(
                  promotion.professional?.graduationDate
                ).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "N/A"}
          </p>
        </div>
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Last Promotion
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.professional?.lastPromotion
              ? new Date(
                  promotion.professional?.lastPromotion
                ).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "N/A"}
          </p>
        </div>
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Last Range Adj.
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.professional?.lastRangeAdjustment
              ? new Date(
                  promotion.professional?.lastRangeAdjustment
                ).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "N/A"}
          </p>
        </div>

        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Current Grade
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.currentGrade ?? "N/A"}
          </p>
        </div>
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Expected Grade
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.expectedGrade ?? "N/A"}
          </p>
        </div>
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Current Range
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.currentGradeRange ?? "N/A"}
          </p>
        </div>
        <div className="key-message">
          <p style={{ color: "#dedede", fontWeight: "bold", fontSize: 14 }}>
            Expected Range
          </p>
          <p style={{ color: "#333333", fontWeight: 600, fontSize: 16 }}>
            {promotion.expectedGradeRange ?? "N/A"}
          </p>
        </div>
      </div>
      {loading ? (
        <ContentLoader
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" width="552" height="192" />
        </ContentLoader>
      ) : (
        <>
          {isAdmin && (
            <div
              style={{
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#666666",
                  marginLeft: "16px",
                }}
              >
                Sales Coach
              </label>
              <SelectBox
                caption="Sales Coach"
                style={{ width: "100%" }}
                dataSource={master.professionals}
                searchEnabled
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Select Sales Coach"
                defaultValue={promotion.idSalesCoach}
                onValueChanged={(e) =>
                  handleSelectBoxChange("idSalesCoach", e.value)
                }
              />
            </div>
          )}

          <TextArea
            caption="Key Message"
            value={promotion.textRecommendation}
            onChanged={(value) => handleTextChange("textRecommendation", value)}
          />
        </>
      )}
      {loading ? (
        <ContentLoader
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" width="553" height="234" />
        </ContentLoader>
      ) : (
        <>
          {promotion.evaluation ? (
            <div className="pillars">
              <div className="pillar">
                <div
                  style={{
                    color: "#8b0304",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  Overall Grade
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                    }}
                  >
                    (current cycle)
                  </p>
                </div>
                <p style={{ color: "#8b0304", fontWeight: "bold" }}>
                  {totalGrade?.toFixed(2) ?? 0}
                </p>
              </div>
              {pillars.map((pillar, index) => (
                <div className="pillar" key={index}>
                  <p style={{ color: "#333333", fontWeight: "bold" }}>
                    {pillar?.name}
                  </p>
                  <p style={{ color: "#333333", fontWeight: "bold" }}>
                    {pillar.value?.toFixed(2) ?? 0}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <strong style={{ textAlign: "center" }}>
              No evaluation for this professional in the current active cycle.
            </strong>
          )}
        </>
      )}
    </Container>
  );
}
