export default {
  Corporate: "Integration Corporate Area",
  Consultancy: "All Consultancy Professionals",
  Manager: "API.PDS.Manager",
  CoManager: "API.PDS.CoManager",
  Director: "API.PDS.Director",
  Partner: "API.PDS.Partner",
  Admin: "API.PDS.Admin",
  Sales: "API.PDS.Sales",
  Delivery: "API.PDS.Delivery",
  KNM: "API.PDS.KNM",
  Allocation: "API.PDS.Allocation",
  PDC: "API.PDS.PDC",
  EvaluationCommittee: "API.PDS.EvaluationCommittee",
  CorporateLeaders: "API.PDS.CorporateLeaders",
  CultureAndProfessionalCommittee: "API.PDS.CultureAndProfessionalCommittee",
  WeeklyManagementMeeting: "API.PDS.WeeklyManagementMeeting",
  Tutorship: "API.PDS.Tutorship",
};
