import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import pdsGroups from "../../config/pdsGroups";
import { TriggerContext } from "../../context/TriggerProvider";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import SidePanelLoader from "../ContentLoader";
import EvaluationByProjectsList from "../EvaluationList/EvaluationByProjects";
import MenteeEvaluationList from "../EvaluationList/MenteeEvaluation";
import SidePanelHeader from "./Header";
import Conclusion from "./Tabs/Conclusion";
import Overall from "./Tabs/Overall";
import Overview from "./Tabs/Overview";
import Pillars from "./Tabs/Pillars";
import { Container } from "./styles";

export default function SidePanel({
  isSidePanelFullScreen,
  setIsSidePanelOpen,
  setIsSidePanelFullScreen,
  path,
}) {
  const { user, hasScope } = useAuth();
  const isSales = useMemo(() => hasScope(pdsGroups.Sales), [hasScope]);
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const { trigger, triggers } = useContext(TriggerContext);

  const [evaluation, setEvaluation] = useState();
  const [canSeeOverallAndPillarsTabs, setCanSeeOverallAndPillarsTabs] =
    useState(false);
  const [pillars, setPillars] = useState();
  const [areaTypes, setAreaTypes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGrades, setIsLoadingGrades] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [allowShowEvaluationBy, setAllowShowEvaluationBy] = useState(false);

  const isSenior = useMemo(() => {
    return hasScope([pdsGroups.Manager, pdsGroups.Director, pdsGroups.Partner]);
  }, [hasScope]);

  const handleTabClick = useCallback((index) => {
    setActiveTab(index);
  }, []);

  const handleChangeIsLocked = useCallback(
    async (newValue) => {
      try {
        addToast({
          type: "info",
          title: "Wait a moment...",
        });

        await api.put(`/api/evaluations/${evaluation?.id}`, {
          isLocked: newValue,
        });

        setEvaluation((prevEvaluation) => ({
          ...prevEvaluation,
          isLocked: newValue,
        }));

        addToast({
          type: "success",
          title:
            newValue === true ? "Evaluation locked" : "Evaluation unlocked",
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Error updating evaluation",
        });
        throw err;
      }
    },
    [evaluation, addToast]
  );

  const isEvaluationSenior = useMemo(() => {
    const areaType = areaTypes?.find(
      (areaType) =>
        areaType.id === evaluation?.idAreaType ||
        areaType.id === evaluation?.cycle?.idAreaType
    );
    return areaType?.isWeightedAverage;
  }, [evaluation, areaTypes]);

  const tabs = useMemo(() => {
    const pillarTabs =
      !pillars || !evaluation
        ? []
        : pillars
            .filter(
              (p) =>
                p.idAreaType === evaluation.idAreaType ||
                p.idAreaType === evaluation?.cycle?.idAreaType
            )
            .map((p) => ({
              label: p.name,
              content: (
                <Pillars
                  isSidePanelFullScreen={isSidePanelFullScreen}
                  evaluation={evaluation}
                  idEvaluation={id}
                  pillar={p}
                  isEvaluationSenior={isEvaluationSenior}
                  isLoadingGrades={isLoadingGrades}
                />
              ),
            }));
    const teamEvaluationTab = {
      label: isSidePanelFullScreen
        ? "Team evaluation by projects"
        : "Team eval. by proj.",
      content: (
        <EvaluationByProjectsList
          type="team"
          isSidePanelFullScreen={isSidePanelFullScreen}
          idEvaluation={id}
        />
      ),
    };
    const menteeEvaluationTab = {
      label: "Mentee Evaluation",
      content: (
        <MenteeEvaluationList
          isSidePanelFullScreen={isSidePanelFullScreen}
          evaluation={evaluation}
          idEvaluation={id}
        />
      ),
    };
    const evaluationByTabs =
      !evaluation || !pillars
        ? []
        : isEvaluationSenior
        ? [teamEvaluationTab, menteeEvaluationTab].filter((tab) => tab !== null)
        : [];

    const tabs = [
      {
        label: "Overview",
        visible: isSales ? evaluation?.idProfessional === user.userId : true,
        content: (
          <Overview
            isSidePanelFullScreen={isSidePanelFullScreen}
            evaluation={evaluation}
            pillars={pillars}
            isLoadingGrades={isLoadingGrades}
            isEvaluationSenior={isEvaluationSenior}
          />
        ),
      },
      {
        label: "Overall",
        visible: isSales
          ? evaluation?.idProfessional === user.userId
          : canSeeOverallAndPillarsTabs,
        content: (
          <Overall
            isSidePanelFullScreen={isSidePanelFullScreen}
            evaluation={evaluation}
            idEvaluation={id}
          />
        ),
      },
      {
        label: isSidePanelFullScreen
          ? "Partner evaluation by projects"
          : "Partner eval. by proj.",
        visible:
          !isSales &&
          (evaluation?.areaType?.isWeightedAverage ||
            evaluation?.cycle?.areaType?.isWeightedAverage) &&
          (hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
            evaluation?.idPartner === user.userId ||
            (evaluation?.idProfessional === user.userId &&
              (evaluation?.idEvaluationStatus === 4 ||
                evaluation?.idEvaluationStatus === 5))),
        content: (
          <EvaluationByProjectsList
            type="partner"
            isSidePanelFullScreen={isSidePanelFullScreen}
            idEvaluation={id}
            idAreaType={evaluation?.idAreaType || evaluation?.cycle?.idAreaType}
            evaluation={evaluation}
          />
        ),
      },
      ...evaluationByTabs.map((tab) => ({
        ...tab,
        visible: !isSales && allowShowEvaluationBy,
      })),
      ...pillarTabs.map((tab) => ({
        ...tab,
        visible: isSales
          ? (evaluation?.idProfessional === user.userId &&
              canSeeOverallAndPillarsTabs) ||
            (evaluation?.idProfessional !== user.userId &&
              tab.label.toUpperCase() === "EXECUTE THE DAY TO DAY")
          : canSeeOverallAndPillarsTabs,
      })),
      {
        label: "Conclusion",
        visible: isSales ? evaluation?.idProfessional === user.userId : true,
        content: (
          <Conclusion
            isSidePanelFullScreen={isSidePanelFullScreen}
            evaluation={evaluation}
            idEvaluation={id}
          />
        ),
      },
    ];

    return tabs;
  }, [
    pillars,
    evaluation,
    isSidePanelFullScreen,
    id,
    isEvaluationSenior,
    isSales,
    user.userId,
    isLoadingGrades,
    canSeeOverallAndPillarsTabs,
    hasScope,
    allowShowEvaluationBy,
  ]);

  useEffect(() => {
    setIsSidePanelOpen(true);
    const source = axios.CancelToken.source();
    const loadEvaluation = async () => {
      try {
        setIsLoading(true);
        await api.get(`/api/evaluations/${id}/grade`, {
          cancelToken: source.token,
        });
        const [evaluation, pillars, areaTypes] = await Promise.all([
          api.get(`/api/evaluations/${id}`, {
            cancelToken: source.token,
          }),
          api.get(`/api/pillars`, {
            params: {
              idEvaluation: id,
            },
            cancelToken: source.token,
          }),

          api.get("api/area-types"),
        ]);
        setEvaluation(evaluation.data);
        setCanSeeOverallAndPillarsTabs(
          evaluation.data.idProfessional === user.userId
            ? isSenior
              ? true
              : evaluation.data.evaluationStatus.id === 4 ||
                evaluation.data.evaluationStatus.id === 5
            : true
        );

        setAllowShowEvaluationBy(
          hasScope([pdsGroups.Admin, pdsGroups.Partner]) ||
            evaluation.data.idPartner === user.userId
        );
        if (isSales) {
          if (evaluation.data.idProfessional === user.userId)
            setPillars(pillars.data.filter((x) => x.isActive));
          else {
            setPillars(
              pillars.data
                .filter(
                  (x) =>
                    x.isActive &&
                    x.name.toUpperCase() === "EXECUTE THE DAY TO DAY"
                )
                .map((pillar) => ({
                  ...pillar,
                  competences: pillar.competences.filter(
                    (comp) => comp.description.toUpperCase() === "SALES"
                  ),
                }))
            );
          }
        } else {
          setPillars(pillars.data.filter((x) => x.isActive));
        }
        setAreaTypes(areaTypes.data);
        setIsLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          console.warn("Request canceled", err.message);
        } else {
          addToast({
            type: "error",
            title: "Something went wrong on loading data",
          });
          history.push("/" + path + "/");
          setIsSidePanelFullScreen(false);
          setIsSidePanelOpen(false);
          throw err;
        }
      }
    };
    loadEvaluation();

    return () => {
      source.cancel("Operation canceled by the user.");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, trigger]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const source = axios.CancelToken.source();
      const loadEvaluation = async () => {
        try {
          setIsLoadingGrades(true);
          await api.get(`/api/evaluations/${id}/grade`, {
            cancelToken: source.token,
          });
          const evaluation = await api.get(`/api/evaluations/${id}`, {
            cancelToken: source.token,
          });
          setEvaluation(evaluation.data);
        } catch (err) {
          if (axios.isCancel(err)) {
            console.warn("Request canceled", err.message);
          } else {
            addToast({
              type: "error",
              title: "Something went wrong on loading data",
            });
          }
        } finally {
          setIsLoadingGrades(false);
        }
      };
      loadEvaluation();

      return () => {
        source.cancel("Operation canceled by the user.");
      };
    }
  }, [addToast, id, triggers.updateGrade, triggers.updateText]);

  useEffect(() => {
    setActiveTab(0);
  }, [id]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {isLoading ? (
        <SidePanelLoader width="100%" />
      ) : (
        <>
          <SidePanelHeader
            isSidePanelFullScreen={isSidePanelFullScreen}
            setIsSidePanelFullScreen={setIsSidePanelFullScreen}
            setIsSidePanelOpen={setIsSidePanelOpen}
            evaluation={evaluation}
            pillars={pillars}
            path={path}
            isLoadingGrades={isLoadingGrades}
            isEvaluationSenior={isEvaluationSenior}
            handleChangeIsLocked={handleChangeIsLocked}
          />

          {!isSidePanelFullScreen && (
            <div className="tabs-menu">
              {tabs.length > 0 &&
                tabs
                  ?.filter((x) => x?.visible)
                  .map((tab, index) => (
                    <div
                      key={index}
                      className={activeTab === index ? "active" : ""}
                      onClick={() => {
                        handleTabClick(index);
                      }}
                    >
                      {tab.label}
                    </div>
                  ))}
            </div>
          )}

          <div
            className={`content-wrapper ${
              !isSidePanelFullScreen ? "no-scroll" : ""
            }`}
          >
            {tabs.length > 0 &&
              tabs
                ?.filter((x) => x?.visible)
                .map((tab, index) => (
                  <div
                    key={index}
                    className="content"
                    style={{
                      display: !isSidePanelFullScreen
                        ? activeTab === index
                          ? ""
                          : "none"
                        : "",
                    }}
                  >
                    {isSidePanelFullScreen && (
                      <h2 className="separator">{tab.label}</h2>
                    )}
                    {tab.content}
                  </div>
                ))}
          </div>
        </>
      )}
    </Container>
  );
}
