import React, { useState, useCallback, useEffect } from "react";

import { Container } from "./styles";

export default function EvaluationNumeric({
  defaultValue,
  onChanged,
  children,
  readOnly: _readOnly,
  ...rest
}) {
  const [value, setValue] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setReadOnly(_readOnly);
  }, [_readOnly]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;

      setValue(newValue);

      if (typingTimeout) clearTimeout(typingTimeout);

      setTypingTimeout(
        setTimeout(() => {
          !readOnly && onChanged && onChanged(newValue);
        }, 3000)
      );
    },
    [onChanged, readOnly, typingTimeout]
  );

  const handleBlur = useCallback(
    (e) => {
      const newValue = e.target.value;

      if (typingTimeout) clearTimeout(typingTimeout);

      !readOnly && onChanged && onChanged(newValue);
    },
    [onChanged, readOnly, typingTimeout]
  );

  return (
    <Container
      value={value}
      onChange={(e) => {
        if (e.target.value === value) return;

        handleChange(e);
      }}
      onBlur={(e) => {
        if (e.target.value === value) return;

        handleBlur(e);
      }}
      readOnly={readOnly}
      {...rest}
      type="number"
      pattern="[0-9]+([\.,][0-9]+)?"
      step="0.01"
    >
      {children}
    </Container>
  );
}
