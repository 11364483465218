import Tooltip from "@material-ui/core/Tooltip";
import React, { useMemo } from "react";
import { GrContract, GrExpand, GrFormClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import NoIcon from "../../../assets/images/no-icon.svg";
import Button from "../../Button";
import OfficeIcon from "../../OfficeIcons";
import { TopMenu } from "./styles";
import { useAuth } from "../../../hooks/auth";

export default function SidePanelHeader({
  path,
  listData,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  setOpenAddConversation,
  master,
}) {
  const { user, hasScope } = useAuth();
  const history = useHistory();
  const professional = useMemo(() => {
    return master?.professionals?.find(
      (x) => x.ID === listData?.professionalMentor?.idProfessional
    );
  }, [listData, master]);

  const professionalMentor = useMemo(() => {
    return master?.professionals?.find(
      (x) => x.ID === listData?.professionalMentor?.idMentor
    );
  }, [listData, master]);

  return (
    <TopMenu
      status={
        listData?.professionalMentor?.idMentor === user.userId
          ? listData?.professionalMentor?.wasGoodStatus
          : undefined
      }
    >
      <div>
        <div className="evaluators">
          <div>
            <Tooltip
              title={professionalMentor?.Name ?? "loading"}
              placement="bottom"
            >
              <img
                src={
                  professionalMentor?.Login
                    ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${professionalMentor?.Login}_192_192.png`
                    : NoIcon
                }
                alt={professionalMentor?.Name}
              />
            </Tooltip>
            Current Mentor
          </div>
        </div>
        <div className="status">
          {listData?.professionalMentor?.idMentor === user.userId
            ? listData?.professionalMentor?.wasGoodStatus != undefined
              ? listData?.professionalMentor.wasGoodStatus
                ? "The last conversation was good"
                : "The last conversation was bad"
              : "No Conversation with this mentor yet"
            : ""}
        </div>
        <div className="tools">
          <div onClick={() => setIsSidePanelFullScreen((x) => !x)}>
            {!isSidePanelFullScreen ? (
              <GrExpand size={12} />
            ) : (
              <GrContract size={12} />
            )}
          </div>
          <div
            onClick={() => {
              history.push("/" + path + "/");
              setIsSidePanelFullScreen(false);
              setIsSidePanelOpen(false);
            }}
          >
            <GrFormClose size={20} />
          </div>
        </div>
      </div>
      <div className="profile">
        <div>
          <img
            className="professional"
            src={
              professional?.Login
                ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${professional?.Login}_192_192.png`
                : NoIcon
            }
            alt={professional?.Name}
          />
          <div>
            <div>
              <h2>{professional?.Name}</h2>
              <div className="profile-info">
                <h3>
                  <b>{professional?.JobTitle.Name}</b> |{" "}
                  {professional?.Practices[0]?.Name}
                </h3>

                <OfficeIcon
                  className="office-icon"
                  office={professional?.Offices[0]?.Name.toLowerCase()}
                />
              </div>
            </div>
          </div>
        </div>
        {(hasScope("API.PDS.Admin") ||
          listData?.professionalMentor?.idMentor === user.userId) && (
          <div className="buttons-wrapper">
            <Button primary onClick={() => setOpenAddConversation(true)}>
              Add Conversation
            </Button>
          </div>
        )}
      </div>
    </TopMenu>
  );
}
