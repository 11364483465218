import React, { useEffect, useState } from "react";

import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import api from "../../../services/api";
import { DataGridWrapper } from "../styles";

export default function AdminEvaluationStatus() {
  const [evaluationStatusSource, setevaluationStatusSource] = useState();

  useEffect(() => {
    const evaluationStatusStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/evaluation-status");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/evaluation-status", data);
      },
      update: async (id, data) => {
        await api.put(`/api/evaluation-status/${id}`, {
          id,
          description: data.description,
          isActive: data.isActive,
        });
      },
      //remove: async (id) => {
      //  await api.delete(`/api/area-types/${id}`);
      //}
    });

    setevaluationStatusSource(
      new DataSource({
        store: evaluationStatusStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, []);

  return (
    <DataGridWrapper>
      <DataGrid
        dataSource={evaluationStatusSource}
        showBorders={false}
        key="id"
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="description" />
        <Column dataField="isActive" dataType="boolean" />
      </DataGrid>
    </DataGridWrapper>
  );
}
