import React, { useCallback, useEffect, useState } from "react";
import { Container, GridContainer } from "./styles";
import CustomStore from "devextreme/data/custom_store";
import api from "../../../services/api";
import master from "../../../services/master";
import DataSource from "devextreme/data/data_source";
import { DataGrid } from "devextreme-react";
import {
  Column,
  HeaderFilter,
  Lookup,
  Paging,
  Sorting,
} from "devextreme-react/data-grid";
import { PromotionStatusCell } from "../../EvaluationList/styles";
import PhotoCell from "../../EvaluationList/CellRender/PhotoCell";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { FiExternalLink, FiSearch } from "react-icons/fi";
import Button from "../../Button";
import { useHistory } from "react-router-dom";

export default function PromotionCycle({ promotion, isSidePanelFullScreen }) {
  const history = useHistory();
  const [actionPlansSource, setActionPlansSource] = useState([]);
  const [promotionSource, setPromotionSource] = useState([]);
  const [openActionPlanMacro, setOpenActionPlanMacro] = useState(false);
  const [openActionPlan, setOpenActionPlan] = useState(false);
  const [actionPlanText, setActionPlanText] = useState("");
  const [openResponsibleComments, setOpenResponsibleComments] = useState(false);
  const [responsibleCommentsText, setResponsibleCommentsText] = useState("");
  const [selectedPromotionId, setSelectedPromotionId] = useState(promotion.id);
  const loadPromotions = useCallback(async () => {
    const store = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get(
          `api/promotion?idProfessional=${promotion.idProfessional}`
        );
        return data;
      },
    });

    setPromotionSource(
      new DataSource({
        store: store,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [promotion.idProfessional]);

  const [promotionActionStatuses] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/promotion-action-status");
        return data;
      },
    }),
    paginate: true,
  });

  const [cycles] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/promotioncycle");
        const cyclesWithDisplayName = data.map((cycle) => ({
          ...cycle,
          displayName: `${cycle.period}Q - ${cycle.currentYear}`,
        }));

        return cyclesWithDisplayName;
      },
    }),
    paginate: true,
  });

  const [professionals] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await master.get("master/professionals");
        return data;
      },
    }),
    paginate: true,
  });

  const loadActionPlans = useCallback(async () => {
    const store = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get(
          `api/promotion-actions?idPromotion=${selectedPromotionId}`
        );
        return data;
      },
      insert: async (values) => {
        const { data } = await api.post("api/promotion-actions", {
          ...values,
          idPromotion: selectedPromotionId,
        });
        return data;
      },
      update: async (key, values) => {
        const { data } = await api.put(`api/promotion-actions/${key}`, {
          ...values,
          idPromotion: selectedPromotionId,
        });
      },
    });

    setActionPlansSource(
      new DataSource({
        store: store,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [selectedPromotionId]);

  useEffect(() => {
    loadPromotions();
  }, [loadPromotions]);

  useEffect(() => {
    loadActionPlans();
  }, [loadActionPlans]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <GridContainer>
        <DataGrid
          dataSource={promotionSource}
          height="100%"
          columnAutoWidth
          allowColumnResizing
        >
          <Paging defaultPageSize={10} enabled />
          <HeaderFilter visible allowSearch />
          <Sorting mode="multiple" />
          <Column
            dataField="promotionStatus.id"
            caption="Status"
            cellRender={(e) => (
              <PromotionStatusCell status={e.data.promotionStatus.id}>
                <PhotoCell data={promotion.professional} />
                {e.data.promotionStatus.description}
              </PromotionStatusCell>
            )}
            alignment="left"
          >
            <Lookup
              dataSource={promotionActionStatuses}
              valueExpr="id"
              displayExpr="description"
            />
          </Column>
          <Column
            dataField="promotionCycle.id"
            caption="Cycle"
            cellRender={(e) => {
              return ` ${e.data.promotionCycle.period}Q — ${e.data.promotionCycle.currentYear}`;
            }}
            alignment="center"
          >
            <Lookup
              dataSource={cycles}
              valueExpr="id"
              displayExpr="displayName"
            />
          </Column>
          <Column
            caption="Action Plans"
            alignment="center"
            allowFiltering={false}
            allowSorting={false}
            cellRender={(e) => {
              return (
                <IconButton
                  onClick={() => {
                    setSelectedPromotionId(e.key);
                    setOpenActionPlanMacro(true);
                  }}
                >
                  <FiSearch size={14} />
                </IconButton>
              );
            }}
          />
          <Column
            alignment="center"
            width={50}
            cellRender={(e) => {
              return (
                <IconButton
                  onClick={() => {
                    window.open(`/promotions/${e.key}`, "_blank");
                  }}
                >
                  <FiExternalLink size={14} />
                </IconButton>
              );
            }}
          />
        </DataGrid>
      </GridContainer>
      {openActionPlanMacro && (
        <Dialog
          open={openActionPlanMacro}
          onClose={() => setOpenActionPlanMacro(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Action Plans</DialogTitle>
          <DialogContent>
            <DataGrid
              dataSource={actionPlansSource}
              height="100%"
              columnAutoWidth
              allowColumnResizing
              onRowUpdating={(options) => {
                options.newData = { ...options.oldData, ...options.newData };
              }}
            >
              <Paging defaultPageSize={10} enabled />
              <HeaderFilter visible allowSearch />
              <Sorting mode="multiple" />
              <Column
                dataField="idStatus"
                caption="Status"
                editorType="dxSelectBox"
                cellRender={(e) => (
                  <PromotionStatusCell
                    status={e.data.promotionActionStatus?.id}
                  >
                    <PhotoCell data={promotion.professional} />
                    {e.data.promotionActionStatus?.description}
                  </PromotionStatusCell>
                )}
                alignment="left"
              >
                <Lookup
                  dataSource={promotionActionStatuses}
                  valueExpr="id"
                  displayExpr="description"
                />
              </Column>
              <Column
                dataField="isGroupResponsible"
                dataType="boolean"
                alignment="center"
              />
              <Column
                dataField="idActionResponsible"
                allowSearch
                caption="Responsible"
                alignment="center"
                editorType="dxSelectBox"
                cellRender={(e) => <PhotoCell data={e.data.professional} />}
              >
                <Lookup
                  dataSource={professionals}
                  valueExpr="ID"
                  displayExpr="Name"
                />
              </Column>
              <Column
                dataField="actionText"
                caption="Action Plan"
                editorType="dxTextArea"
                alignment="center"
                allowFiltering={false}
                allowSorting={false}
                cellRender={(e) => {
                  return (
                    <IconButton
                      onClick={() => {
                        setActionPlanText(e.data.actionText);
                        setOpenActionPlan(true);
                      }}
                    >
                      <FiSearch size={14} />
                    </IconButton>
                  );
                }}
              />
              <Column
                dataField="responsibleComments"
                caption="Responsible Comments"
                editorType="dxTextArea"
                alignment="center"
                allowFiltering={false}
                allowSorting={false}
                cellRender={(e) => {
                  return (
                    <IconButton
                      onClick={() => {
                        setResponsibleCommentsText(e.data.responsibleComments);
                        setOpenResponsibleComments(true);
                      }}
                    >
                      <FiSearch size={14} />
                    </IconButton>
                  );
                }}
              />
            </DataGrid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenActionPlanMacro(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {openActionPlan && (
        <Dialog
          open={openActionPlan}
          onClose={() => setOpenActionPlan(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Action Plan</DialogTitle>
          <DialogContent>
            <strong style={{ fontWeight: 500 }}>
              {actionPlanText || "No action plans"}
            </strong>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenActionPlan(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {openResponsibleComments && (
        <Dialog
          open={openResponsibleComments}
          onClose={() => setOpenResponsibleComments(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Responsible Comments</DialogTitle>
          <DialogContent>
            <strong style={{ fontWeight: 500 }}>
              {responsibleCommentsText || "No comments"}
            </strong>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenResponsibleComments(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}
