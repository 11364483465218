import styled, { css } from "styled-components";

export const Container = styled.div`
  font-weight: lighter;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;

  ${({ isSidePanelFullScreen }) =>
    isSidePanelFullScreen &&
    css`
      flex-direction: row;
      justify-content: space-between;
    `}

  .int-text-area {
    flex: 1;
  }

  .selectbox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }

  h2 {
    margin-right: 10px;
  }

  h3 {
    font-size: 0.75rem;
    margin-bottom: 1em;
  }

  h3.conclusion {
    font-size: 0.875rem;
    margin-bottom: 1em;
    font-weight: 400;
  }

  strong {
    font-weight: bold;
  }

  .key-messages {
    display: flex;
    justify-content: center;
    flex: 1;
    gap: 20px;
    flex-wrap: wrap;

    .key-message {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 7px;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      padding: 10px 15px;
      flex: 48%;
    }

    strong {
      font-size: 1rem;
    }

    h3 {
      font-size: 0.875rem;
      color: #ccc;
      margin: 0;
    }
  }

  .grade {
    display: flex;
    gap: 20px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border-color: #f5f5f5;
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }

  .pillars {
    flex: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .overview-grade {
    background-color: white;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    line-height: 1;
    padding: 15px;
    flex: 200px;

    .div_tooltip {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }
    .div_tooltip_overview {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }

    &.grouped {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      flex: 1 0 100%;
    }

    .int-select-box {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
    }

    label {
      font-size: 0.75rem;
      margin: 0;
      flex: 1 0 70%;
      font-weight: bold;
      width: min-content;
    }

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: bold;
      color: #8b0304;
    }

    .header .grade {
      box-shadow: 0 0 30px rgba(0 0 0 / 10%);
      padding: 10px 15px;
    }

    .header .grade h2 {
      font-size: 1.5rem;
    }

    .competences {
      width: 100%;
      gap: 20px;
      display: flex;
      flex-direction: column;
    }

    .competence {
      width: 100%;
      gap: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-weight: 400;
    }

    .competence label {
      margin: 0;
      flex: 1 0 80%;
    }
  }
`;
