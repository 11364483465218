import React, { useState, useEffect, useMemo, useContext } from "react";
import { Container } from "./styles";
import SidePanelLoader from "../ContentLoader";
import SidePanelHeader from "./Header";
import { useParams, useHistory } from "react-router-dom";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import DialogAddTalk from "./DialogAddTalk";
import Talks from "./Tabs/Talks";
import PastTutors from "./Tabs/PastTutors";
import { ValueContext } from "../../context/ValueProvider";
import { TriggerContext } from "../../context/TriggerProvider";
import { useAuth } from "../../hooks/auth";
import pdsGroups from "../../config/pdsGroups";

export default function SidePanelTutorship({
  isSidePanelFullScreen,
  setIsSidePanelOpen,
  setIsSidePanelFullScreen,
  listData,
  path,
}) {
  const { user, hasScope } = useAuth();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);
  const { id } = useParams();
  const history = useHistory();
  const { master } = useContext(ValueContext);
  const { trigger } = useContext(TriggerContext);

  const [talks, setTalks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAddTalk, setOpenAddTalk] = useState(false);
  const { addToast } = useToast();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setIsSidePanelOpen(true);
    const loadTalks = async () => {
      try {
        setIsLoading(true);
        if (listData?.professionalTutor) {
          const [talks] = await Promise.all([
            api.get(`api/tutorship/${listData?.professionalTutor?.id}/talks`),
          ]);
          setTalks(talks.data);
        }
        setIsLoading(false);
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong on loading data",
        });
        history.push("/" + path + "/");
        setIsSidePanelFullScreen(false);
        setIsSidePanelOpen(false);
        throw err;
      } finally {
        setIsLoading(false);
      }
    };
    loadTalks();
  }, [
    addToast,
    history,
    id,
    listData,
    path,
    setIsSidePanelFullScreen,
    setIsSidePanelOpen,
    trigger,
  ]);

  const tabs = useMemo(
    () => [
      {
        visible: true,
        label: "Talks",
        content: (
          <Talks
            talks={talks}
            isSidePanelFullScreen={isSidePanelFullScreen}
            isAdmin={isAdmin}
          />
        ),
      },
      {
        visible: true,
        label: "Past Tutors",
        content: (
          <PastTutors
            listData={listData}
            isSidePanelFullScreen={isSidePanelFullScreen}
            isAdmin={isAdmin}
          />
        ),
      },
    ],
    [talks, isSidePanelFullScreen, isAdmin, listData]
  );

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {isLoading || !master.professionals ? (
        <SidePanelLoader width="100%" />
      ) : (
        <>
          <SidePanelHeader
            setOpenAddTalk={setOpenAddTalk}
            isSidePanelFullScreen={isSidePanelFullScreen}
            setIsSidePanelFullScreen={setIsSidePanelFullScreen}
            setIsSidePanelOpen={setIsSidePanelOpen}
            path={path}
            listData={listData}
            userId={user.userId}
            isAdmin={isAdmin}
          />
          {!isSidePanelFullScreen && (
            <div className="tabs-menu">
              {tabs
                .filter((x) => x.visible)
                .map((tab, index) => (
                  <div
                    key={index}
                    className={activeTab === index ? "active" : ""}
                    onClick={() => {
                      setActiveTab(index);
                    }}
                  >
                    {tab.label}
                  </div>
                ))}
            </div>
          )}
          <div
            className={`content-wrapper ${
              !isSidePanelFullScreen ? "no-scroll" : ""
            }`}
          >
            {tabs.map((tab, index) => (
              <div
                key={index}
                className="content"
                style={{
                  display: !isSidePanelFullScreen
                    ? activeTab === index
                      ? ""
                      : "none"
                    : "",
                }}
              >
                {isSidePanelFullScreen && (
                  <h2 className="separator">{tab.label}</h2>
                )}
                {tab.content}
              </div>
            ))}
          </div>
        </>
      )}
      <DialogAddTalk
        open={openAddTalk}
        setOpenAddTalk={setOpenAddTalk}
        id={listData?.professionalTutor?.id}
        tutorship={listData?.professionalTutor}
      />
    </Container>
  );
}
