import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { SelectBox, TextArea } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "../../../../components/Button";
import { TriggerContext } from "../../../../context/TriggerProvider";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import { Fieldset, Label } from "./styles";

export default function DialogEditDoDont({
  dodontClassificationId,
  isDo,
  dodontId,
  open,
  handleClose,
}) {
  const classificationSource = useMemo(() => {
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/classification");
        return data.map(({ icon, ...rest }) => ({
          ...rest,
        }));
      },
      paginate: true,
    });
  }, []);

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [classification, setClassification] = useState(null);
  const [subject, setSubject] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const { triggerFunction } = useContext(TriggerContext);

  const load = useCallback(async () => {
    const { data } = await api.get(
      `/api/dodontclassification/${dodontClassificationId}`
    );

    setClassification(data.idClassification);
    setSubject(data.subject);
    setCreatedAt(data.createdAt);
  }, [dodontClassificationId]);

  useEffect(() => {
    load();
  }, [load]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (classification && subject) {
        setLoading(true);

        try {
          await api.put(`/api/dodontclassification/${dodontClassificationId}`, {
            idDoDont: dodontId,
            idClassification: classification,
            subject,
            isDo,
            createdAt,
          });

          triggerFunction();

          addToast({
            type: "success",
            title: "Saved",
          });
          handleClose();
        } catch (err) {
          addToast({
            type: "error",
            title: "Ops",
            description: "Something went wrong",
          });
        }
      } else {
        addToast({
          type: "error",
          title: "Ops",
          description: "Check if you filled all required fields",
        });
      }

      setLoading(false);
    },
    [
      classification,
      subject,
      dodontClassificationId,
      dodontId,
      isDo,
      createdAt,
      addToast,
      handleClose,
    ]
  );

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Edit {isDo ? "Do" : "Don't"}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Fieldset>
            <Label>Classification</Label>
            <SelectBox
              dataSource={classificationSource}
              displayExpr="description"
              valueExpr="id"
              placeholder="Select classification"
              value={classification}
              onValueChanged={(e) => setClassification(e.value)}
            />
          </Fieldset>
          <Fieldset>
            <Label>{isDo ? "Do" : "Don't"}</Label>
            <TextArea
              value={subject}
              placeholder="Start typing here..."
              required={true}
              onValueChanged={(e) => setSubject(e.value)}
              height={300}
            />
          </Fieldset>
        </DialogContent>

        <DialogActions>
          <Button
            primary
            type="submit"
            disabled={loading}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 5,
            }}
          >
            {loading && <CircularProgress size="1em" />}
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
