import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 600px;

  .content {
    margin-bottom: 40px;
  }

  .tabs-menu {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 5px;
    padding: 20px;
    justify-content: center;
    color: #666;

    & > div {
      text-transform: initial;
      font-size: 0.75rem;
      font-weight: bold;
      letter-spacing: initial;
      min-width: fit-content;
      min-height: initial;
      background-color: #eee;
      border-radius: 50px;
      padding: 0.1em 1em;
      cursor: pointer;

      &.active {
        background-color: #8b0304;
        color: white;
      }
    }
  }

  .content-wrapper {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    ${({ isSidePanelFullScreen }) =>
      isSidePanelFullScreen &&
      css`
        padding-top: 60px;
      `}

    h2.separator {
      font-size: 1rem;
      font-weight: bold;
      color: #ccc;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;

      :before,
      ::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #eee;
      }

      :not(:empty)::before {
        margin-right: 1em;
      }

      :not(:empty)::after {
        margin-left: 1em;
      }

      .separator {
      }
    }
  }
`;
