import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./styles";
import api from "../../../services/api";
import {
  Chart,
  CommonSeriesSettings,
  Title,
  Font,
  Label,
  Format,
  SeriesTemplate,
  ValueAxis,
  Strip,
  StripStyle,
  Legend,
  Grid,
  Tooltip as ChartTooltip,
  ArgumentAxis,
} from "devextreme-react/chart";

import {
  PolarChart,
  CommonSeriesSettings as PolarCommonSeriesSettings,
  Series,
  Title as PolarTitle,
  Font as PolarFont,
  ValueAxis as PolarValueAxis,
  Label as PolarLabel,
  ArgumentAxis as PolarArgumentAxis,
} from "devextreme-react/polar-chart";
import { useMemo } from "react";
import ContentLoader from "react-content-loader";

export default function Charts({ promotion, isSidePanelFullScreen }) {
  const [loadingChart, setLoadingChart] = useState(true);
  const [last2YearsChart, setLast2YearsChart] = useState([]);
  const [radarCompetencesChart, setRadarCompetencesChart] = useState([]);
  const [evaluationCycleChart, setEvaluationCycleChart] = useState([]);
  const [radarPillarsChart, setRadarPillarsChart] = useState([]);
  const [pillarsChart, setPillarsChart] = useState([]);
  const [pillarJobTitleAverageChart, setPillarJobTitleAverageChart] = useState(
    []
  );
  const [totalAverageChart, setTotalAverageChart] = useState([]);

  const startValueByAreaType = useMemo(() => {
    if (promotion.promotionCycle && promotion.promotionCycle.idAreaType === 1) {
      return [0, 1, 1.2, 2];
    } else if (
      promotion.promotionCycle &&
      promotion.promotionCycle.idAreaType === 2
    ) {
      return [0, 0.9, 1.2, 1.65];
    } else {
      return [0, 0.9, 1.2, 1.65];
    }
  }, [promotion]);
  const endValueByAreaType = useMemo(() => {
    if (promotion.promotionCycle && promotion.promotionCycle.idAreaType === 1) {
      return [1, 1.2, 2, 3];
    } else if (
      promotion.promotionCycle &&
      promotion.promotionCycle.idAreaType === 2
    ) {
      return [0.9, 1.2, 1.65, 3];
    } else {
      return [0.9, 1.2, 1.65, 3];
    }
  }, [promotion]);

  const gradesLevels = useMemo(
    () => [
      {
        name: "Below Level",
        color: "rgba(244, 67, 54, .5)",
        start: startValueByAreaType[0],
        end: endValueByAreaType[0],
      },
      {
        name: "At Level",
        color: "rgba(255, 235, 59, .5)",
        start: startValueByAreaType[1],
        end: endValueByAreaType[1],
      },
      {
        name: "Above Level",
        color: "rgba(33, 150, 243, .5)",
        start: startValueByAreaType[2],
        end: endValueByAreaType[2],
      },
      {
        name: "Outstanding",
        color: "rgba(76, 175, 80, .5)",
        start: startValueByAreaType[3],
        end: endValueByAreaType[3],
      },
    ],
    [startValueByAreaType, endValueByAreaType]
  );

  const loadCharts = useCallback(async () => {
    setLoadingChart(true);
    const responseCharts = await api.get(
      `api/promotions/${promotion.id}/charts`
    );

    const charts = responseCharts.data;
    setLast2YearsChart(charts.averageGradeLast2Years.reverse());
    setRadarCompetencesChart(charts.competenceRadar);
    setEvaluationCycleChart(charts.evaluationsPerCycle);
    setRadarPillarsChart(charts.pillarRadar);
    setPillarsChart(charts.pillarAverage);
    setPillarJobTitleAverageChart(charts.pillarJobTitleAverage);
    setTotalAverageChart([
      {
        arg: "Total Average",
        professional: charts.professionalAverage,
        jobtitle: charts.jobTitleAverage,
      },
    ]);
    setLoadingChart(false);
  }, [promotion.id]);

  useEffect(() => {
    loadCharts();
  }, [isSidePanelFullScreen, loadCharts]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <div className="charts">
        {loadingChart ? (
          <div className="loading-charts">
            <ContentLoader width="100%" viewBox="0 0 894 760">
              <path d="M 0 509 h 150 v 251 H 0 z M 186 424 h 150 v 336 H 186 z M 372 331 h 150 v 429 H 372 z M 558 0 h 150 v 760 H 558 z M 744 424 h 150 v 336 H 744 z" />
            </ContentLoader>
            {!isSidePanelFullScreen && (
              <strong>
                For a better experience viewing the charts, please expand the
                screen through the button above.
              </strong>
            )}
          </div>
        ) : (
          <>
            {!isSidePanelFullScreen && (
              <strong>
                For a better experience viewing the charts, please expand the
                screen through the button above.
              </strong>
            )}

            <Chart dataSource={last2YearsChart} className="chart">
              <ArgumentAxis>
                <Label overlappingBehavior="rotate" />
              </ArgumentAxis>
              <CommonSeriesSettings
                argumentField="project"
                valueField="totalAverage"
                type="bar"
                ignoreEmptyPoints
              >
                <Label visible customizeText={(arg) => `${arg.valueText}`}>
                  <Format type="fixedPoint" precision={2} />
                </Label>
              </CommonSeriesSettings>
              <SeriesTemplate nameField="project" />
              <Title text="Last 2 years performance per project">
                <Font size={16} />
              </Title>
              <ValueAxis visualRange={[0, 3]} />
              <Legend visible={false} />
            </Chart>

            <PolarChart
              dataSource={radarCompetencesChart}
              useSpiderWeb
              className="chart"
            >
              <PolarCommonSeriesSettings type="line" />
              {evaluationCycleChart.map((item) => (
                <Series
                  key={`${item.value}`}
                  valueField={`${item.value}`}
                  name={item.name}
                />
              ))}
              <Legend verticalAlignment="bottom" horizontalAlignment="center" />
              <PolarTitle text="Competence Matrix">
                <PolarFont size={16} />
              </PolarTitle>
              <PolarArgumentAxis>
                <PolarLabel
                  customizeText={({ valueText }) =>
                    valueText.replace(/\n/g, " ")
                  }
                />
              </PolarArgumentAxis>
              <PolarValueAxis visualRange={[0, 3]} />
            </PolarChart>

            <PolarChart
              dataSource={radarPillarsChart}
              useSpiderWeb
              className="chart"
            >
              <PolarCommonSeriesSettings type="line" />
              {evaluationCycleChart.map((item) => (
                <Series
                  key={`${item.value}`}
                  valueField={`${item.value}`}
                  name={item.name}
                />
              ))}
              <Legend verticalAlignment="bottom" horizontalAlignment="center" />
              <PolarTitle text="Pillar Matrix">
                <PolarFont size={16} />
              </PolarTitle>
              <PolarValueAxis visualRange={[0, 3]} />
            </PolarChart>

            <Chart dataSource={pillarsChart} className="chart">
              <Series
                argumentField="pillar"
                valueField="value"
                type="spline"
                color="#a3aaaa"
              />
              <ValueAxis visualRange={[0, 2.5]}>
                <Grid visible={false} />
                <Label visible />
                {gradesLevels.map((level) => (
                  <Strip
                    startValue={level.start}
                    key={level.name}
                    endValue={level.end}
                    color={level.color}
                  >
                    <Label text={level.name}>
                      <Font color="#333" />
                    </Label>
                  </Strip>
                ))}
                <StripStyle>
                  <Label>
                    <Font weight="500" size="14" />
                  </Label>
                </StripStyle>
              </ValueAxis>
              <Legend visible={false} />
              <Title text="Pillar performance average (general with all projects in the cycle)">
                <Font size={16} />
              </Title>
              <ChartTooltip enabled />
            </Chart>

            <Chart dataSource={pillarJobTitleAverageChart} className="chart">
              <CommonSeriesSettings
                argumentField="pillar"
                type="bar"
                selectionMode="allArgumentPoints"
              >
                <Label visible>
                  <Format type="fixedPoint" precision={2} />
                </Label>
              </CommonSeriesSettings>
              <Series
                argumentField="pillar"
                valueField="professional"
                name="Professional average"
              />
              <Series valueField="jobtitle" name="JobTitle average" />
              <Legend verticalAlignment="bottom" horizontalAlignment="center" />
              <ValueAxis visualRange={[0, 3]} />
              <Title text="Pillars average by professional position and practice">
                <Font size={16} />
              </Title>
            </Chart>

            <Chart dataSource={totalAverageChart} className="chart">
              <CommonSeriesSettings
                argumentField="arg"
                type="bar"
                selectionMode="allArgumentPoints"
              >
                <Label visible>
                  <Format type="fixedPoint" precision={2} />
                </Label>
              </CommonSeriesSettings>
              <Series
                argumentField="arg"
                valueField="professional"
                name="Professional average"
              />
              <Series valueField="jobtitle" name="JobTitle average" />
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
              ></Legend>
              <ValueAxis visualRange={[0, 3]} />
              <Title text="Grade average by professional position and practice">
                <Font size={16} />
              </Title>
            </Chart>
          </>
        )}
      </div>
    </Container>
  );
}
