import React, { useState, useContext, useMemo } from "react";
import PhotoCell from "../../EvaluationList/CellRender/PhotoCell";
import { ValueContext } from "../../../context/ValueProvider";
import { Container } from "./styles";
import TextArea from "../../TextArea";

export default function TimelineItem({
  comment,
  professionalId,
  handleConversationEdit,
  reverse,
  visible,
}) {
  const { master } = useContext(ValueContext);
  const [professional, setProfessional] = useState();

  const photo = useMemo(() => {
    const commentProfessional = master.professionals?.find(
      (x) => x.ID === professionalId
    );
    setProfessional(commentProfessional);

    return <PhotoCell data={commentProfessional} />;
  }, [comment, master]);

  return (
    <>
      {visible && (
        <Container reverse={reverse}>
          <TextArea
            caption={professional?.Name}
            value={comment}
            onChanged={(value) => handleConversationEdit(value)}
            // disabled
          />
          <div className="timeline-item-commands">
            <div className="photo">{photo}</div>
          </div>
        </Container>
      )}
    </>
  );
}
