import React, { useEffect, useState, useCallback } from "react";

import DataGrid, {
  Column,
  Editing,
  MasterDetail,
  RowDragging,
  Lookup,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import api from "../../../services/api";
import { DataGridWrapper } from "../styles";

export default function AdminCounselingFieldTypes() {
  const [actionFieldTypeSource, setActionFieldTypeSource] = useState();
  const [actionFieldTypeStore, setActionFieldTypeStore] = useState();

  useEffect(() => {
    const actionFieldTypesStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/action-field-types");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/action-field-types", data);
      },
      update: async (id, data) => {
        await api.put(`/api/action-field-types/${id}`, data);
      },
    });

    setActionFieldTypeStore(actionFieldTypesStore);

    setActionFieldTypeSource(
      new DataSource({
        store: actionFieldTypesStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, []);

  const handleDrag = useCallback(
    async (e) => {
      await actionFieldTypeStore.update(e.itemData.id, {
        order: e.toIndex + 1,
      });
      await e.component.refresh();
    },
    [actionFieldTypeStore]
  );

  return (
    <DataGridWrapper>
      <DataGrid
        height={550}
        dataSource={actionFieldTypeSource}
        showBorders={false}
        key="id"
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <Editing mode="row" allowAdding allowUpdating />
        <RowDragging
          allowReordering
          onReorder={handleDrag}
          showDragIcons
          dropFeedbackMode="push"
        />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="description" />
        <Column dataField="isCounselorField" dataType="boolean" />
        <MasterDetail
          enabled
          component={(data) => <ActionFieldMasterDetail data={data.data} />}
        />
      </DataGrid>
    </DataGridWrapper>
  );
}

export const ActionFieldMasterDetail = ({ data }) => {
  const [actionFieldTypeId] = useState(data.data.id);
  const [actionFieldSource, setActionFieldSource] = useState();
  const [actionFieldStore, setActionFieldStore] = useState();
  const [actionFieldTypes, setActionFieldTypes] = useState();

  useEffect(() => {
    const actionFieldStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/action-fields", {
          params: {
            idActionFieldType: actionFieldTypeId,
          },
        });
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/action-fields", data);
      },
      update: async (id, data) => {
        await api.put(`/api/action-fields/${id}`, data);
      },
    });

    setActionFieldStore(actionFieldStore);

    setActionFieldSource(
      new DataSource({
        store: actionFieldStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );

    setActionFieldTypes({
      store: new CustomStore({
        key: "ID",
        loadMode: "raw",
        load: async () => {
          const response = await api.get("/api/action-field-types");
          return response.data;
        },
      }),
      paginate: true,
    });
  }, [actionFieldTypeId]);

  const handleDrag = useCallback(
    async (e) => {
      await actionFieldStore.update(e.itemData.id, {
        order: e.toIndex + 1,
      });
      await e.component.refresh();
    },
    [actionFieldStore]
  );

  return (
    <DataGridWrapper>
      <DataGrid
        dataSource={actionFieldSource}
        showBorders={false}
        key="id"
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <RowDragging
          allowReordering
          onReorder={handleDrag}
          showDragIcons
          dropFeedbackMode="push"
        />
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="description" width={200} />
        <Column dataField="isActive" dataType="boolean" width={80} />
        <Column dataField="isCheckbox" dataType="boolean" width={90} />
        <Column dataField="isText" dataType="boolean" width={80} />
        <Column dataField="isList" dataType="boolean" width={80} />
        <Column dataField="order" visible={false} />
        <Column dataField="listCount" width={80} alignment="center">
          <Lookup dataSource={["1", "2"]} />
        </Column>
        <Column dataField="listPlaceholder" alignment="center" />
        <Column dataField="listType" alignment="center">
          <Lookup dataSource={["PRACTICES", "OFFICES", "GOVERNANCE"]} />
        </Column>
        <Column dataField="idActionFieldType" caption="Action Field Type">
          <Lookup
            dataSource={actionFieldTypes}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>
        <Column dataField="tooltip" />
      </DataGrid>
    </DataGridWrapper>
  );
};
