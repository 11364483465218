import React, { useState, useEffect } from "react";
import CheckBox from "devextreme-react/check-box";

export default function GroupTemplate({
  isProjectConfirmed,
  checked: _checked,
  onValueChanged,
  groupText,
}) {
  const [checked, setChecked] = useState(_checked);

  useEffect(() => {
    setChecked(_checked);
  }, [_checked]);

  return (
    <React.Fragment>
      <CheckBox
        value={checked}
        onValueChanged={onValueChanged}
        visible="true"
      />
      <span style={{ marginLeft: "5px" }}> {groupText} </span>
    </React.Fragment>
  );
}
