import { useCallback, useContext, useMemo, useState } from "react";
import api from "../services/api";
import { useToast } from "./toast";
import { TriggerContext } from "../context/TriggerProvider";

export default function useUpdateField() {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { triggersFunction } = useContext(TriggerContext);

  const apiMethods = useMemo(
    () => ({
      patch: api.patch,
      put: api.put,
      post: api.post,
      delete: api.delete,
    }),
    []
  );

  const updateField = useCallback(
    async (method, endpoint, payload, triggerName) => {
      method = method.toLowerCase();
      setIsLoading(true);
      try {
        const apiMethod = apiMethods[method];
        if (!apiMethod) throw new Error(`Invalid HTTP method: ${method}`);
        await apiMethod(endpoint, payload);
        if (triggerName) triggersFunction(triggerName);
        addToast({
          type: "success",
          title: "Data updated successfully.",
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Data update failed.",
        });
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [addToast, apiMethods, triggersFunction]
  );

  return { updateField, isLoading };
}
