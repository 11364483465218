import React, { useCallback, useEffect, useState } from "react";

import { Container } from "./styles";

import api from "../../../../services/api";

import TabPanel from "../../../../components/TabPanel";

import PillarGrid from "./PillarGrid";
import { SelectBox } from "devextreme-react";

export default function Structure() {
  const [areaTypes, setAreaTypes] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const loadAreaTypes = useCallback(async () => {
    const response = await api.get("/api/area-types");

    setAreaTypes(response.data);
  }, []);

  useEffect(() => {
    loadAreaTypes();
  }, [loadAreaTypes]);

  return (
    <Container>
      <div className="selectDiv">
        <SelectBox
          defaultValue={selectedIndex}
          dataSource={areaTypes}
          displayExpr="description"
          valueExpr="id"
          onValueChanged={(e) => setSelectedIndex(e.value)}
        />
      </div>
      {areaTypes.map((areaType) => (
        <TabPanel value={selectedIndex} key={areaType.id} index={areaType.id}>
          <PillarGrid
            key={`area-type-${areaType.id}`}
            idAreaType={areaType.id}
            isWeighted={areaType.isWeightedAverage}
          />
        </TabPanel>
      ))}
    </Container>
  );
}
