import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 600px;

  .content {
    margin-bottom: 40px;
  }

  .conversation {
    display: flex;
    flex-direction: ${(props) =>
      props.isSidePanelFullScreen ? "row" : "column"};
    gap: 40px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 40px 20px 20px 20px;
    border-radius: 7px;
    position: relative;
    margin-top: 20px;
  }

  .conversation-date {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-weight: bold;
    color: #333;
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    padding: 8px 15px;
    background: white;
  }

  .content-wrapper {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    padding-top: 60px;

    h2.separator {
      font-size: 1rem;
      font-weight: bold;
      color: #ccc;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;

      :before,
      ::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #eee;
      }

      :not(:empty)::before {
        margin-right: 1em;
      }

      :not(:empty)::after {
        margin-left: 1em;
      }

      .separator {
      }
    }
  }
`;
