import styled, { css } from "styled-components";

export const Container = styled.div`
  font-weight: lighter;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;

  .action-field {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 20px;
    flex: 1 0 31%;
  }

  .default-answers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    gap: 20px;
    padding: 0 20px;
    text-wrap: nowrap;

    button {
      height: 100%;
    }

    & > div {
      width: 100%;
    }
  }

  h2 {
    font-weight: bold;
    color: #333;
  }
  .list {
    display: flex;
    gap: 20px;
    align-items: center;
    font-weight: bold;
    color: #666;

    & > div {
      flex: 1;
    }

    label {
      font-weight: bold;
      color: #666;
    }
  }

  ${({ isSidePanelFullScreen }) =>
    isSidePanelFullScreen &&
    css`
      flex-direction: row;
      justify-content: space-between;
    `}

  .int-text-area {
    flex: 1;
  }

  h3 {
    font-size: 0.75rem;
    margin-bottom: 1em;
  }

  strong {
    font-weight: bold;
  }

  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border-color: #f5f5f5;
  }
`;
