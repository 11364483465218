import React, { useEffect, useContext, useState } from "react";
import { GrExpand, GrContract, GrFormClose } from "react-icons/gr";
import NoIcon from "../../../assets/images/no-icon.svg";
import { TopMenu } from "./styles";
import OfficeIcon from "../../OfficeIcons";
import { useHistory } from "react-router-dom";
import RecommendationIcon from "../../../assets/images/recommendation-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { TriggerContext } from "../../../context/TriggerProvider";
import { Photo } from "../../EvaluationList/styles";

export default function PromotionSidePanelHeader({
  promotion,
  isSidePanelOpen,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  path,
}) {
  const history = useHistory();

  return (
    <>
      <TopMenu status={promotion.idStatus}>
        <div>
          <div className="evaluators">
            <div>
              <Tooltip
                title={promotion.mentor?.name ?? "N/A"}
                placement="bottom"
              >
                <img
                  src={
                    promotion?.mentor?.login
                      ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${promotion?.mentor?.login}_192_192.png`
                      : NoIcon
                  }
                  alt={promotion?.mentor?.name ?? "N/A"}
                />
              </Tooltip>
              Mentor
            </div>
          </div>
          <div className="status">{promotion.promotionStatus?.description}</div>
          <div className="tools">
            <div onClick={() => setIsSidePanelFullScreen((x) => !x)}>
              {!isSidePanelFullScreen ? (
                <GrExpand size={12} />
              ) : (
                <GrContract size={12} />
              )}
            </div>
            <div
              onClick={() => {
                history.push("/" + path + "/");
                setIsSidePanelFullScreen(false);
                setIsSidePanelOpen(false);
              }}
            >
              <GrFormClose size={20} />
            </div>
          </div>
        </div>
        <div className="profile">
          <div>
            <img
              className="professional"
              src={
                promotion.professional?.login
                  ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${promotion.professional?.login}_192_192.png`
                  : NoIcon
              }
              alt={promotion.professional?.name}
            />
            <div>
              <div>
                <h2>{promotion.professional?.name}</h2>
                <div className="profile-info">
                  <h3>
                    <b>{promotion.professional?.jobTitle?.name}</b> |{" "}
                    {promotion.practice?.name}
                  </h3>

                  <OfficeIcon
                    className="office-icon"
                    office={promotion.office?.name}
                  />
                </div>
              </div>
            </div>
          </div>
          {promotion.isRecommended && (
            <Tooltip title="Recommended professional">
              <Photo src={RecommendationIcon} />
            </Tooltip>
          )}
        </div>
      </TopMenu>
    </>
  );
}
