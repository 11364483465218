import React, { useCallback, useEffect, useState, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SelectBox, TextArea } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import Button from "../../../../components/Button";
import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";
import { AddDoDontFix, Fieldset, Label } from "./styles";
import { CircularProgress } from "@material-ui/core";
import { TriggerContext } from "../../../../context/TriggerProvider";

export default function DialogAddDoDont({
  isDo,
  dodontId,
  open,
  handleClose,
  onSubmitted,
}) {
  const [classifications, setClassifications] = useState([]);
  const [classificationSource] = useState({
    store: new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/classification");

        setClassifications(data);
        return data
          .filter((x) => x.isActive)
          .map(({ icon, ...rest }) => ({
            ...rest,
          }));
      },
    }),
    paginate: true,
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [classification, setClassification] = useState(null);
  const [tooltip, setTooltip] = useState("");
  const [subject, setSubject] = useState(null);
  const { triggerFunction } = useContext(TriggerContext);

  useEffect(() => {
    const selected = classifications.find((x) => x.id === classification);

    setTooltip(
      (selected && (isDo ? selected.tooltipDo : selected.tooltipDont)) || ""
    );
  }, [classification, classifications, isDo]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (classification && subject) {
        setLoading(true);

        try {
          await api.post("/api/dodontclassification", {
            idDoDont: dodontId,
            idClassification: classification,
            subject,
            isDo,
          });

          triggerFunction();

          addToast({
            type: "success",
            title: "Created",
          });
          handleClose();
        } catch (err) {
          addToast({
            type: "error",
            title: "Ops",
            description: "Something went wrong",
          });
        }
      } else {
        addToast({
          type: "error",
          title: "Ops",
          description: "Check if you filled all required fields",
        });
      }

      setLoading(false);
    },
    [
      classification,
      subject,
      dodontId,
      isDo,
      onSubmitted,
      addToast,
      handleClose,
    ]
  );

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Add {isDo ? "Do" : "Don't"}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Fieldset>
            <Label>Classification</Label>
            <SelectBox
              dataSource={classificationSource}
              displayExpr="description"
              valueExpr="id"
              placeholder="Select classification"
              value={classification}
              onValueChanged={(e) => setClassification(e.value)}
            />
          </Fieldset>
          {tooltip && (
            <Alert severity="info" style={{ marginTop: -15, marginBottom: 15 }}>
              <p>{tooltip || ""}</p>
            </Alert>
          )}
          <Fieldset>
            <Label>{isDo ? "Do" : "Don't"}</Label>
            <AddDoDontFix>
              <TextArea
                value={subject}
                placeholder="Start typing here..."
                required={true}
                onValueChanged={(e) => setSubject(e.value)}
                height={300}
              />
            </AddDoDontFix>
          </Fieldset>
        </DialogContent>

        <DialogActions>
          <Button
            primary
            type="submit"
            disabled={loading}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 5,
            }}
          >
            {loading && <CircularProgress size="1em" />} Add
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
