import React, { useState, useCallback, useEffect } from "react";

import CustomStore from "devextreme/data/custom_store";

import api from "../../../services/api";
import { DataGrid } from "devextreme-react";
import {
  Column,
  HeaderFilter,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";
import { DataGridWrapper } from "../styles";

export default function AdminPromotionCycle() {
  const [promotionCycleSource, setPromotionCycleSource] = useState([]);

  const [areaTypeSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("api/area-types");
        return response.data;
      },
    }),
    paginate: true,
  });

  const loadpromotionCycles = useCallback(async () => {
    const promotionStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/promotioncycle");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/promotioncycle", data);
      },
      update: async (id, data) => {
        await api.put(`/api/promotioncycle/${id}`, {
          id,
          idAreaType: data.idAreaType,
          period: data.period,
          currentYear: data.currentYear,
          isActive: data.isActive,
          actionPlanDeadline: data.actionPlanDeadline,
        });
      },
    });

    setPromotionCycleSource(promotionStore);
  }, []);

  useEffect(() => {
    loadpromotionCycles();
  }, [loadpromotionCycles]);

  return (
    <DataGridWrapper>
      <DataGrid
        height={550}
        dataSource={promotionCycleSource}
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <HeaderFilter visible={true} allowSearch={true} />
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="idAreaType" caption="Area Type" alignment="center">
          <Lookup
            dataSource={areaTypeSource}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="period"
          defaultSortIndex={1}
          defaultSortOrder="asc"
          caption="Period"
          alignment="center"
        />
        <Column
          dataField="currentYear"
          defaultSortIndex={0}
          defaultSortOrder="desc"
          caption="Year"
          alignment="center"
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption="Is Active"
          alignment="center"
          allowFiltering={false}
        />
        <Column dataField="actionPlanDeadline" dataType="date" />
      </DataGrid>
    </DataGridWrapper>
  );
}
