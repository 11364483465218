import { CircularProgress } from "@material-ui/core";
import { Button } from "devextreme-react/button";
import { Form, Item } from "devextreme-react/form";
import { ScrollView } from "devextreme-react/scroll-view";
import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { TriggerContext } from "../../../context/TriggerProvider";
import { StyledPopup } from "../styles";

export default function CustomEditInsertPopup({
  evaluationSource,
  popupVisible,
  setPopupVisible,
  selectedRowData,
  master,
  areaTypes,
  evaluationStatuses,
  getFilteredCycles,
}) {
  const { triggerFunction } = useContext(TriggerContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [cycleValue, setCycleValue] = useState(selectedRowData.idCycle ?? null);
  const [selectedAreaType, setSelectedAreaType] = useState(
    selectedRowData.idAreaType ?? null
  );
  const [isProjectConfirmed, setIsProjectConfirmed] = useState(
    selectedRowData.isProjectConfirmed ?? null
  );

  const [professional, setProfessional] = useState(
    selectedRowData.idProfessional
  );

  const handleSelectedAreaType = useCallback(
    (areaType) => {
      const foundAreaType = areaTypes.__rawData.find(
        (at) => at.id === areaType
      );
      setSelectedAreaType(foundAreaType);
    },
    [areaTypes.__rawData]
  );

  const isInsert = !selectedRowData?.id;

  const handleEditInsert = useCallback(async () => {
    try {
      setIsLoading(true);
      isInsert
        ? await evaluationSource.store().insert(selectedRowData)
        : await evaluationSource
            .store()
            .update(selectedRowData.id, selectedRowData);

      evaluationSource.reload();
      const locationId = history.location.pathname.split("/");
      if (locationId.pop() == selectedRowData?.id) triggerFunction();
      setPopupVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    evaluationSource,
    history.location.pathname,
    isInsert,
    selectedRowData,
    setPopupVisible,
    triggerFunction,
  ]);

  return (
    <StyledPopup
      visible={popupVisible}
      onHiding={handleEditInsert}
      title="Edit Fields"
      showTitle={false}
      width="80vw"
      height="80vh"
    >
      <ScrollView className="custom-scroll">
        <Form formData={selectedRowData} colCount={2}>
          <Item
            dataField="idAreaType"
            editorType="dxSelectBox"
            label={{ text: "Area Type (changes evaluation structure)" }}
            isRequired
            editorOptions={{
              dataSource: areaTypes,
              valueExpr: "id",
              searchEnabled: true,
              displayExpr: "description",
              onValueChanged: (e) => {
                handleSelectedAreaType(e.value);
                setCycleValue(null);
                setIsProjectConfirmed(null);
              },
            }}
          />
          {selectedAreaType !== null && (
            <Item
              dataField="idCycle"
              editorType="dxSelectBox"
              isRequired
              label={{ text: "Cycle" }}
              editorOptions={{
                dataSource: getFilteredCycles({ data: selectedRowData }),
                displayExpr: "displayName",
                valueExpr: "id",
                searchEnabled: true,
                onValueChanged: (e) => {
                  setCycleValue(e.value);
                },
              }}
            />
          )}
          {!isInsert && selectedAreaType === null && (
            <Item
              dataField="idEvaluationStatus"
              editorType="dxSelectBox"
              label={{ text: "Status" }}
              editorOptions={{
                dataSource: evaluationStatuses,
                displayExpr: "description",
                valueExpr: "id",
                searchEnabled: true,
              }}
            />
          )}
          {selectedAreaType !== null && (
            <Item
              dataField="idProfessional"
              editorType="dxSelectBox"
              label={{ text: "Professional" }}
              isRequired
              editorOptions={{
                dataSource: master.professionals,
                displayExpr: "Name",
                valueExpr: "ID",
                searchEnabled: true,
                onValueChanged: (e) => {
                  setProfessional(e.value);
                },
              }}
            />
          )}
          {selectedAreaType !== null && selectedAreaType?.id != 1 && (
            <Item
              dataField="idProject"
              editorType="dxSelectBox"
              label={{ text: "Project" }}
              editorOptions={{
                dataSource: master.projects,
                displayExpr: (item) =>
                  item && item.ProjectCode && item.ProjectName
                    ? `${item.ProjectCode} - ${item.ProjectName}`
                    : "-",
                valueExpr: "ID",
                searchEnabled: true,
              }}
            />
          )}
          {selectedAreaType !== null &&
            !selectedAreaType?.isWeightedAverage && (
              <Item
                dataField="idEvaluator"
                editorType="dxSelectBox"
                label={{ text: "Evaluator" }}
                editorOptions={{
                  dataSource: master.professionals,
                  displayExpr: "Name",
                  valueExpr: "ID",
                  searchEnabled: true,
                }}
              />
            )}
          {selectedAreaType !== null &&
            !selectedAreaType?.isWeightedAverage && (
              <Item
                dataField="idValidator"
                editorType="dxSelectBox"
                label={{
                  text: "Validator",
                }}
                editorOptions={{
                  dataSource: master.professionals,
                  displayExpr: "Name",
                  valueExpr: "ID",
                  searchEnabled: true,
                }}
              />
            )}
          {selectedAreaType !== null && (
            <Item
              dataField="idPartner"
              editorType="dxSelectBox"
              label={{
                text:
                  selectedAreaType?.id !== 1 && selectedAreaType?.id !== 2
                    ? "Mentor"
                    : "Partner",
              }}
              editorOptions={{
                dataSource: master.professionals,
                displayExpr: "Name",
                valueExpr: "ID",
                searchEnabled: true,
                disabled: selectedAreaType === null,
              }}
            />
          )}
          {selectedAreaType !== null && (
            <Item
              dataField="idPractice"
              editorType="dxSelectBox"
              label={{
                text: "Practice",
              }}
              editorOptions={{
                dataSource: master.practices,
                displayExpr: "Name",
                valueExpr: "ID",
                searchEnabled: true,
                disabled: selectedAreaType === null,
              }}
            />
          )}
          {selectedAreaType !== null && (
            <Item
              dataField="idOffice"
              editorType="dxSelectBox"
              label={{
                text: "Office",
              }}
              editorOptions={{
                dataSource: master.offices,
                displayExpr: "Name",
                valueExpr: "ID",
                searchEnabled: true,
                disabled: selectedAreaType === null,
              }}
            />
          )}
          {selectedAreaType?.isWeightedAverage && selectedAreaType !== null && (
            <Item
              dataField="isProjectConfirmed"
              editorType="dxCheckBox"
              label={{ text: "IsProject Confirmed" }}
              editorOptions={{
                checked: isProjectConfirmed,
                onValueChanged: (e) => {
                  setIsProjectConfirmed(e.value);
                },
              }}
            />
          )}
        </Form>
      </ScrollView>
      <div className="buttons">
        <Button
          disabled={
            isLoading ||
            !selectedRowData.idProfessional ||
            !selectedRowData.idCycle
          }
          type="success"
          onClick={() => {
            handleEditInsert();
          }}
        >
          <CircularProgress
            size="1em"
            color="secondary"
            style={{ display: isLoading ? "block" : "none" }}
          />
          {isLoading ? "" : "Save"}
        </Button>
        <Button
          text="Cancel"
          type="default"
          onClick={() => setPopupVisible(false)}
        />
      </div>
    </StyledPopup>
  );
}
