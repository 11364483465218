import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const CounselingVideoDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Counseling video</DialogTitle>
      <DialogContent>
        <iframe
          style={{
            width: "400px",
            padding: "0px",
            position: "relative",
            top: 0,
            left: 0,
            marginBottom: "50px",
            height: "300px",
          }}
          src={
            "https://player.vimeo.com/video/680126918?h=517d7d1a84&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          }
          title="Tutorial"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CounselingVideoDialog;
