import React, { useEffect, useState, useContext } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import Button from "../Button";
import { Container } from "./styles";
import { useAuth } from "../../hooks/auth";
import Surface from "../Surface";

export default function ApplicationLayers({ children }) {
  const { token, isAuthenticated, redirectMicrosoft } = useAuth();
  const [openAlert, setOpenAlert] = useState(false);
  const [isMobile, setisMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setisMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (token && token.expiredAt) {
      setInterval(() => {
        const counterInMinutes = (token.expiredAt - new Date()) / 1000 / 60;

        if (Math.round(counterInMinutes) === 5) {
          setOpenAlert(true);
        }
      }, 1000 * 60); // every minute
    }
  }, [token, isAuthenticated, redirectMicrosoft]);

  return (
    <>
      <Container>{children}</Container>

      {openAlert && (
        <Dialog
          onClose={() => setOpenAlert(false)}
          open={openAlert}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>You session will expire</DialogTitle>
          <DialogContent>
            <p>Your session will expire soon. (5 minutes)</p>
            <p>
              Please copy your data or save to sign-in again and refresh your
              session.
            </p>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => redirectMicrosoft()}>Sign In again</Button>
            <Button primary onClick={() => setOpenAlert(false)}>
              Back and copy data
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
