import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";

const ComponentVisibleContext = createContext({});

export function ComponentVisibleProvider({ initialIsVisible, children }) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <ComponentVisibleContext.Provider
      value={{ ref, isComponentVisible, setIsComponentVisible }}
    >
      {children}
    </ComponentVisibleContext.Provider>
  );
}

export function useComponentVisible() {
  const context = useContext(ComponentVisibleContext);

  if (!context) {
    throw new Error("useComponentVisible must be used within an AuthProvider");
  }

  return context;
}
