import React, { useContext, useState, useEffect } from "react";
import { Container } from "./styles";
import Overall from "./Tabs/Overall";
import { useHistory, useParams } from "react-router-dom";
import { TriggerContext } from "../../context/TriggerProvider";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import SidePanelLoader from "../ContentLoader";
import SidePanelHeader from "./Header";
import ActionPlans from "./Tabs/ActionPlans";
import Charts from "./Tabs/Charts";
import Details from "./Tabs/Details";
import EvaluationCycle from "./Tabs/EvaluationCycle";
import PromotionCycle from "./Tabs/PromotionCycle";

export default function PromotionSidePanel({
  isSidePanelOpen,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  path,
}) {
  const { id } = useParams();
  const history = useHistory();
  const [promotion, setPromotion] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToast();
  const { trigger, triggers } = useContext(TriggerContext);

  useEffect(() => {
    setIsSidePanelOpen(true);
    const loadPromotion = async () => {
      try {
        setIsLoading(true);
        const promotion = await api.get(`/api/promotion/${id}`);
        setPromotion(promotion.data);
        setIsLoading(false);
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong on loading data",
        });
        history.push("/promotions/");
        setIsSidePanelFullScreen(false);
        setIsSidePanelOpen(false);
        throw err;
      }
    };
    loadPromotion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    id,
    setIsSidePanelFullScreen,
    triggers.editPromotionList,
    addToast,
    history,
    id,
    setIsSidePanelFullScreen,
    trigger,
  ]);

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Overall",
      content: (
        <Overall
          isSidePanelFullScreen={isSidePanelFullScreen}
          promotion={promotion}
        />
      ),
    },
    {
      label: "Evaluation Cycle",
      content: (
        <EvaluationCycle
          isSidePanelFullScreen={isSidePanelFullScreen}
          promotion={promotion}
        />
      ),
    },
    {
      label: "Charts",
      content: (
        <Charts
          isSidePanelFullScreen={isSidePanelFullScreen}
          promotion={promotion}
        />
      ),
    },
    {
      label: "Details",
      content: (
        <Details
          isSidePanelFullScreen={isSidePanelFullScreen}
          promotion={promotion}
        />
      ),
    },
    {
      label: "Action Plans",
      content: (
        <ActionPlans
          isSidePanelFullScreen={isSidePanelFullScreen}
          promotion={promotion}
        />
      ),
    },
    {
      label: "Promotion Cycle",
      content: (
        <PromotionCycle
          isSidePanelFullScreen={isSidePanelFullScreen}
          promotion={promotion}
        />
      ),
    },
  ];

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {isLoading ? (
        <SidePanelLoader width="100%" />
      ) : (
        <>
          <SidePanelHeader
            isSidePanelOpen={isSidePanelOpen}
            setIsSidePanelOpen={setIsSidePanelOpen}
            isSidePanelFullScreen={isSidePanelFullScreen}
            setIsSidePanelFullScreen={setIsSidePanelFullScreen}
            promotion={promotion}
            path={path}
          />

          {!isSidePanelFullScreen && (
            <div className="tabs-menu">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={activeTab === index ? "active" : ""}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  {tab.label}
                </div>
              ))}
            </div>
          )}

          <div
            className={`content-wrapper ${
              !isSidePanelFullScreen ? "no-scroll" : ""
            }`}
          >
            {tabs.map((tab, index) => (
              <div
                key={index}
                className="content"
                style={{
                  display: !isSidePanelFullScreen
                    ? activeTab === index
                      ? ""
                      : "none"
                    : "",
                }}
              >
                {isSidePanelFullScreen && (
                  <h2 className="separator">{tab.label}</h2>
                )}
                {tab.content}
              </div>
            ))}
          </div>
        </>
      )}
    </Container>
  );
}
