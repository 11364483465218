import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { Column, DataGrid, Lookup } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { WhisperSpinner } from "react-spinners-kit";
import { TriggerContext } from "../../../context/TriggerProvider";
import { ValueContext } from "../../../context/ValueProvider";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import Button from "../../Button";
import PhotoCell from "../../EvaluationList/CellRender/PhotoCell";
import { Container, GridContainer } from "./styles";

export default function PastTutors({
  listData,
  isSidePanelFullScreen,
  isAdmin,
}) {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmKey, setConfirmKey] = useState(null);
  const { master } = useContext(ValueContext);
  const { triggerFunction } = useContext(TriggerContext);

  const handleReactivate = useCallback(
    async (confirmKey) => {
      setIsLoading(true);
      try {
        await api.put(`api/tutorship/${confirmKey}/reactivate`);

        addToast({
          type: "success",
          title: "Tutorship reactivated.",
        });
        triggerFunction();
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong...",
        });
      } finally {
        setIsLoading(false);
        setOpen(false);
      }
    },
    [addToast, triggerFunction]
  );
  const dataSource = useMemo(() => {
    if (listData?.id) {
      return new DataSource({
        key: "id",
        loadMode: "raw",
        load: async () => {
          try {
            const { data } = await api.get(
              `api/tutorship/${listData?.id}/past`
            );
            return data;
          } catch (err) {
            addToast({
              type: "error",
              title: "Something went wrong...",
            });
            throw err;
          }
        },
        remove: async (key) => {
          try {
            await api.delete(`api/tutorship/${key}`);
          } catch (err) {
            addToast({
              type: "error",
              title: "Something went when removing data...",
            });
            throw err;
          }
        },
      });
    }
  }, [addToast, listData]);

  const reactivateCell = useCallback(
    (e) => (
      <Button
        style={{
          padding: "5px",
        }}
        primary
        onClick={() => {
          setConfirmKey(e.key);
          setOpen(true);
        }}
      >
        Reactivate
      </Button>
    ),
    []
  );

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <GridContainer>
        <DataGrid
          dataSource={dataSource}
          headerFilter={{ visible: true }}
          scrolling={{ mode: "scrolling" }}
          editing={{ allowDeleting: isAdmin }}
        >
          <Column
            caption="Tutor"
            dataField="idTutor"
            width={100}
            cellRender={(e) => (
              <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <PhotoCell
                  data={master.professionals?.find(
                    (p) => p.ID === e.data.idTutor
                  )}
                />
                {e.data.name}
              </div>
            )}
            alignment="center"
          >
            <Lookup
              dataSource={master.professionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column
            caption="Start Date"
            alignment="center"
            dataField="startDate"
            dataType="date"
            format="dd MMM yyyy"
          />
          <Column
            caption="End Date"
            alignment="center"
            dataField="endDate"
            dataType="date"
            format="dd MMM yyyy"
          />
          <Column
            alignment="center"
            width={130}
            cellRender={reactivateCell}
            visible={isAdmin}
          />
        </DataGrid>
      </GridContainer>

      <Dialog open={open}>
        <DialogTitle>Reactivate this tutor?</DialogTitle>
        <DialogActions>
          <Button
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            primary
            disabled={isLoading}
            onClick={() => handleReactivate(confirmKey)}
          >
            Save
            {isLoading && (
              <WhisperSpinner
                size={20}
                color="#8b0304"
                backColor="#8b0304"
                frontColor="#fff"
              />
            )}
          </Button>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
