/* eslint-disable no-restricted-globals */
import ApplicationLayers from "./components/ApplicationLayers";
import { PrivateRoute } from "./components/PrivateRoute";
import React, { useState } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import HeaderCycle from "./components/HeaderCycle";
import MainList from "./components/EvaluationList/MainList";
import SidePanel from "./components/SidePanel";
import EvaluationToDos from "./components/EvaluationList/EvaluationToDos";
import DosAndDontsList from "./components/DoDontList";
import DoDontSidePanel from "./components/DoDontSidePanel";
import PromotionsList from "./components/EvaluationList/PromotionsList";
import CounselingList from "./components/EvaluationList/CounselingList";
import CounselingSidePanel from "./components/CounselingSidePanel";
import ActionPlan from "./pages/Promotion/ActionPlan";
import { ValueProvider } from "./context/ValueProvider";
import Surface from "./components/Surface";
import PromotionSidePanel from "./components/PromotionSidePanel";
import MentorintList from "./components/EvaluationList/MentorintList";
import SidePanelMentorInt from "./components/SidePanelMentorint";
import TutorshipList from "./components/EvaluationList/TutorshipList";
import SidePanelTutorship from "./components/SidePanelTutorship";
import Admin from "./pages/Admin";
import { TriggerProvider } from "./context/TriggerProvider";
import { AuthProvider } from "./hooks/auth";
import { ToastProvider } from "./hooks/toast";
import { ComponentVisibleProvider } from "./hooks/componentVisible";
import Home from "./pages/Home";

export default function Routes() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastProvider>
          <ComponentVisibleProvider>
            <ApplicationLayers>
              <PrivateRoute path="/" component={InsideRoutes} />
            </ApplicationLayers>
          </ComponentVisibleProvider>
        </ToastProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

const routes = [
  {
    path: "/home/",
    component: Home,
    name: "home",
  },
  {
    path: "/myevaluations/",
    component: MainList,
    name: "myevaluations",
  },
  {
    path: "/allevaluations/",
    component: MainList,
    name: "allevaluations",
  },
  {
    path: "/businessunit/",
    component: MainList,
    name: "businessunit",
  },
  {
    path: "/toevaluate/",
    component: MainList,
    name: "toevaluate",
    extraComponent: EvaluationToDos,
  },
  {
    path: "/promotions/",
    component: PromotionsList,
    name: "promotions",
  },
  {
    path: "/actionplans/",
    component: ActionPlan,
    name: "actionplans",
  },
  {
    path: "/counseling/:id?/:year?",
    component: CounselingList,
    name: "counseling",
  },
  {
    path: "/dosanddonts/",
    component: DosAndDontsList,
    name: "dosanddonts",
  },
  {
    path: "/mentorint/:id?",
    component: MentorintList,
    name: "mentorint",
  },
  {
    path: "/tutorship/:id?",
    component: TutorshipList,
    name: "tutorship",
  },
  {
    path: "/admin/evaluations/",
    component: Admin,
    name: "evaluations",
  },
  {
    path: "/admin/promotions/",
    component: Admin,
    name: "promotions",
  },
  {
    path: "/admin/dosanddonts/",
    component: Admin,
    name: "dosanddonts",
  },
  {
    path: "/admin/counseling/",
    component: Admin,
    name: "counseling",
  },
  {
    path: "/admin/mentorint/",
    component: Admin,
    name: "mentorint",
  },
  {
    path: "/admin/tutorship/",
    component: Admin,
    name: "tutorship",
  },
];

const sidePanelRoutes = [
  {
    path: "/allevaluations/:id",
    component: SidePanel,
    name: "allevaluations",
  },
  {
    path: "/myevaluations/:id",
    component: SidePanel,
    name: "myevaluations",
  },
  {
    path: "/businessunit/:id",
    component: SidePanel,
    name: "businessunit",
  },
  {
    path: "/toevaluate/:id",
    component: SidePanel,
    name: "toevaluate",
  },
  {
    path: "/promotions/:id",
    component: PromotionSidePanel,
    name: "promotions",
  },
  {
    path: "/counseling/:id/:year?",
    component: CounselingSidePanel,
    name: "counseling",
  },
  {
    path: "/dosanddonts/:id",
    component: DoDontSidePanel,
    name: "dosanddonts",
  },
  {
    path: "/mentorint/:id",
    component: SidePanelMentorInt,
    name: "mentorint",
  },
  {
    path: "/tutorship/:id",
    component: SidePanelTutorship,
    name: "tutorship",
  },
];

function InsideRoutes() {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isSidePanelFullScreen, setIsSidePanelFullScreen] = useState(false);
  const [listData, setListData] = useState();

  return (
    <TriggerProvider>
      <ValueProvider>
        <Surface
          isSidePanelFullScreen={isSidePanelFullScreen}
          setIsSidePanelFullScreen={setIsSidePanelFullScreen}
          isSidePanelOpen={isSidePanelOpen}
          setIsSidePanelOpen={setIsSidePanelOpen}
        />
        <div
          className="wrapper"
          style={{
            maxWidth: isSidePanelOpen
              ? "calc(100vw - 80px - 600px)" //  menu width and sidepanel width
              : "calc(100vw - 80px)",
            display: isSidePanelFullScreen ? "none" : "flex",
            flexDirection: "column",
            height: "100vh",
            gap: "20px",
            padding: "20px",
            width: "100%",
          }}
        >
          {(window?.location?.pathname.replace("/", "") === "myevaluations/" ||
            window?.location?.pathname.replace("/", "") === "allevaluations/" || 
            window?.location?.pathname.replace("/", "") === "toevaluate/" || 
            window?.location?.pathname.replace("/", "") === "promotions/") &&
            (
              <HeaderCycle isSidePanelOpen={isSidePanelOpen} />
            )}
          <Switch>
            <Redirect exact from="/" to="/home/" />
            {routes.map((route) => (
              <Route key={route.path} path={route.path}>
                {route.extraComponent ? (
                  <>
                    <route.component
                      path={route.name}
                      setListData={setListData}
                    />
                    <route.extraComponent setListData={setListData} />
                  </>
                ) : (
                  <route.component
                    path={route.name}
                    setListData={setListData}
                  />
                )}
              </Route>
            ))}
          </Switch>
        </div>
        <Switch>
          {sidePanelRoutes.map((route) => (
            <Route key={route.path} path={route.path}>
              {route.component === SidePanel ? (
                <SidePanel
                  isSidePanelOpen={isSidePanelOpen}
                  setIsSidePanelOpen={setIsSidePanelOpen}
                  isSidePanelFullScreen={isSidePanelFullScreen}
                  setIsSidePanelFullScreen={setIsSidePanelFullScreen}
                  path={route.name}
                  listData={listData}
                />
              ) : (
                <route.component
                  isSidePanelOpen={isSidePanelOpen}
                  setIsSidePanelOpen={setIsSidePanelOpen}
                  isSidePanelFullScreen={isSidePanelFullScreen}
                  setIsSidePanelFullScreen={setIsSidePanelFullScreen}
                  path={route.name}
                  listData={listData}
                />
              )}
            </Route>
          ))}
        </Switch>
      </ValueProvider>
    </TriggerProvider>
  );
}
