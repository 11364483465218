import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background-color: #e3e3e3;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;