import React, { useState, useCallback, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
} from "@material-ui/core";
import { TextArea, DateBox } from "devextreme-react";
import { Container } from "./styles";
import Button from "../../../../components/Button";
import api from "../../../../../src/services/api";
import { useToast } from "../../../../hooks/toast";
import { TriggerContext } from "../../../../context/TriggerProvider";

export default function DialogAddConversation({
  open,
  setOpenAddConversation,
  loadTalks,
  id,
}) {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [wasGood, setWasGood] = useState(null);
  const [conversationDate, setConversationDate] = useState("");
  const { triggerFunction } = useContext(TriggerContext);

  const handleAddConversation = useCallback(async () => {
    if (!wasGood || !conversationDate)
      return addToast({
        type: "error",
        title: "Please fill all required fields.",
      });
    try {
      setIsLoading(true);
      const data = {
        conversationDate,
        wasGood,
        comment,
      };
      await api.post(`api/professional-mentor/${id}/talks`, data);
      triggerFunction();
      addToast({
        type: "success",
        title: "Conversation added successfully.",
      });
    } catch (err) {
      addToast({
        type: "error",
        title: "Something went wrong on adding conversation.",
      });
      throw err;
    } finally {
      setIsLoading(false);
    }
    loadTalks();
    setOpenAddConversation(false);
  }, [
    wasGood,
    conversationDate,
    addToast,
    loadTalks,
    setOpenAddConversation,
    comment,
    id,
    triggerFunction,
  ]);

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
        Add Conversation
      </DialogTitle>
      <DialogContent>
        <Container>
          <div>
            <div>
              <label>Conversation Was Good?</label>
              <RadioGroup
                onClick={(e) => setWasGood(e.target.value)}
                value={wasGood}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio required />}
                  label="Yes"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio required />}
                  label="No"
                />
              </RadioGroup>
            </div>
            <div>
              <label>Conversation Date</label>
              <DateBox
                label="Conversation Date"
                openOnFieldClick
                onValueChange={(e) => {
                  setConversationDate(e);
                }}
                max={new Date()}
                displayFormat="dd MM yyyy"
              />
            </div>
          </div>

          <div>
            <label>Conversation comments</label>
            <TextArea
              className="styled-textarea"
              style={{ backgroundColor: "#EFEFEF" }}
              onValueChange={(e) => setComment(e)}
            />
          </div>
        </Container>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          padding: "20px",
        }}
      >
        <Button primary onClick={handleAddConversation} disabled={isLoading}>
          {isLoading ? <CircularProgress size="1em" /> : "Save"}
        </Button>
        <Button onClick={() => setOpenAddConversation(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
