import styled from "styled-components";

export const Fieldset = styled.fieldset`
  margin-bottom: 32px;
`;

export const Label = styled.label`
  font-weight: 700;
`;
export const AddDoDontFix = styled.div`
  .dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    padding-top: 0px !important;
    padding-left: 2px !important;
  }
  .dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    display: inline-block !important;
  }
`;
