import DataGrid, {
  Column,
  Editing,
  Grouping,
  Lookup,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { ToolbarItem } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useMemo, useState } from "react";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { StyledPopup } from "../../../../components/EvaluationList/styles";
import ProfessionalLookup from "../../../../components/ProfessionalLookup";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import GroupTemplate from "./GroupTemplate";

export default function DialogEditProject({
  evaluationSource,
  isProjectConfirmed,
  evaluationId,
  open,
  master,
  handleClose,
}) {
  const [groupedData, setGroupedData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const projectSource = useMemo(() => {
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      paginate: true,
      load: async () => {
        const { data } = await api.get(
          `api/evaluations/${evaluationId}/senior-projects`
        );

        const activeMembers = data.filter((x) => x.isActive).map((x) => x.id);
        setSelectedKeys(activeMembers);

        // if (isProjectConfirmed) {
        //   return data
        //     .filter((x) => x.isActive)
        //     .map((project) => ({
        //       ...project,
        //       displayName: `${project.projectName} - ${project.projectCode}`,
        //     }));
        // } else {
        return data.map((project) => ({
          ...project,
          displayName: `${project.projectName} - ${project.projectCode}`,
        }));
      },
      update: async (id, data) => {
        try {
          await api.put(
            `api/evaluations/${evaluationId}/senior-projects/${id}`,
            data
          );

          addToast({
            type: "success",
            title: "Saved",
          });
        } catch (error) {
          addToast({
            type: "error",
            title:
              "Something went wrong on saving data. Make sure there is only one partner selected per project.",
          });
        }
      },
    });
  }, [evaluationId, addToast]);

  const professionalCell = useCallback(
    (e) => {
      const professional = master.professionals.find(
        (x) => x.ID === e.data.idEvaluator
      );
      return (
        <ProfessionalLookup
          name={professional && professional.Name}
          login={professional && professional.Login}
          jobtitle={professional && professional.JobTitle.Name}
        />
      );
    },
    [master.professionals]
  );

  const checkIfKeysAreSelected = useCallback((currentKeys, selectedKeys) => {
    let count = 0;

    if (selectedKeys.length === 0) return false;
    for (var i = 0; i < currentKeys.length; i++) {
      var keyValue = currentKeys[i];
      if (selectedKeys.indexOf(keyValue) > -1)
        // key is not selected
        count++;
    }
    if (count === 0) return false;
    if (currentKeys.length === count) return true;
    else return undefined;
  }, []);

  const getGroupText = useCallback(
    (column, text, groupContinuesMessage, groupContinuedMessage) => {
      let groupText = "";
      groupText = column.caption + ": " + text;
      if (groupContinuedMessage && groupContinuesMessage) {
        groupText +=
          " (" + groupContinuedMessage + ". " + groupContinuesMessage + ")";
      } else if (groupContinuesMessage) {
        groupText += " (" + groupContinuesMessage + ")";
      } else if (groupContinuedMessage) {
        groupText += " (" + groupContinuedMessage + ")";
      }
      return groupText;
    },
    []
  );

  const getKeys = useCallback(
    (keys, groupedColumnName, groupKey, keyFieldName) => {
      const groupRow = groupedData.find((i) => {
        return i.key === groupKey;
      });
      if (groupRow) keys = groupRow.items.map((i) => i[keyFieldName]);

      return keys;
    },
    [groupedData]
  );

  const onValueChanged = useCallback((args, grid, keys) => {
    if (!args.event) return;
    if (args.value) grid.selectRows(keys, true);
    else grid.deselectRows(keys);
  }, []);

  const groupCellRender = useCallback(
    ({
      column,
      value,
      groupContinuedMessage,
      groupContinuesMessage,
      text,
      component,
    }) => {
      const keys = getKeys([], column.dataField, value, "id");
      const checked = checkIfKeysAreSelected(
        keys,
        component.getSelectedRowKeys()
      );
      const groupText = getGroupText(
        column,
        text,
        groupContinuesMessage,
        groupContinuedMessage
      );

      return (
        <GroupTemplate
          checked={checked}
          groupText={groupText}
          onValueChanged={(args) => onValueChanged(args, component, keys)}
          isProjectConfirmed={isProjectConfirmed}
        />
      );
    },
    [
      isProjectConfirmed,
      checkIfKeysAreSelected,
      getGroupText,
      getKeys,
      onValueChanged,
    ]
  );

  const onSelectionChanged = useCallback(
    async (args) => {
      try {
        const keys = groupedData.slice();
        setSelectedKeys(args.selectedRowKeys);

        if (!firstLoad) {
          await Promise.all([
            ...args.currentDeselectedRowKeys.map((x) =>
              api.put(`api/evaluations/${evaluationId}/senior-projects/${x}`, {
                isActive: false,
              })
            ),
            ...args.currentSelectedRowKeys.map((x) =>
              api.put(`api/evaluations/${evaluationId}/senior-projects/${x}`, {
                isActive: true,
              })
            ),
          ]);

          addToast({
            type: "success",
            title: "Saved",
          });
        }
        setFirstLoad(false);
        setGroupedData(keys);
      } catch (error) {
        let errorMessage = error?.response?.data?.split("\n")[0];
        errorMessage = errorMessage?.replace("System.Exception: ", "");
        addToast({
          type: "error",
          title:
            errorMessage ||
            "Something went wrong on saving data. Make sure there is only one partner selected per project.",
        });
        return;
      }
    },
    [addToast, evaluationId, firstLoad, groupedData]
  );

  const onContentReady = useCallback((args) => {
    if (args.component.isNotFirstLoad) return;
    args.component.isNotFirstLoad = true;
    const ds = args.component.getDataSource();
    ds.store()
      .load(ds.loadOptions())
      .done((r) => {
        setGroupedData(r);
      });
  }, []);

  const handleEditorPreparing = useCallback(
    (e) => {
      if (e.command === "select") {
        e.editorOptions.disabled = isProjectConfirmed;
      }
    },
    [isProjectConfirmed]
  );

  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await api.put(`api/evaluations/${evaluationId}`, {
        isProjectConfirmed: true,
      });
      evaluationSource.reload();
      addToast({
        type: "success",
        title: "Success!",
      });
      handleClose();
    } catch (e) {
      addToast({
        type: "error",
        title:
          "Something went wrong... Make sure there is only one partner selected per project.",
      });
      setLoading(false);
    }
  }, [evaluationId, evaluationSource, addToast, handleClose]);

  return (
    <StyledPopup
      visible={open}
      onHiding={() => {
        evaluationSource.reload();
        handleClose();
      }}
      width="80vw"
      height="80vh"
      showTitle
      title="Evaluation Projects"
      showCloseButton={!loading}
    >
      <ToolbarItem
        widget="dxButton"
        location="after"
        toolbar="top"
        options={{
          disabled: loading,
          template: () => {
            return loading ? "Loading..." : "Confirm";
          },
          onClick: () => {
            handleConfirm();
          },
        }}
      />
      {!isProjectConfirmed && (
        <div className="alerts">
          {/* <div className="alert important">
            <FiAlertTriangle size={18} />
            <ul>
              <li>
                When you confirm the projects selection it will not be possible
                to edit them again.
              </li>
            </ul>
          </div> */}
          <div className="alert">
            <FiInfo size={18} />
            <ul>
              <li>
                The projects below consider a minimum allocation period of 5
                days.
              </li>
              <li>
                If is there any other project or team member not listed and
                you'd like to consider, please contact <b>HR</b>.
              </li>
              <li>You can only select ONE partner for each project.</li>
            </ul>
          </div>
        </div>
      )}
      <div className="grid">
        <DataGrid
          dataSource={projectSource}
          key="id"
          onSelectionChanged={onSelectionChanged}
          onContentReady={onContentReady}
          selectedRowKeys={selectedKeys}
          showColumnHeaders={false}
          // onEditorPreparing={handleEditorPreparing}
          width="100%"
          height="100%"
        >
          <Selection mode="multiple" selectAllMode="allPages" />

          <Grouping autoExpandAll={false} />
          <Scrolling mode="virtual" />
          <Editing allowUpdating mode="cell" />
          <Column
            dataField="displayName"
            caption="Project"
            alignment="left"
            groupIndex={0}
            groupCellRender={groupCellRender}
          />
          <Column
            dataField="idEvaluator"
            allowEditing={false}
            alignment="left"
            caption="Professional"
            cellRender={professionalCell}
          >
            <Lookup
              dataSource={master.professionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
        </DataGrid>
      </div>
    </StyledPopup>
  );
}
