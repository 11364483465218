import React from "react";
import { Container } from "./styles";

export default function ProfessionalLookup({
  name,
  login,
  jobtitle,
  isMentoree,
  ...rest
}) {
  return (
    <Container {...rest}>
      {login && (
        <img
          src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${login}_192_192.png`}
          alt={name}
        />
      )}

      <div>
        <p className="name">{name}</p>
        <p className="jobtitle">{jobtitle}</p>
        {isMentoree && (
          <p style={{ color: "#8b0305", fontWeight: 500 }}>Mentoree</p>
        )}
      </div>
    </Container>
  );
}
