import { StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";

import styled from "styled-components";

export const DownloadButton = styled(PDFDownloadLink)`
  font-weight: 700;
  font-size: 12;
  padding: 0;
  background-color: transparent;
  color: #8b0304;

  &:hover {
    text-decoration: none;
    color: #8b0304;
    background-color: transparent;
  }

  &:disabled {
    color: #8b0304;
    background-color: transparent;
  }
`;

export const styles = StyleSheet.create({
  page: { fontFamily: "Helvetica" },
  header: { color: "#333333", padding: "15px 47px" },
  headerStatus: {
    textAlign: "center",
  },
  headerStatusBar: {
    width: "100%",
    height: 5,
  },
  headerStatusText: {
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: 0.1,
    textTransform: "uppercase",
    marginBottom: 16,
  },
  headerLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: 0.1,
    textTransform: "uppercase",
    color: "#333",
    marginBottom: 15,
  },
  headerSubTitle: {
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: 0.1,
    textTransform: "uppercase",
    color: "#999",
  },
  image: {
    maxWidth: "99px",
  },
  headerLineItem: {
    fontSize: 12,
    letterSpacing: 0.1,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginRight: 8,
  },
  headerLineItemTitle: {
    fontSize: 11,
    color: "#999999",
  },
  totalGrade: {
    fontWeight: 700,
    fontSize: 24,
    color: "#8b0304",
  },
  body: {
    color: "#333333",
    padding: "15px 47px",
  },
  bodyTitle: {
    width: "100%",
    borderBottom: 2,
    borderColor: "#dddddd",
    borderBottomStyle: "solid",
    marginBottom: 20,
    fontSize: 12,
    color: "#999999",
    paddingBottom: 10,
    fontWeight: 700,
  },
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 12,
    color: "#333",
    marginBottom: 8,
  },
  sectionContent: {
    fontSize: 10,
    backgroundColor: "#efefef",
    minHeight: 100,
    padding: 18,
  },
  sectionCompetences: {
    marginBottom: 15,
  },
  sectionCompetenceGrade: {
    fontSize: 10,
    fontWeight: 700,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 8,
    backgroundColor: "#efefef",
    padding: "5px 10px",
  },
  sectionGradeTitle: {
    fontSize: 10,
    color: "#999999",
  },
  sectionGradeText: {
    fontWeight: 700,
    fontSize: 12,
    color: "#333",
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    // flex: '1 0 48%',
    marginBottom: 16,
    width: "100%",
  },
  summaryTitle: {
    fontWeight: 700,
    fontSize: 12,
    color: "#333",
    marginBottom: 8,
  },
  summaryCompetenceTitle: {
    fontWeight: 700,
    fontSize: 10,
    color: "#999",
    marginBottom: 8,
  },
  summaryCompetenceGrade: {
    fontWeight: 700,
    fontSize: 10,
  },
  summaryCompetenceSession: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#efefef",
    padding: 10,
  },
  summaryCompetence: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  summaryCompetenceSeniors: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
  },
  summarySession: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    padding: "15px 47px",
  },
});
