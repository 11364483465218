import { Tooltip } from "@material-ui/core";
import DataGrid, { Column, Lookup } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import RecommendationIcon from "../../assets/images/recommendation-icon.svg";
import pdsGroups from "../../config/pdsGroups";
import { TriggerContext } from "../../context/TriggerProvider";
import { ValueContext } from "../../context/ValueProvider";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import master from "../../services/master";
import OfficeIcon from "../OfficeIcons";
import ProfessionalLookup from "../ProfessionalLookup";
import PhotoCell from "./CellRender/PhotoCell";
import { Container, PromotionStatusCell } from "./styles";
import { NumberBox } from "devextreme-react";

export default function PromotionsList({ path }) {
  const { hasScope } = useAuth();
  const { triggers, triggersFunction, trigger } = useContext(TriggerContext);
  const { master: masterContext } = useContext(ValueContext);
  const { addToast } = useToast();
  const history = useHistory();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);

  const isCorpLeader = useMemo(() => {
    return hasScope([pdsGroups.CorporateLeaders]);
  }, [hasScope]);

  const promotionStatus = useMemo(
    () => ({
      store: new CustomStore({
        key: "ID",
        loadMode: "raw",
        load: async () => {
          const { data } = await api.get("api/promotion-status");
          return data;
        },
      }),
      paginate: true,
    }),
    []
  );

  const cycles = useMemo(
    () => ({
      store: new CustomStore({
        key: "ID",
        loadMode: "raw",
        load: async () => {
          const { data } = await api.get("api/promotioncycle");
          const cyclesWithDisplayName = data.map((cycle) => ({
            ...cycle,
            displayName: `(${cycle.areaType?.description}) ${cycle.period}Q - ${cycle.currentYear}`,
          }));

          return cyclesWithDisplayName.sort(
            (a, b) => b.areaType?.description - a.areaType?.description
          );
        },
      }),
      paginate: true,
    }),
    []
  );

  const jobTitleSource = useMemo(
    () => ({
      store: new CustomStore({
        key: "ID",
        loadMode: "raw",
        load: async () => {
          const response = await master.get("master/jobtitles");
          const sortedData = response.data.sort(
            (a, b) => b.PDSRanking - a.PDSRanking
          );
          return sortedData.sort((a, b) => a.PDSRanking - b.PDSRanking);
        },
        sort: [{ field: "PDSRanking", asc: true }],
      }),
      paginate: true,
    }),
    []
  );

  const filterUndefinedProperties = useCallback((obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== undefined)
    );
  }, []);

  const dataSource = useMemo(() => {
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      reshapeOnPush: true,
      load: async () => {
        const { data } = await api.get("/api/promotion");
        return data;
      },
      update: async (key, values) => {
        const { professional, ...otherValues } = values;
        await api.put(`/api/promotion/${key}`, otherValues);

        if (professional) {
          const professionalMasterData = {
            LastPromotion: professional?.lastPromotion,
            LastRangeAdjustment: professional?.lastRangeAdjustment,
            GraduationDate: professional?.graduationDate,
          };
          await master.put(
            `master/professionals/${values.professional.id}`,
            filterUndefinedProperties(professionalMasterData)
          );
        }
        addToast({
          type: "success",
          title: "Saved",
        });
      },
      insert: async (values) => {
        const { professional, ...otherValues } = values;
        if (professional) {
          const professionalMasterData = {
            LastPromotion: professional?.lastPromotion,
            LastRangeAdjustment: professional?.lastRangeAdjustment,
            GraduationDate: professional?.graduationDate,
          };
          await master.put(
            `master/professionals/${values.professional.id}`,
            filterUndefinedProperties(professionalMasterData)
          );
        }
        await api.post(`/api/promotion`, otherValues);
        addToast({
          type: "success",
          title: "Saved",
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggers.promotion, trigger]);

  const handleRowClick = useCallback(
    (e) => {
      history.push(`${e?.data?.id}`);
    },
    [history]
  );

  return (
    <Container style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <DataGrid
        dataSource={dataSource}
        key="id"
        width="100%"
        height="100%"
        headerFilter={{ visible: true, allowSearch: true }}
        onRowUpdating={(options) => {
          if (options.newData.professional) {
            const professionalId = options.oldData.professional?.id;
            options.newData = {
              ...options.newData,
              professional: {
                id: professionalId,
                ...options.newData.professional,
              },
            };
          }
        }}
        onRowInserting={(options) => {
          if (options.data.professional) {
            const professionalId = options.data.idProfessional;
            options.data = {
              ...options.data,
              professional: {
                id: professionalId,
                ...options.data.professional,
              },
            };
          }
        }}
        columnAutoWidth
        sorting={{
          mode: "multiple",
          defaultSorted: [
            { selector: "cycle", desc: false },
            { selector: "PDSRanking", desc: false },
          ],
        }}
        // onSortingChanged={(e) => {
        //   const columnSorting = e.component.getCombinedSorting(true);
        //   const jobTitleSorting = columnSorting.find(
        //     (s) => s.selector === "idJobTitle"
        //   );
        //   if (jobTitleSorting) {
        //     e.component.columnOption("idJobTitle", "sortingMethod", (a, b) => {
        //       if (jobTitleSorting.desc) {
        //         return b.PDSRanking - a.PDSRanking; // Descendente
        //       } else {
        //         return a.PDSRanking - b.PDSRanking; // Ascendente
        //       }
        //     });
        //   }
        // }}
        onRowClick={(e) => handleRowClick(e)}
        onToolbarPreparing={(e) => {
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
              text: "Clear Filters",
              elementAttr: { class: "clear-filter" },
              icon: "filter",
              onClick: () => {
                e.component.clearFilter();
              },
            },
          });
        }}
        onSaving={(e) => {
          const locationId = history.location.pathname.split("/");

          if (e.changes.length > 0 && locationId.pop() == e.changes.key) {
            triggersFunction("editPromotionList");
          }
        }}
        editing={{
          mode: "popup",
          allowUpdating: true,
          allowAdding: isAdmin,
          form: {
            colCount: 4,
          },
        }}
        allowColumnReordering
        allowColumnResizing
      >
        <Column
          dataField="idStatus"
          caption="Status"
          width={180}
          cellRender={(e) => (
            <PromotionStatusCell status={e.data?.promotionStatus?.id}>
              <PhotoCell data={e.data?.professional} />
              {e.data?.promotionStatus?.description}
            </PromotionStatusCell>
          )}
          alignment="left"
          // formItem={{ colSpan: 4 }}
          editorOptions={{ disabled: !isAdmin }}
        >
          <Lookup
            dataSource={promotionStatus}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>
        {/* <Column
          dataField="promotionCycle.areaType.description"
          caption="Area Type"
          allowEditing={false}
          formItem={{ visible: false }}
          alignment="center"
        /> */}
        <Column
          dataField="idCycle"
          caption="Cycle"
          cellRender={(e) => {
            return (
              <p>
                {e.data?.promotionCycle?.period}Q -{" "}
                {e.data?.promotionCycle?.currentYear}
              </p>
            );
          }}
          alignment="center"
          editorOptions={{ disabled: !isAdmin }}
        >
          <Lookup
            dataSource={cycles}
            displayExpr="displayName"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="entryDate"
          caption="Entry date"
          alignment="center"
          dataType="date"
          format="dd MMM yyyy"
          visible={false}
          editorOptions={{ useMaskBehavior: true, disabled: !isAdmin }}
        />
        <Column
          dataField="professional.graduationDate"
          caption="Graduation date"
          alignment="center"
          dataType="date"
          format="dd MMM yyyy"
          visible={false}
          editorOptions={{ useMaskBehavior: true, disabled: !isAdmin }}
        />
        <Column
          dataField="professional.lastPromotion"
          caption="Last promotion"
          alignment="center"
          dataType="date"
          format="dd MMM yyyy"
          visible={false}
          editorOptions={{ useMaskBehavior: true, disabled: !isAdmin }}
        />
        <Column
          dataField="professional.lastRangeAdjustment"
          caption="Last range adjustment"
          alignment="center"
          dataType="date"
          format="dd MMM yyyy"
          editorOptions={{ disabled: !isAdmin }}
          visible={false}
        />

        <Column
          dataField="idProfessional"
          caption="Professional"
          alignment="left"
          allowSorting={false}
          cellRender={(e) => (
            <ProfessionalLookup
              data={e.data?.professional}
              name={e.data?.professional?.name}
              style={{ cursor: "pointer" }}
            />
          )}
          editorOptions={{ disabled: !isAdmin }}
        >
          <Lookup
            dataSource={masterContext.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField="idJobTitle"
          caption="Job Title"
          alignment="center"
          editorOptions={{ disabled: !isAdmin }}
          // sortingMethod={(a, b) => a.PDSRanking - b.PDSRanking}
          allowSorting={false}
        >
          <Lookup
            dataSource={jobTitleSource}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column dataField="PDSRanking" visible={false} />
        <Column
          dataField="currentGradeRange"
          caption="Current Grade Range (%)"
          alignment="center"
          visible={false}
          editorOptions={{ disabled: !isAdmin }}
        />
        <Column
          dataField="expectedGradeRange"
          caption="Expected Grade Range (%)"
          alignment="center"
          visible={false}
          editorOptions={{ disabled: !isAdmin && !isCorpLeader }}
        />
        <Column
          dataField="currentGrade"
          caption="Current Grade"
          alignment="center"
          visible={false}
          dataType="number"
          editorOptions={{ disabled: !isAdmin }}
        />
        <Column
          dataField="expectedGrade"
          caption="Expected Grade"
          alignment="center"
          visible={false}
          dataType="number"
          editorOptions={{ disabled: !isAdmin && !isCorpLeader }}
        />
        <Column
          dataField="idPractice"
          caption="Practice"
          alignment="center"
          editorOptions={{ disabled: !isAdmin }}
        >
          <Lookup
            dataSource={masterContext.practices}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField="idOffice"
          caption="Office"
          alignment="center"
          cellRender={(e) => (
            <OfficeIcon
              office={e.data?.professional?.offices[0]?.name ?? null}
              className="office-icon"
            />
          )}
          editorOptions={{ disabled: !isAdmin }}
        >
          <Lookup
            dataSource={masterContext.offices}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField="idMentor"
          caption="Mentor"
          visible={false}
          editorOptions={{ disabled: true }}
        >
          <Lookup
            dataSource={masterContext.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField="isRecommended"
          width={170}
          alignment="center"
          caption="Recommendation"
          cellRender={(e) =>
            e.value && (
              <Tooltip title="Recommended professional">
                <img
                  style={{ height: "1.5rem" }}
                  src={RecommendationIcon}
                  alt={e.value ? "Recommended" : "Not Recommended"}
                />
              </Tooltip>
            )
          }
        />
        <Column
          dataField="isActive"
          caption="Is Active?"
          visible={false}
          formItem={{ colSpan: 4 }}
          editorOptions={{ disabled: !isAdmin }}
        />
        <Column
          dataField="textRecommendation"
          caption="Text Recommendation"
          visible={false}
          editorType="dxTextArea"
          editorOptions={{ height: 180 }}
          formItem={{ colSpan: 4 }}
        />
      </DataGrid>
    </Container>
  );
}
