import React from "react";
import { Container, GridContainer } from "./styles";
import { DataGrid } from "devextreme-react";
import {
  Column,
  HeaderFilter,
  Lookup,
  Paging,
  Sorting,
} from "devextreme-react/data-grid";
import { useCallback } from "react";
import CustomStore from "devextreme/data/custom_store";
import api from "../../../services/api";
import DataSource from "devextreme/data/data_source";
import { useState } from "react";
import { useEffect } from "react";
import { StatusCell } from "../../EvaluationList/styles";
import PhotoCell from "../../EvaluationList/CellRender/PhotoCell";
import { IconButton } from "@material-ui/core";
import { FiExternalLink } from "react-icons/fi";
import { useHistory } from "react-router-dom";

export default function EvaluationCycle({ promotion, isSidePanelFullScreen }) {
  const history = useHistory();
  const [evaluationsSource, setPromotionSource] = useState([]);
  const loadEvaluations = useCallback(async () => {
    const store = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get(
          `api/evaluations/${promotion.idProfessional}/all`
        );
        return data;
      },
    });

    setPromotionSource(
      new DataSource({
        store: store,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [promotion.idProfessional]);

  const [cycles] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/cycles");
        const cyclesWithDisplayName = data.map((cycle) => ({
          ...cycle,
          displayName: `${cycle.period}Q - ${cycle.year}`,
        }));

        return cyclesWithDisplayName;
      },
    }),
    paginate: true,
  });

  const [evaluationStatuses] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/evaluation-status");
        return data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    loadEvaluations();
  }, [loadEvaluations]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <GridContainer>
        <DataGrid
          dataSource={evaluationsSource}
          height="100%"
          columnAutoWidth
          allowColumnResizing
          onRowClick={(e) => window.open(`/allevaluations/${e.key}`, "_blank")}
        >
          <Paging defaultPageSize={10} enabled />
          <HeaderFilter visible allowSearch />
          <Sorting mode="multiple" />
          <Column
            dataField="evaluationStatus.id"
            caption="Status"
            cellRender={(e) => (
              <StatusCell status={e.data.evaluationStatus.id}>
                <PhotoCell data={promotion.professional} />
                {e.data.evaluationStatus.description}
              </StatusCell>
            )}
            width={230}
            alignment="left"
          >
            <Lookup
              dataSource={evaluationStatuses}
              valueExpr="id"
              displayExpr="description"
            />
          </Column>
          <Column
            dataField="cycle.id"
            caption="Cycle"
            cellRender={(e) => {
              return ` ${e.data.cycle.period}Q — ${e.data.cycle.year}`;
            }}
            alignment="center"
          >
            <Lookup
              dataSource={cycles}
              valueExpr="id"
              displayExpr="displayName"
            />
          </Column>

          <Column dataField="totalAverage" caption="Grade" alignment="center" />
          <Column
            alignment="center"
            width={50}
            cellRender={(e) => {
              return (
                <IconButton
                  onClick={() => {
                    window.open(`/allevaluations/${e.key}`, "_blank");
                  }}
                >
                  <FiExternalLink size={14} />
                </IconButton>
              );
            }}
          />
        </DataGrid>
      </GridContainer>
    </Container>
  );
}
