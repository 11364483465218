import React from "react";
import BrazilIcon from "../../assets/images/brazil-icon.svg";
import UKIcon from "../../assets/images/uk-icon.svg";
import ChileIcon from "../../assets/images/chile-icon.svg";
import ArgentinaIcon from "../../assets/images/argentina-icon.svg";
import MexicoIcon from "../../assets/images/mexico-icon.svg";
import GermanyIcon from "../../assets/images/germany-icon.svg";
import USAIcon from "../../assets/images/usa-icon.svg";
import { Tooltip } from "@material-ui/core";
import NoIcon from "../../assets/images/no-icon.svg";

export default function OfficeIcon({ className, office }) {
  const officeIcons = {
    brazil: BrazilIcon,
    uk: UKIcon,
    chile: ChileIcon,
    argentina: ArgentinaIcon,
    germany: GermanyIcon,
    mexico: MexicoIcon,
    usa: USAIcon,
    us: USAIcon,
  };
  const Icon = office ? officeIcons[office.toLowerCase()] : NoIcon;

  return (
    <Tooltip title={office ?? "N/A"}>
      <div>
        <img className={className} src={Icon} alt={office} />
      </div>
    </Tooltip>
  );
}
