import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div:first-child {
    display: flex;
    gap: 20px;
  }
`;
