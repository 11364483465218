import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
`;