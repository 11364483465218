import {
  CircularProgress,
  IconButton,
  Popover,
  Tooltip,
} from "@material-ui/core";
import React, { useCallback, useContext, useState } from "react";
import { IoIosSkipForward } from "react-icons/io";
import Button from "../../../../components/Button";
import { TriggerContext } from "../../../../context/TriggerProvider";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";

export default function DialogProjectStatus({ counselingData }) {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { addToast } = useToast();
  const { triggerFunction } = useContext(TriggerContext);

  const handleChangeStatusConfirm = useCallback(async () => {
    try {
      setLoading(true);

      await api.put(`api/counseling/${counselingData.id}`, {
        idCounselingStatus: 3,
      });
      triggerFunction();
    } catch (err) {
      addToast({
        type: "error",
        title: "Something went wrong when changing status.",
      });
      throw err;
    } finally {
      handleClose();
      setLoading(false);
    }
  }, [addToast, counselingData.id, triggerFunction]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Change status">
        <IconButton size="small" onClick={handleClick}>
          <span>
            <IoIosSkipForward color="#8b0304" />
          </span>
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className="popup-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            gap: "20px",
            minWidth: "200px",
          }}
        >
          Complete counseling?
          <Button onClick={handleChangeStatusConfirm} primary>
            {!loading ? (
              "Confirm"
            ) : (
              <CircularProgress size={16} color="inherit" />
            )}
          </Button>
        </div>
      </Popover>
    </>
  );
}
