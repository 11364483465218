import styled from "styled-components";

export const PhotoGray = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  filter: grayscale(1) brightness(0.95);
  border-radius: 50%;
  &:hover {
    filter: brightness(0.95);
  }
`;
