import React, { useCallback } from "react";
import { format, parseISO } from "date-fns";
import TimelineItem from "../TimelineItem";
import { useParams } from "react-router-dom";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { Container } from "./styles";
import { useAuth } from "../../../hooks/auth";

export default function Talks({ talks, isSidePanelFullScreen, isAdmin }) {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { id } = useParams();

  const handleTalkEdit = useCallback(
    async (talkId, comment, key) => {
      try {
        await api.put(`api/tutorship/${id}/talks/${talkId}`, {
          [key]: comment,
        });
        addToast({
          type: "success",
          title: "Comment edited.",
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong...",
        });
        throw err;
      }
    },
    [id, addToast]
  );

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {talks.length <= 0 ? (
        "No talks added yet."
      ) : (
        <>
          {talks.map((talk) => (
            <div className="conversation" key={talk.id}>
              <div className="conversation-date">
                {talk.conversationDate &&
                  format(parseISO(talk.conversationDate), "dd MMM yyyy")}
              </div>
              {(talk.professionalTutor.idProfessional === user.userId ||
                isAdmin) && (
                <TimelineItem
                  comment={talk.comment}
                  professionalId={talk.professionalTutor.idProfessional}
                  handleTalkEdit={(comment) =>
                    handleTalkEdit(talk.id, comment, "comment")
                  }
                  reverse
                />
              )}
              {(talk.professionalTutor.idTutor === user.userId || isAdmin) && (
                <TimelineItem
                  disabled={
                    !isAdmin && talk.professionalTutor.idTutor !== user.userId
                  }
                  comment={talk.tutoredComment}
                  professionalId={talk.professionalTutor.idTutor}
                  handleTalkEdit={(comment) =>
                    handleTalkEdit(talk.id, comment, "tutoredComment")
                  }
                />
              )}
              {isAdmin && (
                <TimelineItem
                  disabled={!isAdmin}
                  comment={talk.pdcComment}
                  handleTalkEdit={(comment) =>
                    handleTalkEdit(talk.id, comment, "pdcComment")
                  }
                />
              )}
            </div>
          ))}
        </>
      )}
    </Container>
  );
}
