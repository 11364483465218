import React, { useState, useCallback, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { TextArea, DateBox } from "devextreme-react";
import { WhisperSpinner } from "react-spinners-kit";
import { Container } from "./styles";
import Button from "../../Button";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { TriggerContext } from "../../../context/TriggerProvider";
import { useAuth } from "../../../hooks/auth";
import { useMemo } from "react";
import pdsGroups from "../../../config/pdsGroups";

export default function DialogAddTalk({ open, setOpenAddTalk, id, tutorship }) {
  const { hasScope, user } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [tutoredComment, setTutorComment] = useState("");
  const [pdcComment, setPDCComment] = useState("");
  const [error, setError] = useState(null);
  const [conversationDate, setConversationDate] = useState("");
  const { triggerFunction } = useContext(TriggerContext);

  const handleClose = useCallback(() => {
    setOpenAddTalk(false);
  }, [setOpenAddTalk]);

  const handleAddConversation = useCallback(async () => {
    if (!conversationDate) {
      setError("Selection is required");
      return;
    } else {
      try {
        setLoading(true);
        const data = {
          conversationDate,
          comment,
          tutoredComment,
          pdcComment,
        };
        await api.post(`api/tutorship/${id}/talks`, data);

        addToast({
          type: "success",
          title: "Added with success.",
        });
        triggerFunction();
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong...",
        });
      } finally {
        setLoading(false);
      }
    }

    handleClose();
  }, [
    conversationDate,
    handleClose,
    comment,
    tutoredComment,
    pdcComment,
    id,
    addToast,
    triggerFunction,
  ]);

  return (
    <>
      {loading && (
        <div>
          <WhisperSpinner size={30} backColor="#8b0304" frontColor="#fff" />
        </div>
      )}
      <Dialog
        onClose={() => {
          setOpenAddTalk(false);
        }}
        open={open}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Talk</DialogTitle>
        <DialogContent>
          <Container>
            <div
              style={{
                display: "block",
              }}
            >
              <div
                style={{
                  display: "grid",
                  marginBottom: "12px",
                }}
              >
                <label>Conversation Date</label>
                <DateBox
                  openOnFieldClick
                  onValueChange={(e) => {
                    setConversationDate(e);
                  }}
                  max={new Date()}
                  displayFormat="dd MMM yyyy"
                />
                {error && (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {error}
                  </p>
                )}
              </div>
            </div>
            {(isAdmin || tutorship?.idProfessional === user.userId) && (
              <div style={{ marginBottom: "12px" }}>
                <label>Conversation comments</label>
                <TextArea
                  className="styled-textarea"
                  style={{ backgroundColor: "#EFEFEF" }}
                  onValueChange={(e) => setComment(e)}
                />
              </div>
            )}
            {(isAdmin || tutorship?.idTutor === user.userId) && (
              <div style={{ marginBottom: "12px" }}>
                <label>Tutor comments</label>
                <TextArea
                  className="styled-textarea"
                  style={{ backgroundColor: "#EFEFEF" }}
                  onValueChange={(e) => setTutorComment(e)}
                />
              </div>
            )}
            {isAdmin && (
              <div style={{ marginBottom: "12px" }}>
                <label>PDC comments</label>
                <TextArea
                  className="styled-textarea"
                  style={{ backgroundColor: "#EFEFEF" }}
                  onValueChange={(e) => setPDCComment(e)}
                />
              </div>
            )}
          </Container>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button primary onClick={handleAddConversation}>
            Save
          </Button>
          <Button onClick={() => setOpenAddTalk(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
