import React from "react";

import { WhisperSpinner } from "react-spinners-kit";

import { Container } from "./styles";

export default function Button({
  children,
  primary,
  secondary,
  disabled,
  loading = false,
  ...rest
}) {
  return (
    <Container
      {...rest}
      primary={primary && !loading}
      secondary={secondary}
      disabled={loading || disabled}
    >
      {children}
      {loading && (
        <WhisperSpinner size={15} backColor="#8b0304" frontColor="#fff" />
      )}
    </Container>
  );
}
