import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RadioGroupWrapper } from "./styles";

export default function RadioGroup({
  options,
  selectedOption,
  onChange,
  style,
  disabled,
}) {
  const [uniqueID] = useState(uuidv4());

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <RadioGroupWrapper style={style}>
      {options.map((option, index) => (
        <div key={index}>
          <input
            type="radio"
            name={`radio-group-${uniqueID}`}
            value={option.value}
            checked={selectedOption === option.value}
            onChange={(e) => handleChange(e)}
            disabled={option.disabled || disabled}
          />

          <span>{option.label}</span>
        </div>
      ))}
    </RadioGroupWrapper>
  );
}
