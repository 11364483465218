import styled from "styled-components";

export const Box = styled.div`
  cursor: pointer;
  background-color: #fff;
  border-radius: 7px;
  gap: 10px;
  padding: 10px 20px;
  width: 100%;
  min-width: 270px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 30px 0px #0000001a;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const Headline = styled.p`
  font-family: Roboto Condensed;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: bold;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: 700;
  color: #ccc;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
