import React, { useCallback, useState, useEffect, useMemo } from "react";

import { WhisperSpinner } from "react-spinners-kit";
import CustomStore from "devextreme/data/custom_store";
import { SelectBox, CheckBox, TextArea } from "devextreme-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import ProfessionalLookup from "../../../../components/ProfessionalLookup";
import Button from "../../../../components/Button";
import api from "../../../../services/api";
import master from "../../../../services/master";

import { Label, Fieldset, LoadingContainer } from "./styles";

import { useToast } from "../../../../hooks/toast";
import { useAuth } from "../../../../hooks/auth";
import pdsGroups from "../../../../config/pdsGroups";

export default function DialogSeniorsMentee({
  statusId,
  idEvaluation,
  idMentee,
  open,
  handleClose,
  onChanged,
}) {
  const [professionalSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await master.get("master/professionals", {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });

  const { hasScope, user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [menteeEvaluation, setMenteeEvaluation] = useState("");
  const [doYouFeelComfortable, setDoYouFeelComfortable] = useState(false);
  const [isApplicable, setIsApplicable] = useState(false);

  const loadEvaluation = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `api/evaluations/${idEvaluation}/mentee/${idMentee}`
    );
    setDoYouFeelComfortable(data.doYouFeelComfortable);
    setMenteeEvaluation(data.menteeEvaluation);
    setIsApplicable(data.isApplicable);
    setLoading(false);
  }, [idEvaluation, idMentee]);

  useEffect(() => {
    loadEvaluation();
  }, [loadEvaluation]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await api.put(`api/evaluations/${idEvaluation}/mentee/${idMentee}`, {
        menteeEvaluation,
        doYouFeelComfortable,
        isApplicable,
      });

      addToast({
        type: "success",
        title: "Saved",
      });
      setLoading(false);

      onChanged();
      handleClose();
    } catch (err) {
      addToast({
        type: "error",
        title: "Ops",
        description: "Ops... Something went wrong on edit",
      });
    }

    setLoading(false);
  }, [
    idEvaluation,
    idMentee,
    menteeEvaluation,
    doYouFeelComfortable,
    isApplicable,
    addToast,
    onChanged,
    handleClose,
  ]);

  const statusNames = useMemo(
    () => ({
      Draft: 1,
      WaitingDiscussion: 3,
      Completed: 5,
      Excluded: 6,
    }),
    []
  );

  const allowEdit = useMemo(() => {
    return (
      hasScope([pdsGroups.Admin]) ||
      (statusId === statusNames.Draft && idMentee === user.userId)
    );
  }, [hasScope, idMentee, statusId, statusNames.Draft, user.userId]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Edit</DialogTitle>
      <DialogContent>
        {loading && (
          <LoadingContainer>
            <WhisperSpinner
              size={20}
              color="#8b0304"
              backColor="#8b0304"
              frontColor="#fff"
            />
          </LoadingContainer>
        )}

        {!loading && (
          <>
            {hasScope([pdsGroups.Admin]) && (
              <>
                <Fieldset>
                  <Label>Mentee</Label>
                  <SelectBox
                    dataSource={professionalSource}
                    displayExpr="Name"
                    valueExpr="ID"
                    placeholder="Select a professional"
                    itemRender={(data) => (
                      <ProfessionalLookup
                        name={data.Name}
                        login={data.Login}
                        jobtitle={data.JobTitle.Name}
                      />
                    )}
                    value={idMentee}
                    readOnly
                  />
                </Fieldset>

                <Fieldset>
                  <CheckBox
                    value={isApplicable}
                    onValueChange={(e) => setIsApplicable(e)}
                    className="checkbox"
                    style={{ marginRight: 10 }}
                    readOnly={!allowEdit}
                  />
                  <Label className="label">Considered</Label>
                </Fieldset>

                <hr />
              </>
            )}

            <Fieldset>
              <CheckBox
                value={doYouFeelComfortable}
                onValueChange={(e) => setDoYouFeelComfortable(e)}
                className="checkbox"
                style={{ marginRight: 10 }}
                readOnly={!allowEdit}
              />
              <Label className="label">
                Do you feel comfortable to evaluate this professional? (If you
                don't, uncheck the box)
              </Label>
            </Fieldset>

            <Fieldset>
              <Label>
                "Has the professional contributed as a mentor to your
                professional development during this cycle? Please give
                examples."
              </Label>
              <TextArea
                defaultValue={menteeEvaluation}
                onValueChange={(e) => setMenteeEvaluation(e)}
                style={{ height: 200, backgroundColor: "#efefef" }}
                readOnly={!allowEdit}
              />
            </Fieldset>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button primary disabled={loading} onClick={handleSubmit}>
          Save
          {loading && (
            <WhisperSpinner size={15} backColor="#8b0304" frontColor="#fff" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
