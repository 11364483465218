import styled, { css } from "styled-components";

const statusesColors = {
  1: "#cccccc",
  2: "#d15c56",
  3: "#edce61",
  4: "#52b9d9",
  5: "#4cb56f",
  6: "#333333",
};

export const TopMenu = styled.div`
  ${(props) => css`
    background-color: ${statusesColors[props.status]};
  `};
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 10px 20px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  flex: 0 0 85px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    filter: saturate(0) brightness(0.95);
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
  }

  .evaluators {
    display: flex;
    gap: 10px;
    & > div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .tools {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;

    & > div {
      cursor: pointer;
      mix-blend-mode: overlay;

      :hover {
        opacity: 0.5;
      }
      :active {
        mix-blend-mode: normal;
      }
    }
  }

  .profile {
    display: flex;
    padding: 15px 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    background-color: white;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    position: absolute;
    top: 45px;
    left: 20px;
    width: calc(100% - 40px);
    z-index: 10;

    h2 {
      font-size: 1rem;
      font-weight: bold;
      color: #333333;
    }

    h3 {
      color: #999;
      font-weight: 300;

      & > b {
        font-weight: bold;
      }
    }

    & > div {
      display: flex;
      gap: 10px;
    }

    img.professional {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      filter: brightness(0.95);
    }

    img.office-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      filter: brightness(0.95);
    }

    .profile-info {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: center;
      gap: 20px;

      & > path {
        stroke: #999;
      }
    }
  }
`;

export const MatrixCriteria = styled.div`
  .matrix {
    border: 2px solid #909497;
    border-radius: 5px;
    border-collapse: collapse;
    width: 100%;
  }
  .matrix__header {
    background-color: #8b0305;
    color: #fff;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    padding: 1rem;
    border: 2px solid #909497;
  }
  .matrix__first-column {
    background-color: #d7dbdd;
    color: #000;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    padding: 1rem;
    border: 2px solid #909497;
  }
  .tr,
  td {
    border: 2px solid #909497;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
    padding: 1rem;
  }
`;

export const DialogTitleContainer = styled.div`
  .dialog__title {
    text-align: center;
    padding: 0;
    font-weight: bold 800;
    font-size: 10 vw;
    color: #8b0305;
    -webkit-text-stroke: 0.25px #909497;
  }
`;
