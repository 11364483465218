import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import SidePanelLoader from "../ContentLoader";
import SidePanelHeader from "./Header";
import DoDontTab from "./Tabs/DoDontTab";
import Skills from "./Tabs/Skills";
import { Container } from "./styles";

export default function DoDontSidePanel({
  isSidePanelOpen,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  path,
}) {
  const { id } = useParams();
  const [doDont, setDoDont] = useState();
  const [skillTypes, setSkillTypes] = useState();
  const [skills, setSkills] = useState();
  const [doDontIsLoading, setDoDontIsLoading] = useState(true);
  const { addToast } = useToast();

  const loadDoDont = useCallback(async () => {
    try {
      setDoDontIsLoading(true);
      const doDontResponse = await api.get(`/api/dodont/${id}`);
      const skillTypesResponse = await api.get(`/api/skill-type`);
      const skillsResponse = await api.get(`/api/skill`, {
        params: {
          idProfessional: doDontResponse.data.idProfessional,
        },
      });
      setSkills(skillsResponse.data);
      setDoDont(doDontResponse.data);
      setSkillTypes(skillTypesResponse.data);
      setSkills(skillsResponse.data);
      return doDontResponse.data; // return the data
    } catch (err) {
      addToast({
        type: "error",
        title: "Something went wrong on loading Do's and Don'ts",
      });
      throw err;
    } finally {
      setDoDontIsLoading(false);
    }
  }, [addToast, id]);

  useEffect(() => {
    setIsSidePanelOpen(true);
  }, [setIsSidePanelOpen]);

  useEffect(() => {
    loadDoDont();
  }, [loadDoDont]);

  const [activeTab, setActiveTab] = useState(0);
  const skillsTab = useMemo(() => {
    return skillTypes && skills
      ? skillTypes
          .filter((skillType) => skillType.isActive)
          .map((skillType) => ({
            label: skillType.description,
            content: (
              <Skills
                isSidePanelFullScreen={isSidePanelFullScreen}
                skills={skills}
                skillType={skillType}
                doDont={doDont}
                reload={loadDoDont}
              />
            ),
          }))
      : [];
  }, [skillTypes, skills, isSidePanelFullScreen, doDont, loadDoDont]);

  const tabs = useMemo(() => {
    return [
      {
        label: "Do's and Don'ts",
        content: (
          <DoDontTab
            isSidePanelFullScreen={isSidePanelFullScreen}
            doDont={doDont}
            doDontIsLoading={doDontIsLoading}
            reload={loadDoDont}
          />
        ),
      },
      ...skillsTab,
    ];
  }, [isSidePanelFullScreen, doDont, doDontIsLoading, loadDoDont, skillsTab]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {doDontIsLoading ? (
        <SidePanelLoader width="100%" />
      ) : (
        <>
          <SidePanelHeader
            isSidePanelOpen={isSidePanelOpen}
            setIsSidePanelOpen={setIsSidePanelOpen}
            isSidePanelFullScreen={isSidePanelFullScreen}
            setIsSidePanelFullScreen={setIsSidePanelFullScreen}
            doDont={doDont}
            path={path}
          />

          {!isSidePanelFullScreen && (
            <div className="tabs-menu">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={activeTab === index ? "active" : ""}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  {tab.label}
                </div>
              ))}
            </div>
          )}

          <div
            className={`content-wrapper ${
              !isSidePanelFullScreen ? "no-scroll" : ""
            }`}
          >
            {tabs.map((tab, index) => (
              <div
                key={index}
                className="content"
                style={{
                  display: !isSidePanelFullScreen
                    ? activeTab === index
                      ? ""
                      : "none"
                    : "",
                }}
              >
                {isSidePanelFullScreen && (
                  <h2 className="separator">{tab.label}</h2>
                )}
                {tab.content}
              </div>
            ))}
          </div>
        </>
      )}
    </Container>
  );
}
