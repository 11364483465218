import styled, { css } from "styled-components";

const statusesColors = {
  1: "#cccccc",
  2: "#d15c56",
  3: "#4cb56f",
};

export const Container = styled.div`
  position: relative;
  height: 90%;

  /* display: flex; */
  & > div {
    box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
    border-radius: 7px;
    /* overflow: hidden; */
  }
  .dx-datagrid {
    color: #333;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
    border: none;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    color: #999;
    font-size: 0.75rem;
    font-weight: 300;
  }

  .dx-datagrid-rowsview .dx-row {
    cursor: pointer;

    :hover {
      background-color: #f5f5f5ff;
    }
    :active {
      background-color: #d9d9d9ff;
    }
  }

  .dx-datagrid-rowsview .dx-row.dx-freespace-row {
    cursor: default;

    :hover {
      background-color: transparent;
    }
    :active {
      background-color: transparent;
    }
  }

  .dx-datagrid .dx-row > td {
    font-size: 0.75rem;
    font-weight: 400;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #f5f5f5;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-data-row > td {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 0;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0 0 30px rgba(0 0 0 / 15%);
    background: #8b0304;
    color: white;

    :hover {
      background: #b80304;
    }
    :active {
      background: #a80304;
    }
  }
`;

export const Photo = styled.img`
  max-width: 48px;
  border-radius: 50%;
`;

export const PhotoGray = styled.img`
  max-width: 48px;
  border-radius: 50%;
  filter: grayscale(1);

  &:hover {
    filter: none;
  }
`;

export const YearCell = styled.div`
  font-size: 20px;
  color: #8b0304;
  font-weight: 700;
`;

// export const StatusCell = styled.div`
//   width: 75px;
//   position: absolute;
//   transform: rotate(-90deg);
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   text-align: center;

//   hr {
//     margin-top: 0;
//     border-width: 5px;
//     ${(props) => css`
//       border-color: ${statusesColors[props.status]};
//     `}
//   }

//   p {
//     font-weight: 700;
//     ${(props) => css`
//       color: ${statusesColors[props.status]};
//     `}
//   }
// `;

export const StatusCell = styled.div`
  ${(props) => css`
    background-color: ${statusesColors[props.status]};
  `};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 300px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  gap: 5px;

  & img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 3px;
    filter: grayscale(1) brightness(0.95);
  }
`;

export const StatusActivity = styled.div`
  p {
    font-weight: 700;
    ${(props) => css`
      color: ${statusesColors[props.isFilled ? 3 : 2]};
    `}
  }
`;
