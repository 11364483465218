import styled from 'styled-components';
import { Drawer } from '@material-ui/core';

export const Container = styled(Drawer)`
  position: relative;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
`;

export const LegacyCard = styled.div`
  width: 100%;
  background-color: #efefef;
  padding: 18px;
  margin: 15px 0;
  cursor: pointer;
  color: #999999;
  font-weight: 700;
`;

export const Alert = styled.div`
  display: flex;
  padding: 6px 16px;
  font-size: 14px;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  color: rgb(13, 60, 97);
  background-color: rgb(232, 244, 253);
  align-items: center;

  span {
    font-weight: 700;
  }

  svg {
    display: flex;
    opacity: 0.9;
    margin-right: 12px;
  }
`;