import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { FiInfo } from "react-icons/fi";
import { GrContract, GrExpand, GrFormClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import NoIcon from "../../../assets/images/no-icon.svg";
import { ListContainer } from "../../../pages/DoDont/List/styles";
import Button from "../../Button";
import OfficeIcon from "../../OfficeIcons";
import { TopMenu } from "./styles";

export default function SidePanelHeader({
  doDont,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  path,
  setIsSidePanelOpen,
}) {
  const history = useHistory();
  const [openDoDontInfoDialog, setOpenDoDontInfoDialog] = useState(false);
  const handleOpenDoDontInfoDialog = useCallback(() => {
    setOpenDoDontInfoDialog(true);
  });
  const handleCloseDoDontInfoDialog = useCallback(() => {
    setOpenDoDontInfoDialog(false);
  });

  return (
    <>
      <TopMenu status={doDont?.hasDont === "Yes" ? 2 : false}>
        <>
          <div className="status">Dos and Don'ts | Personal Info</div>
          <div className="tools">
            <div onClick={() => setIsSidePanelFullScreen((x) => !x)}>
              {!isSidePanelFullScreen ? (
                <GrExpand size={12} />
              ) : (
                <GrContract size={12} />
              )}
            </div>
            <div
              onClick={() => {
                history.push("/" + path + "/");
                setIsSidePanelFullScreen(false);
                setIsSidePanelOpen(false);
              }}
            >
              <GrFormClose size={20} />
            </div>
          </div>
        </>
        <div className="profile">
          <div>
            <img
              className="professional"
              src={
                doDont?.professional?.login
                  ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${doDont?.professional?.login}_192_192.png`
                  : NoIcon
              }
              alt={doDont?.professional?.name}
            />
            <div>
              <div>
                <h2>{doDont?.professional?.name}</h2>
                <div className="profile-info">
                  <h3>
                    {doDont?.professional?.jobTitle.name} -{" "}
                    {doDont?.professional?.practices[0].name}
                  </h3>

                  <OfficeIcon
                    className="office-icon"
                    office={doDont?.professional?.offices[0].name.toLowerCase()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-wrapper">
            <FiInfo
              style={{ color: "#8b0304", fontSize: "18px", cursor: "pointer" }}
              onClick={handleOpenDoDontInfoDialog}
            />

            <Dialog
              open={openDoDontInfoDialog}
              onClose={handleCloseDoDontInfoDialog}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>Do's and Don'ts Information</DialogTitle>

              <DialogContent>
                <ListContainer>
                  <div>
                    <p class="text__do__dont">
                      Professionals can access the tool through Integration’s
                      Intranet. They can write their needs (Dos and Don’ts) and
                      add personal information, such as passport/citizenship,
                      country/visa, number of days spent in other countries (for
                      bi taxation / tax resident issues), languages and possible
                      exclusivity agreements with clients.
                    </p>
                    <ul class="lista__externa">
                      <li>
                        Do's: are needs that a professional may have in a
                        specific point of their career. For instance (and these
                        are not exhaustive):
                        <ol class="lista__interna">
                          <li>
                            A technical and/or methodological qualification.
                          </li>
                          <li>Experience in a specific country.</li>
                          <li>Participation in a project outside the base.</li>
                          <li>
                            A change in the composition and/or size of a project
                            team.
                          </li>
                          <li>A project that requires travel.</li>
                          <li>Experience of a project in another practice.</li>
                          <li>Work time adjustments for a period of time.</li>
                          <li>
                            Experience a process from other corporate areas.
                          </li>
                          <li>
                            Have experience working in other Business Units (in
                            corporate areas).
                          </li>
                        </ol>
                      </li>

                      <li>
                        Don’ts: are things that a professional does not want in
                        a specific point of their career. For instance (and
                        these are not exhaustive):
                        <ol class="lista__interna">
                          <li>Work in a particular methodology.</li>
                          <li>Work in a specific industry.</li>
                          <li>Participation in a project outside the base.</li>
                          <li>Work with a specific professional.</li>
                          <li>Experience of a project in another practice.</li>
                        </ol>
                      </li>
                    </ul>
                  </div>
                </ListContainer>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleCloseDoDontInfoDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </TopMenu>
    </>
  );
}
