import { format, parseISO } from "date-fns";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import pdsGroups from "../../config/pdsGroups";
import { ValueContext } from "../../context/ValueProvider";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import DialogAddConversation from "../../pages/Mentorint/DialogProfessionalTalksDetails/DialogAddConversation";
import api from "../../services/api";
import SidePanelLoader from "../ContentLoader";
import SidePanelHeader from "./Header";
import TimelineItem from "./TimelineItem";
import { Container } from "./styles";

export default function SidePanelMentorInt({
  isSidePanelFullScreen,
  setIsSidePanelOpen,
  setIsSidePanelFullScreen,
  path,
  listData,
}) {
  const { id } = useParams();
  const history = useHistory();

  const [conversations, setConversations] = useState([]);
  const [openAddConversation, setOpenAddConversation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToast();
  const { master } = useContext(ValueContext);
  const { user, hasScope } = useAuth();

  const isAdmin = useMemo(() => {
    return hasScope([pdsGroups.Admin]);
  }, [hasScope]);

  const loadTalks = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(`api/professional-mentor/${id}/talks`);

      const sortedData = data.sort((a, b) => {
        const dateA = new Date(a.conversationDate);
        const dateB = new Date(b.conversationDate);

        return dateB - dateA;
      });

      setConversations(sortedData);
      setIsLoading(false);
    } catch (err) {
      addToast({
        type: "error",
        title: "Something went wrong on loading data",
      });
      history.push("/" + path + "/");
      setIsSidePanelFullScreen(false);
      setIsSidePanelOpen(false);
      throw err;
    }
  }, [
    addToast,
    history,
    id,
    path,
    setIsSidePanelFullScreen,
    setIsSidePanelOpen,
  ]);

  useEffect(() => {
    setIsSidePanelOpen(true);
    loadTalks();
  }, [id, loadTalks, setIsSidePanelOpen]);

  const handleConversationEdit = useCallback(
    async (conversationId, comment, key) => {
      try {
        await api.put(`api/professional-mentor/${id}/talks/${conversationId}`, {
          [key]: comment,
        });
        addToast({
          type: "success",
          title: "Comment edited.",
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong...",
        });
        throw err;
      }
    },
    [addToast, id]
  );

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {isLoading || !master.professionals ? (
        <SidePanelLoader width="100%" />
      ) : (
        <>
          <SidePanelHeader
            isSidePanelFullScreen={isSidePanelFullScreen}
            setIsSidePanelFullScreen={setIsSidePanelFullScreen}
            setIsSidePanelOpen={setIsSidePanelOpen}
            conversation={conversations[0]}
            listData={listData}
            path={path}
            setOpenAddConversation={setOpenAddConversation}
            master={master}
          />
          <div
            className={`content-wrapper ${
              !isSidePanelFullScreen ? "no-scroll" : ""
            }`}
          >
            {conversations.length <= 0
              ? "No conversations added yet."
              : conversations.map((conversation, index) => {
                  const isMentor =
                    listData.professionalMentor.idMentor === user.userId;
                  const isProfessional =
                    conversation.professionalMentor.idProfessional ===
                    user.userId;

                  if (!isAdmin && !isProfessional && !isMentor) {
                    return null;
                  }

                  return (
                    <div className="conversation" key={index}>
                      <div className="conversation-date">
                        {isAdmin && (
                          <>
                            {conversation.wasGood ? (
                              <FiCheckCircle size={25} color="#15d448" />
                            ) : (
                              <FiAlertCircle size={25} color="#f1c40f" />
                            )}
                          </>
                        )}
                        {conversation.conversationDate &&
                          format(
                            parseISO(conversation.conversationDate),
                            "dd MMM yyyy"
                          )}
                      </div>
                      <TimelineItem
                        comment={conversation.comment}
                        professionalId={
                          conversation.professionalMentor.idMentor
                        }
                        handleConversationEdit={(comment) =>
                          handleConversationEdit(
                            conversation.id,
                            comment,
                            "comment"
                          )
                        }
                        reverse
                        visible={isAdmin || isMentor}
                      />
                      <TimelineItem
                        comment={conversation.mentoredComment}
                        professionalId={
                          conversation.professionalMentor.idProfessional
                        }
                        handleConversationEdit={(comment) =>
                          handleConversationEdit(
                            conversation.id,
                            comment,
                            "mentoredComment"
                          )
                        }
                        visible={isAdmin || isProfessional}
                      />
                    </div>
                  );
                })}
          </div>
        </>
      )}
      {openAddConversation && (
        <DialogAddConversation
          open={openAddConversation}
          setOpenAddConversation={setOpenAddConversation}
          loadTalks={loadTalks}
          id={id}
        />
      )}
    </Container>
  );
}
