import React, { useEffect, useState } from "react";

import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import api from "../../../services/api";
import { DataGridWrapper } from "../styles";

export default function AdminDoDontVaccine() {
  const [vaccineSource, setVaccineSource] = useState();

  useEffect(() => {
    const vaccineStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/vaccines");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/vaccines", data);
      },
      update: async (id, data) => {
        await api.put(`/api/vaccines/${id}`, data);
      },
    });

    setVaccineSource(
      new DataSource({
        store: vaccineStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, []);

  return (
    <DataGridWrapper>
      <DataGrid
        height={550}
        dataSource={vaccineSource}
        showBorders={false}
        key="id"
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="id" allowEditing={false} visible={false} />
        <Column dataField="name" caption="Description" />
        <Column dataField="isActive" dataType="boolean" />
      </DataGrid>
    </DataGridWrapper>
  );
}
