import React, { useState, useCallback, useEffect } from "react";

import { TagBox as TagBoxDev } from "devextreme-react/tag-box";
import { Container } from "./styles";

export const TagBox = ({
  value: defaultValue,
  onChanged,
  onAdd,
  onRemove,
  readOnly: _readOnly,
  ...rest
}) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(defaultValue || []);
  }, [defaultValue]);

  const handleChange = useCallback(
    (e) => {
      const newValue = e.value;
      const intersectionAdd = newValue.filter((x) => !value.includes(x));
      const intersectionDelete = value.filter((x) => !newValue.includes(x));
      setValue(e.value);
      if (onChanged) onChanged(e.value);

      if (onAdd && intersectionAdd.length > 0) onAdd(intersectionAdd[0]);

      if (onRemove && intersectionDelete.length > 0)
        onRemove(intersectionDelete[0]);
    },
    [onChanged, value, onAdd, onRemove]
  );

  return (
    <Container>
      <TagBoxDev
        value={value}
        onValueChanged={handleChange}
        multiline
        {...rest}
      />
    </Container>
  );
};
