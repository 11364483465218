import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import "devextreme/dist/css/dx.common.css";
import "./assets/custom.css";
import "./assets/dx.material.integration.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const rootElement = document.getElementById("root");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#8b0304",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ["Roboto Condensed", "sans-serif"].join(","),
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Routes />
  </MuiThemeProvider>,
  rootElement
);
