import React, { useCallback, useState } from "react";
import { Drawer, Box, Typography } from "@material-ui/core";

import { WhisperSpinner } from "react-spinners-kit";
import Upload from "../../../../components/Upload";
import Button from "../../../../components/Button";
import FileList from "./FileList";
import api from "../../../../services/api";

import { useToast } from "../../../../hooks/toast";

import { LoadingContainer } from "./styles";

export default function DialogImport({ open, handleClose, onSubmitted }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesRaw, setFilesRaw] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const submitFile = useCallback((files) => {
    const filesProps = files.map((file) => ({
      file,
      name: file.name,
      readableSize: file.size.toString(),
    }));

    setFilesRaw(files);

    setUploadedFiles(filesProps);
  }, []);

  const handleSubmit = useCallback(async () => {
    const data = new FormData();

    if (filesRaw.length <= 0)
      return addToast({
        type: "error",
        title: "Please, select a file",
      });

    filesRaw.map((fileRaw) => {
      data.append("file", fileRaw, fileRaw.name);
    });

    try {
      setLoading(true);
      setLoadingMessage("Importing... This could take a while");
      await api.post(`api/counseling-import`, data);

      addToast({
        type: "success",
        title: "Imported with success",
      });
      handleClose();
      if (onSubmitted) onSubmitted();
    } catch (err) {
      addToast({
        type: "error",
        title: "Error on import",
      });
    } finally {
      setLoading(false);
      setLoadingMessage(null);
      setFilesRaw([]);
      setUploadedFiles([]);
    }
  }, [filesRaw, uploadedFiles, addToast, handleClose, onSubmitted]);

  const handleDownloadTemplate = useCallback(async () => {
    const response = await api.post(`api/counseling-import/template`, {
      responseType: "blob",
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `template.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  const handleDownloadProfessionalsTemplate = useCallback(async () => {
    const response = await api.post(`api/counseling-import/professionals`, {
      responseType: "blob",
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `professionals.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: "relative" }}
    >
      {loading && (
        <LoadingContainer>
          <WhisperSpinner size={50} color="#8b0304" backColor="#8b0304" />
        </LoadingContainer>
      )}

      <Box m={2} minWidth={300}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: "15px" }}
        >
          Import CSV
        </Typography>

        <Typography variant="subtitle2" component="p">
          To import your file please, follow the template below
        </Typography>

        <Button
          variant="text"
          fullWidth
          style={{ width: "100%", marginBottom: 15 }}
          onClick={handleDownloadTemplate}
        >
          Download template
        </Button>

        <Button
          variant="text"
          fullWidth
          style={{ width: "100%", marginBottom: 15 }}
          onClick={handleDownloadProfessionalsTemplate}
        >
          Download professionals template
        </Button>

        <div style={{ marginBottom: 15 }}>
          <Upload onUpload={submitFile} />
        </div>

        {!!uploadedFiles.length && <FileList files={uploadedFiles} />}

        <div style={{ position: "absolute", bottom: 15, right: 15 }}>
          <Button onClick={handleSubmit} primary style={{ marginRight: 15 }}>
            Import
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Box>
    </Drawer>
  );
}
