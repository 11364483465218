import React from "react";
import { Tooltip } from "@material-ui/core";
import NoIcon from "../../../assets/images/no-icon.svg";
import { PhotoGray } from "./styles";

export default function PhotoCell({ data }) {
  const { login, name, Login, Name } = data ?? {};
  const photo =
    login || Login
      ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${
          login || Login
        }_192_192.png`
      : NoIcon;

  return (
    <Tooltip title={name || Name || "N/A"} placement="top">
      <PhotoGray src={photo} />
    </Tooltip>
  );
}
