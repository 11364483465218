import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import DataGrid, {
  Column,
  Editing,
  Button as GridButton,
  Lookup,
  RowDragging,
  Scrolling,
  Sorting,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../../../components/Button";
import api from "../../../../../services/api";
import DialogPermission from "../DialogPermission";

export default function CompetenceSeniorGrid({
  open,
  handleClose,
  competenceName,
  idCompetence: _idCompetence,
}) {
  const [competenceSource, setCompetenceSource] = useState();
  const [competenceStore, setCompetenceStore] = useState();
  const [idCompetence] = useState(() => _idCompetence);
  const [name] = useState(() => competenceName);
  const [openPermission, setOpenPermission] = useState(false);
  const [selectedKey, setSelectedKey] = useState(0);

  useEffect(() => {
    const competenceStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/competences-senior", {
          params: {
            idCompetence,
          },
        });
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/competences-senior", {
          ...data,
          idCompetence,
        });
      },
      update: async (id, data) => {
        await api.put(`/api/competences-senior/${id}`, data);
      },
      remove: async (id) => {
        await api.delete(`/api/competences-senior/${id}`);
      },
    });

    setCompetenceStore(competenceStore);

    setCompetenceSource(
      new DataSource({
        store: competenceStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [idCompetence]);

  const handleDrag = useCallback(
    async (e) => {
      await competenceStore.update(e.itemData.id, { order: e.toIndex + 1 });
      await e.component.refresh();
    },
    [competenceStore]
  );

  const percentCell = useCallback((e) => <p>{e.value}%</p>, []);

  const handleOpenPermission = useCallback((e) => {
    setOpenPermission(true);
    setSelectedKey(e.row.key);
  }, []);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          <DataGrid dataSource={competenceSource} showBorders={false} key="id">
            <RowDragging
              allowReordering
              onReorder={handleDrag}
              showDragIcons
              dropFeedbackMode="push"
            />
            <Scrolling mode="virtual" />
            <Sorting mode="none" />
            <Editing mode="row" allowAdding allowUpdating />
            <Column dataField="id" allowEditing={false} visible={false} />
            <Column dataField="description" />
            <Column dataField="order" visible={false} />
            <Column
              dataField="isNumeric"
              caption="Is a numeric field?"
              dataType="boolean"
            />
            <Column
              dataField="isText"
              caption="Has a text field?"
              dataType="boolean"
            />
            <Column
              dataField="isGrade"
              caption="Is a grade field?"
              dataType="boolean"
            />
            <Column
              dataField="isReadOnly"
              caption="Is read-only?"
              dataType="boolean"
            />
            <Column dataField="isActive" dataType="boolean" />
            <Column
              dataField="hasParent"
              caption="Has parent?"
              dataType="boolean"
            />
            <Column dataField="idParent" caption="Parent">
              <Lookup
                dataSource={competenceStore}
                displayExpr="description"
                valueExpr="id"
              />
            </Column>
            <Column dataField="idCompetence" visible={false} />
            <Column dataField="tooltip" />
            <Column dataField="fieldWeight" cellRender={percentCell} />

            <Column type="buttons">
              <GridButton name="edit" />
              <GridButton
                hint="Permissions"
                icon="key"
                onClick={handleOpenPermission}
              />
            </Column>

            <Summary>
              <TotalItem
                column="fieldWeight"
                summaryType="sum"
                displayFormat="Sum: {0}%"
              />
            </Summary>
          </DataGrid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {openPermission && (
        <DialogPermission
          open={openPermission}
          handleClose={() => setOpenPermission(false)}
          id={selectedKey}
          type="idCompetenceSenior"
        />
      )}
    </>
  );
}
