import React, { useEffect, useState } from "react";

import { DataGridWrapper } from "../../styles";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import api from "../../../../services/api";
import { TextArea } from "devextreme-react";
import { FiEdit2, FiExternalLink } from "react-icons/fi";
import { IconButton, Tooltip } from "@material-ui/core";
import { useCallback } from "react";
import Button from "../../../../components/Button";
import DialogAddConclusion from "./DialogAddConclusion";
import DialogEditConclusion from "./DialogEditConclusion";

export default function CounselingConclusions() {
  const [counselingConclusionsSource, setCounselingConclusionsSource] =
    useState();
  const [openAddConclusion, setOpenAddConclusion] = useState(false);
  const [openEditConclusion, setOpenEditConclusion] = useState(false);
  const [editConclusionId, setEditConclusionId] = useState(0);

  const loadConclusions = useCallback(async () => {
    const conclusionsStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("/api/counseling-conclusions");
        return data;
      },
    });

    setCounselingConclusionsSource(
      new DataSource({
        store: conclusionsStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, []);

  useEffect(() => {
    const counselingConclusionStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("/api/counseling-conclusions");
        return data;
      },
      insert: async (data) => {
        await api.post("/api/counseling-conclusions", data);
      },
      update: async (id, data) => {
        await api.put(`/api/counseling-conclusions/${id}`, data);
      },
      remove: async (id) => {
        await api.delete(`/api/counseling-conclusions/${id}`);
      },
    });

    setCounselingConclusionsSource(counselingConclusionStore);

    setCounselingConclusionsSource(
      new DataSource({
        store: counselingConclusionStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, []);

  const [actionFields] = useState({
    store: new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/action-fields");
        return data;
      },
    }),
    paginate: true,
  });

  const commandColumnRender = useCallback((e) => {
    let key = e.key;
    return (
      <>
        <Tooltip title="Open Conclusion" aria-label="open" placement="left">
          <IconButton
            aria-label="Open Conclusion"
            size="small"
            onClick={(e) => {
              setOpenEditConclusion(true);
              setEditConclusionId(key);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }, []);

  return (
    <DataGridWrapper>
      <Button primary onClick={() => setOpenAddConclusion(true)}>
        ADD CONCLUSION
      </Button>
      <DataGrid
        height={550}
        dataSource={counselingConclusionsSource}
        key="id"
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <Column dataField="title" width={200} />
        <Column
          dataField="answer"
          cellRender={(e) => {
            return <TextArea value={e.value} readOnly />;
          }}
        />
        <Column dataField="isActive" dataType="boolean" width={80} />
        <Column dataField="idActionField" caption="Action Field" width={200}>
          <Lookup
            dataSource={actionFields}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="decision"
          dataType="boolean"
          width={120}
          cellRender={(e) => {
            return <p>{e.value === true ? "Approve" : "Not Approve"}</p>;
          }}
        />
        <Column
          type="buttons"
          cellRender={commandColumnRender}
          width={100}
        ></Column>
      </DataGrid>

      {openAddConclusion && (
        <DialogAddConclusion
          open={openAddConclusion}
          handleClose={() => {
            loadConclusions();
            setOpenAddConclusion(false);
          }}
        />
      )}
      {openEditConclusion && (
        <DialogEditConclusion
          open={openEditConclusion}
          handleClose={() => {
            loadConclusions();
            setOpenEditConclusion(false);
          }}
          conclusionId={editConclusionId}
        />
      )}
    </DataGridWrapper>
  );
}
