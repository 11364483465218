import styled from "styled-components";
import { Drawer } from "@material-ui/core";

export const Container = styled(Drawer)`
  position: relative;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
`;
export const Label = styled.label`
  font-weight: 700;
`;
export const Fieldset = styled.fieldset`
  margin-bottom: 32px;
`;
export const Confirmation = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
`;
