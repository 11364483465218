import DataGrid, { Column, Lookup } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import api from "../../services/api";
import PhotoCell from "./CellRender/PhotoCell";
import { Container, StatusCell } from "./styles";

import { ValueContext } from "../../context/ValueProvider";
import { useToast } from "../../hooks/toast";
import DialogSeniorProject from "../../pages/Evaluation/Seniors/DialogSeniorProject";
import DialogSeniorsMentee from "../../pages/Evaluation/Seniors/DialogSeniorsMentee";
import { useAuth } from "../../hooks/auth";
import pdsGroups from "../../config/pdsGroups";

export default function EvaluationToDos({ path }) {
  const { hasScope, user } = useAuth();
  const { addToast } = useToast();
  const [openEditProject, setOpenEditProject] = useState(false);
  const [openEditMentee, setOpenEditMentee] = useState(false);

  const [projectEditInfo, setProjectEditInfo] = useState([]);
  const [projectEditId, setProjectEditId] = useState(0);
  const [areaTypes, setAreaTypes] = useState([]);
  const { master } = useContext(ValueContext);

  const getAreaTypes = useCallback(async () => {
    const { data } = await api.get(`api/area-types`);

    setAreaTypes(data);
  }, []);

  const [cycles] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/cycles");
        const cyclesWithDisplayName = data.map((cycle) => ({
          ...cycle,
          displayName: `(${cycle.areaType?.description}) ${cycle.period}Q - ${cycle.year}`,
        }));

        return cyclesWithDisplayName.sort(
          (a, b) => b.areaType?.description - a.areaType?.description
        );
      },
    }),
    paginate: true,
  });

  const dataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        const { data } = await api.get("api/evaluations/todos");
        return data;
      },

      loadMode: "raw",
      cacheRawData: true,
    });
  }, [getAreaTypes]);

  const dataGridRef = useRef(null);

  const handleRowClick = useCallback((e) => {
    if (e.data.isProject) {
      setProjectEditInfo(e.data);
      setProjectEditId(e.data.id);
      setOpenEditProject(true);
    } else {
      setProjectEditInfo(e.data);
      setOpenEditMentee(true);
    }
  }, []);

  const isFilledRender = useCallback((e) => {
    let isFilled = false;
    if ((e.data.isPartner && e.data.fieldValue) || e.data.fieldText) {
      isFilled = true;
    }

    if (
      e.data.evaluation &&
      e.data.evaluation.evaluationSeniorMentees &&
      e.data.evaluation.evaluationSeniorMentees.length > 0
    ) {
      const menteeEvaluation = e.data.evaluation.evaluationSeniorMentees.find(
        (mentee) => mentee.idMentee === e.data.idEvaluator
      );
      if (
        menteeEvaluation &&
        menteeEvaluation.menteeEvaluation &&
        menteeEvaluation.menteeEvaluation !== ""
      ) {
        isFilled = true;
      }
    }
    return (
      <StatusCell status={isFilled}>
        <PhotoCell data={e.data.professional} />
        {isFilled ? "Done" : "Pending"}
      </StatusCell>
    );
  }, []);

  return (
    <Container>
      <h2
        style={{
          fontWeight: 600,
          marginBottom: "1em",
          fontSize: "1.5rem",
        }}
      >
        Inputs for senior evaluation
      </h2>
      <DataGrid
        key="id"
        dataSource={dataSource}
        ref={dataGridRef}
        height="80%"
        headerFilter={{ visible: true, allowSearch: true }}
        onRowClick={handleRowClick}
        columnAutoWidth
        sorting={{ mode: "multiple" }}
        onToolbarPreparing={(e) => {
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
              text: "Clear Filters",
              elementAttr: { class: "clear-filter" },
              icon: "filter",
              onClick: () => {
                e.component.clearFilter();
              },
            },
          });
        }}
      >
        <Column
          dataField="isFilled"
          allowFiltering={false}
          caption="Status"
          cellRender={isFilledRender}
          width={230}
          alignment="left"
          allowEditing={true}
        />
        <Column
          dataField="professional.id"
          caption="Professional"
          alignment="left"
          dataType="number"
          width={130}
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField="idProject"
          caption="Project"
          dataType="number"
          editorType="dxSelectBox"
          cellRender={(e) =>
            e && e.data.projectCode && e.data.projectName
              ? e.data.projectCode
              : ""
          }
        >
          <Lookup
            dataSource={master.projects}
            editorType="dxSelectBox"
            displayExpr={(item) =>
              item && item.ProjectCode && item.ProjectName
                ? `${item.ProjectCode} - ${item.ProjectName}`
                : ""
            }
            valueExpr="ID"
          />
        </Column>

        <Column
          dataField="idEvaluator"
          caption="Evaluator"
          cellRender={(e) => <PhotoCell data={e.data.evaluator} />}
          defaultFilterValues={hasScope(pdsGroups.Admin) ? [user.userId] : null}
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          dataField="isProject"
          caption="Project?"
          headerFilter={{
            dataSource: [
              { text: "Yes", value: true },
              { text: "No", value: false },
            ],
          }}
        />
        <Column
          dataField="isApplicable"
          caption="Considered?"
          headerFilter={{
            dataSource: [
              { text: "Yes", value: true },
              { text: "No", value: false },
            ],
          }}
        />

        <Column
          dataField="evaluation.idCycle"
          caption="Cycle"
          editorType="dxSelectBox"
          cellRender={(e) =>
            `${e.data.evaluation.cycle.period}Q — ${e.data.evaluation.cycle.year}`
          }
        >
          <Lookup
            dataSource={cycles}
            displayExpr="displayName"
            valueExpr="id"
            placeholder="Select cycle"
            searchExpr={["year"]}
            minSearchLength={2}
            required={true}
            showDataBeforeSearch={true}
            searchEnabled={true}
          />
        </Column>
      </DataGrid>
      {openEditProject && (
        <DialogSeniorProject
          evaluationId={projectEditInfo.idEvaluation}
          id={projectEditInfo.id}
          open={openEditProject}
          handleClose={() => setOpenEditProject(false)}
          onChanged={() => dataSource.reload()}
          idAreaType={projectEditInfo?.evaluation?.idAreaType}
        />
      )}
      {openEditMentee && (
        <DialogSeniorsMentee
          handleClose={() => setOpenEditMentee(false)}
          open={openEditMentee}
          statusId={projectEditInfo?.evaluation?.idEvaluationStatus}
          idEvaluation={projectEditInfo.idEvaluation}
          idMentee={projectEditInfo?.idEvaluator}
          onChanged={() => {
            setOpenEditMentee(false);
            dataSource.reload();
          }}
        />
      )}
    </Container>
  );
}
