import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  input[type="checkbox"] {
    width: 1em;
    height: 1em;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    font-weight: 700;
    color: #333;
    line-height: 1;
  }
`;
