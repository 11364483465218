import React, { useCallback, useMemo, useState } from "react";
import { Container } from "./styles";
import TextArea from "../../TextArea";
import RadioGroup from "../../RadioGroup/";
import useUpdateField from "../../../hooks/useUpdateField";
import { useAuth } from "../../../hooks/auth";
import pdsGroups from "../../../config/pdsGroups";

export default function Conclusion({
  evaluation,
  idEvaluation,
  isSidePanelFullScreen,
}) {
  const { updateField } = useUpdateField();
  const { user, hasScope } = useAuth();

  const options = useMemo(
    () => [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
    []
  );

  const [alreadyClear, setAlreadyClear] = useState(
    evaluation?.evaluationFeedback?.alreadyClear?.toString()
  );

  const [agreeWithPoints, setAgreeWithPoints] = useState(
    evaluation?.evaluationFeedback?.agreeWithPoints?.toString()
  );
  const [evaluationReflects, setEvaluationReflects] = useState(
    evaluation?.evaluationFeedback?.evaluationReflects?.toString()
  );

  const disabled =
    ((evaluation.evaluationStatus.id !== 4 ||
      user.userId !== evaluation.idProfessional) &&
      !hasScope(pdsGroups.Admin)) ||
    evaluation.isLocked === true;
  console.log(evaluation.isLocked);

  const handleRadioChange = useCallback(
    (selectedOption, key) => {
      updateField(
        "patch",
        `/api/evaluations/${idEvaluation}/feedback/`,
        {
          [key]: selectedOption,
        },
        "updateText"
      );
    },
    [idEvaluation, updateField]
  );

  const handleTextChange = useCallback(
    (value, key) => {
      updateField(
        "patch",
        `/api/evaluations/${idEvaluation}/feedback/`,
        {
          [key]: value,
        },
        "updateText"
      );
    },
    [idEvaluation, updateField]
  );

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      <div>
        <h3 className="conclusion">
          Were the points in this evaluation already clear through online
          feedback?
        </h3>
        <RadioGroup
          options={options}
          selectedOption={alreadyClear}
          onChange={(selectedOption) => {
            setAlreadyClear(selectedOption);
            handleRadioChange(selectedOption, "alreadyClear");
          }}
          disabled={disabled}
        />
        <TextArea
          caption="Comments (optional)"
          value={evaluation?.evaluationFeedback?.alreadyClearText}
          onChanged={(value) => handleTextChange(value, "alreadyClearText")}
          disabled={disabled}
        />
      </div>
      <div>
        <h3 className="conclusion">
          Do you agree with the points and intensity that were included in the
          evaluation?
        </h3>
        <RadioGroup
          options={options}
          selectedOption={agreeWithPoints}
          onChange={(selectedOption) => {
            setAgreeWithPoints(selectedOption);
            handleRadioChange(selectedOption, "agreeWithPoints");
          }}
          disabled={disabled}
        />
        <TextArea
          caption="Comments (optional)"
          value={evaluation?.evaluationFeedback?.agreeWithPointsText}
          onChanged={(value) => handleTextChange(value, "agreeWithPointsText")}
          disabled={disabled}
        />
      </div>
      <div>
        <h3 className="conclusion">
          Do you think your evaluation reflects the competence matrix for your
          level?
        </h3>
        <RadioGroup
          options={options}
          selectedOption={evaluationReflects}
          onChange={(selectedOption) => {
            setEvaluationReflects(selectedOption);
            handleRadioChange(selectedOption, "evaluationReflects");
          }}
          disabled={disabled}
        />
        <TextArea
          caption="Comments (optional)"
          value={evaluation?.evaluationFeedback?.evaluationReflectsText}
          onChanged={(value) =>
            handleTextChange(value, "evaluationReflectsText")
          }
          disabled={disabled}
        />
      </div>
    </Container>
  );
}
