import React, { useCallback, useState } from "react";
import Button from "../../../components/Button";
import { TagBox } from "../../../components/TagBox";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import TextArea from "../../TextArea";
import { Container } from "./styles";

export default function Conclusion({
  isSidePanelFullScreen,
  counselingData,
  activeCounselingConclusions,
  isAdmin,
}) {
  const [conclusionKeyMessage, setConclusionKeyMessage] = useState(
    counselingData.conclusionKeyMessage
  );
  const [counselingDefaultAnswers, setCounselingDefaultAnswers] = useState([]);

  const { addToast } = useToast();

  const handleChangeConclusion = useCallback(
    async (value) => {
      try {
        const data = {
          idCounselingStatus: counselingData.idCounselingStatus,
          idProfessional: counselingData.professional.id,
          idCounselor: counselingData.idCounselor,
          year: counselingData.year,
          conclusionKeyMessage: value,
        };

        await api.put(`api/counseling/${counselingData.id}`, data);

        addToast({
          type: "success",
          title: "Saved",
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong when saving data.",
        });
        throw err;
      }
    },
    [
      addToast,
      counselingData.id,
      counselingData.idCounselingStatus,
      counselingData.idCounselor,
      counselingData.professional.id,
      counselingData.year,
    ]
  );

  const handleApplyDefaultConclusions = useCallback(() => {
    let text = conclusionKeyMessage;
    counselingDefaultAnswers.forEach((x) => (text += "\n\n" + x));
    setConclusionKeyMessage(text);
    handleChangeConclusion(text);
  }, [conclusionKeyMessage, counselingDefaultAnswers, handleChangeConclusion]);

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {isAdmin && counselingData.idCounselingStatus === 1 && (
        <div className="default-answers" style={{ padding: 20 }}>
          <h2>Default Answers</h2>

          <TagBox
            searchEnabled
            dataSource={activeCounselingConclusions}
            onValueChange={(e) => setCounselingDefaultAnswers(e)}
            displayExpr={(item) => {
              const newTitle = (item?.decision ? "👍 " : "👎 ") + item?.title;
              return newTitle;
            }}
            valueExpr="answer"
          />
          <Button primary onClick={handleApplyDefaultConclusions}>
            Apply
          </Button>
        </div>
      )}
      <TextArea
        caption="Conclusion Key Message"
        className="textAreaCounseling"
        value={conclusionKeyMessage}
        onChanged={(value) => handleChangeConclusion(value)}
        readOnly={!isAdmin || counselingData.idCounselingStatus !== 1}
      />
    </Container>
  );
}
