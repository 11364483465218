import {
  CircularProgress,
  IconButton,
  Popover,
  Tooltip,
} from "@material-ui/core";
import { SelectBox } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { IoIosSkipForward } from "react-icons/io";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";
import TextArea from "../../../components/TextArea";
import { TriggerContext } from "../../../context/TriggerProvider";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/auth";

export default function DialogProjectStatus({ evaluation }) {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [statusId, setStatusId] = useState(evaluation?.idEvaluationStatus);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { triggerFunction } = useContext(TriggerContext);
  const { id } = useParams();

  // STATUS
  //   Draft: 1,
  //   WaitingValidation: 2,
  //   WaitingDiscussion: 3,
  //   WaitingComments: 4,
  //   Completed: 5,
  //   Excluded: 6,
  //   Reproved: 8,

  const statuses = useMemo(() => {
    return new CustomStore({
      loadMode: "raw",
      key: "id",
      load: async () => {
        let { data } = await api.get("/api/evaluation-status", {
          params: {
            idEvaluation: id,
          },
        });

        if (user.userId !== evaluation?.idProfessional)
          data.push({ id: 8, description: "Reproved" });
        return data;
      },
    });
  }, [evaluation, id, user.userId]);

  const handleOpenSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  const handleChangeStatusConfirm = useCallback(async () => {
    try {
      if (
        5 === statusId &&
        evaluation?.evaluationFeedback?.alreadyClear === undefined &&
        evaluation?.evaluationFeedback?.agreeWithPoints === undefined &&
        evaluation?.evaluationFeedback?.evaluationReflects === undefined &&
        evaluation?.professional?.isActive
      ) {
        return addToast({
          type: "error",
          title: "Please fill conclusion area",
        });
      }

      if (statusId === evaluation?.idEvaluationStatus) {
        return addToast({
          type: "error",
          title: "Please change the status",
        });
      }
      setLoading(true);
      await api.patch(`/api/evaluations/${id}/status`, {
        IDEvaluationStatus: statusId === 8 ? 1 : statusId,
        IsReproved: statusId === 8,
      });
      triggerFunction();
    } catch (err) {
      return addToast({
        type: "error",
        title: err,
      });
    } finally {
      handleCloseSelect();
      setLoading(false);
    }
  }, [addToast, evaluation, id, statusId, triggerFunction]);

  const handleChangeTextArea = useCallback(
    async (field, value) => {
      await api.put(`/api/evaluations/${id}`, {
        [field]: value,
      });
      triggerFunction();
    },
    [id, triggerFunction]
  );

  return (
    <>
      <Tooltip title="Change status">
        <IconButton size="small" onClick={handleOpenSelect}>
          <IoIosSkipForward color="#8b0304" />
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseSelect}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className="popup-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            gap: "20px",
            minWidth: "300px",
          }}
        >
          <h2 style={{ fontWeight: "bold" }} id="evaluation-status-label">
            Evaluation Status
          </h2>
          <SelectBox
            id="evaluation-status-select"
            dataSource={statuses}
            displayExpr="description"
            valueExpr="id"
            value={statusId}
            onValueChanged={(e) => {
              setStatusId(e.value);
            }}
          />

          {(8 === statusId || evaluation?.isReproved) && (
            <TextArea
              onChanged={(value) =>
                handleChangeTextArea("adjustmentComment", value)
              }
              value={evaluation?.adjustmentComment}
              caption="Adjustment comments"
              disabled={statusId !== 8}
            />
          )}

          <Button onClick={handleChangeStatusConfirm} primary>
            {!loading ? (
              "Confirm"
            ) : (
              <CircularProgress size={16} color="inherit" />
            )}
          </Button>
        </div>
      </Popover>
    </>
  );
}
