import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: 20px;
  /* padding: 30px; */
  overflow: hidden;
  z-index: 9999;
`;
