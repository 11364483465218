import { Tooltip } from "@material-ui/core";
import { SelectBox } from "devextreme-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiHelpCircle } from "react-icons/fi";
import { ValueContext } from "../../../context/ValueProvider";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import CustomCheckbox from "../../Checkbox/index.js";
import TextArea from "../../TextArea";
import { Container } from "./styles";

const ActionField = ({ actionField, counselingData, allowEdit }) => {
  const { master } = useContext(ValueContext);
  const { addToast } = useToast();

  const findActionFieldContent = useCallback(
    (actionField) => {
      return counselingData.counselingActionField?.find(
        (counselingActionField) =>
          counselingActionField.idActionField === actionField.id
      );
    },
    [counselingData.counselingActionField]
  );
  const [selectedFields, setSelectedFields] = useState(() => {
    const fieldText2 = findActionFieldContent(actionField)?.["fieldText2"];
    return fieldText2 ? fieldText2.split(";") : [];
  });

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (selectedFields.length > 0) {
      handleCounselingActionFieldChange(
        actionField.id,
        "fieldText2",
        selectedFields.join(";")
      );
    }
  }, [selectedFields]);

  const [checked, setChecked] = useState(
    actionField.isCheckbox
      ? findActionFieldContent(actionField)?.fieldValue === "true"
      : true // if it's not a checkbox, it's a text field, so it's always true
  );

  const handleCounselingActionFieldChange = useCallback(
    async (idActionField, type, value) => {
      try {
        const data = {
          [type]: value,
        };

        await api.patch(
          `/api/counseling/${counselingData.id}/action-fields/${idActionField}`,
          data
        );

        addToast({
          type: "success",
          title: "Saved",
        });
      } catch (err) {
        addToast({
          type: "error",
          title: "Something went wrong when saving data.",
        });
        throw err;
      }
    },
    [counselingData.id, addToast]
  );

  const getOptions = useCallback(
    (listType) => {
      switch (listType) {
        case "PRACTICES":
          const officeDictionary = {
            Mexico: "LATAM – México",
            Argentina: "LATAM – Argentina",
            Chile: "LATAM – Chile",
            Germany: "EUROPE – Germany",
            UK: "EUROPE – UK",
            Chicago: "USA – Chicago",
            "Finance & Management": "BRAZIL – F&M",
            Implementation: "BRAZIL – IMP",
            "Leadership & Organization": "BRAZIL – L&O",
            "Knowledge Management": "CORP – Knowledge Management",
            "Supply Chain": "BRAZIL – SCH",
            "Marketing & Sales": "BRAZIL – M&S",
            "SI&I": "CORP – SI&I",
            "Tech & Digital": "CC – Tech & Digital",
            "ID One AC": "CC – ID One AC",
            Sustainability: "CC – Sustainability",
          };
          const practicesNames = master.practices
            ?.filter((p) => p.IsActive)
            .map((p) => officeDictionary[p.Name] || p.Name);
          const practicesSorted = practicesNames ? practicesNames.sort() : [];
          return practicesSorted;
        case "OFFICES":
          const officeNames = master.offices?.map((o) => o.Name);
          return officeNames;
        case "GOVERNANCE":
          return master.governanceAreas?.map((g) => g.Name);
        default:
          return [];
      }
    },
    [master.practices, master.offices, master.governanceAreas]
  );

  const tooltipComponent = useCallback((tooltipText) => {
    return tooltipText ? (
      <Tooltip title={tooltipText} placement="top">
        <span style={{ marginLeft: 5 }}>
          <FiHelpCircle color="#CCC" size="1em" />
        </span>
      </Tooltip>
    ) : (
      ""
    );
  }, []);

  return (
    <div key={actionField.id} className="action-field">
      {actionField.isCheckbox && (
        <CustomCheckbox
          id={actionField.id}
          style={{
            flexDirection: "row-reverse",
            justifyContent: "flex-end",
            gap: 7,
          }}
          label={actionField?.description}
          checked={findActionFieldContent(actionField)?.fieldValue === "true"}
          disabled={!allowEdit}
          onChange={(value) => {
            setChecked(Boolean(value));
            handleCounselingActionFieldChange(
              actionField.id,
              "fieldValue",
              value
            );
          }}
        >
          {tooltipComponent(actionField.tooltip)}
        </CustomCheckbox>
      )}

      {actionField.isList && (
        <div className="list">
          {actionField.listPlaceholder.split(";").map((placeholder, index) => {
            // if there is more than one fieldText2, separate them by ";" using the placeholders and render a select box for each one. Also updates the fieldText2 value on change by joining the values with ";" again
            const fieldText2 =
              findActionFieldContent(actionField)?.["fieldText2"];
            const fieldText2Parts = fieldText2 ? fieldText2.split(";") : [];

            return (
              <React.Fragment key={index}>
                {placeholder}
                <SelectBox
                  sort="asc"
                  dataSource={getOptions(actionField.listType)}
                  defaultValue={fieldText2Parts[index] || ""}
                  disabled={!checked || !allowEdit}
                  onValueChanged={(e) => {
                    const newValue = e.value;
                    fieldText2Parts[index] = newValue;
                    setSelectedFields((prevFields) => {
                      const newFields = [...prevFields];
                      newFields[index] = newValue;
                      return newFields;
                    });
                  }}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}

      {actionField.isText && (
        <>
          {!actionField.isCheckbox && !actionField.isList && (
            <h2>
              {actionField?.description + " "}
              {tooltipComponent(actionField.tooltip)}
            </h2>
          )}

          <TextArea
            caption={"Comments"}
            value={findActionFieldContent(actionField)?.["fieldText1"]}
            onChanged={(value) =>
              handleCounselingActionFieldChange(
                actionField.id,
                "fieldText1",
                value
              )
            }
            disabled={!checked || !allowEdit}
          />
        </>
      )}
    </div>
  );
};

export default function ActionFields({
  counselingData,
  actionFields,
  isSidePanelFullScreen,
  allowEdit,
}) {
  // Renders checkboxes, text or lists regarding the type of content in the action field
  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {actionFields.map((actionField) => (
        <ActionField
          key={actionField.id}
          actionField={actionField}
          counselingData={counselingData}
          actionFields={actionFields}
          allowEdit={allowEdit}
        />
      ))}
    </Container>
  );
}
