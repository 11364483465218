import React, { useState, useEffect, useCallback } from "react";

import CustomStore from "devextreme/data/custom_store";
import { DataGrid } from "devextreme-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import Button from "../../../../../components/Button";
import api from "../../../../../services/api";
import { Column, Editing, Export } from "devextreme-react/data-grid";
import { exportDataGrid } from "devextreme/excel_exporter";
import { ContainerLoading } from "./styles";
import { WhisperSpinner } from "react-spinners-kit";
import { useToast } from "../../../../../hooks/toast";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";

export default function DialogRefreshEvaluations({
  open,
  handleClose,
  areaTypeId,
}) {
  const [loading, setLoading] = useState(false);
  const [openGrid, setOpenGrid] = useState(false);
  const [missingStore, setMissingStore] = useState(null);
  const { addToast } = useToast();

  const handleRefreshEvaluations = useCallback(async () => {
    setLoading(true);

    if (areaTypeId === 2) {
      try {
        const { data } = await api.post(`/api/cycles/current/refresh`);

        if (data && data.missing && data.missing.length > 0) {
          setOpenGrid(true);

          setMissingStore({
            store: new CustomStore({
              key: "idPortalEvaluation",
              loadMode: "raw",
              load: async () => {
                return data.missing.map((data) => {
                  const missing = [];

                  if (data.idEvaluator === 0) missing.push("evaluator");
                  if (data.idValidator === 0) missing.push("validator");
                  if (data.idPartner === 0) missing.push("partner");

                  return {
                    ...data,
                    missingData: `Missing: ${missing.join(", ")}`,
                  };
                });
              },
            }),
            paginate: true,
          });
        } else {
          addToast({
            type: "success",
            title: "Success!",
          });
          handleClose();
          return;
        }
      } catch {
        setOpenGrid(false);
        addToast({
          type: "error",
          title: "Something went wrong!",
        });
      }
    } else {
      try {
        const { data } = await api.post(
          `/api/cycles/current/senior-refresh/${areaTypeId}`
        );
        addToast({
          type: "success",
          title: "Success!",
        });
      } catch {
        addToast({
          type: "error",
          title: "Something went wrong!",
        });
      }
    }
    setLoading(false);
  }, [addToast, areaTypeId, handleClose]);

  const handleExport = useCallback((e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
    e.cancel = true;
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      style={{ textAlign: "center" }}
      maxWidth="lg"
    >
      {areaTypeId === 2 ||
      areaTypeId === 3 ||
      areaTypeId === 4 ||
      areaTypeId === 5 ||
      areaTypeId === 9 ? (
        <>
          <DialogTitle>Refresh evaluations</DialogTitle>
          <DialogContent>
            {loading && (
              <ContainerLoading>
                <WhisperSpinner
                  size={20}
                  color="#8b0304"
                  backColor="#8b0304"
                  frontColor="#fff"
                />
              </ContainerLoading>
            )}
            {openGrid && (
              <>
                <span>Some evaluations are missing data in Project Portal</span>
                <DataGrid
                  dataSource={missingStore}
                  showBorders={false}
                  onExporting={handleExport}
                  height="100%"
                  width="100%"
                >
                  <Editing mode="row" />
                  <Column dataField="idPortalEvaluation" visible={false} />
                  <Column dataField="projectCode" />
                  <Column dataField="professionalName" />
                  <Column dataField="projectManagerName" />
                  <Column dataField="totalDays" />
                  <Column
                    dataField="startDate"
                    dataType="date"
                    format="dd/MMM/yyyy"
                  />
                  <Column
                    dataField="endDate"
                    dataType="date"
                    format="dd/MMM/yyyy"
                  />
                  <Column dataField="missingData" allowFiltering={false} />

                  <Export enabled />
                </DataGrid>
              </>
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleRefreshEvaluations} primary>
              Refresh all evaluations
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      ) : (
        <p>Only consultancy type can refresh evaluations</p>
      )}
    </Dialog>
  );
}
