import React, { useMemo, useContext } from "react";
import { GrExpand, GrContract, GrFormClose } from "react-icons/gr";
import NoIcon from "../../../assets/images/no-icon.svg";
import { TopMenu } from "./styles";
import OfficeIcon from "../../OfficeIcons";
import { useHistory, useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { ValueContext } from "../../../context/ValueProvider";
import Button from "../../Button";

export default function SidePanelHeader({
  listData,
  path,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  setOpenAddTalk,
  userId,
  isAdmin,
}) {
  const { master } = useContext(ValueContext);
  const history = useHistory();
  const professional = useMemo(() => {
    return master.professionals?.find((x) => x.ID === listData?.id);
  }, [listData, master]);

  const professionalTutor = useMemo(() => {
    if (listData?.professionalTutor === null) return null;
    return master.professionals?.find(
      (x) => x.ID === listData?.professionalTutor?.idTutor
    );
  }, [listData, master]);

  return (
    <TopMenu>
      <div>
        {listData?.professionalTutor && (
          <div className="evaluators">
            <div>
              <Tooltip
                title={professionalTutor?.Name ?? "loading"}
                placement="bottom"
              >
                <img
                  src={
                    professionalTutor?.Login
                      ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${professionalTutor?.Login}_192_192.png`
                      : NoIcon
                  }
                  alt={professionalTutor?.Name}
                />
              </Tooltip>
              Current Tutor
            </div>
          </div>
        )}

        {listData?.professionalTutor === undefined && (
          <div className="status">No tutor assigned</div>
        )}
        <div className="tools">
          <div onClick={() => setIsSidePanelFullScreen((x) => !x)}>
            {!isSidePanelFullScreen ? (
              <GrExpand size={12} />
            ) : (
              <GrContract size={12} />
            )}
          </div>
          <div
            onClick={() => {
              history.push("/" + path + "/");
              setIsSidePanelFullScreen(false);
              setIsSidePanelOpen(false);
            }}
          >
            <GrFormClose size={20} />
          </div>
        </div>
      </div>
      <div className="profile">
        <div>
          <img
            className="professional"
            src={
              professional?.Login
                ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${professional?.Login}_192_192.png`
                : NoIcon
            }
            alt={professional?.Name}
          />
          <div>
            <div>
              <h2>{professional?.Name}</h2>
              <div className="profile-info">
                <h3>
                  <b>{professional?.JobTitle.Name}</b> |{" "}
                  {professional?.Practices[0]?.Name}
                </h3>

                <OfficeIcon
                  className="office-icon"
                  office={professional?.Offices[0]?.Name.toLowerCase()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-wrapper">
          {listData?.professionalTutor && (
            <Button primary onClick={() => setOpenAddTalk(true)}>
              Add Talk
            </Button>
          )}
        </div>
      </div>
    </TopMenu>
  );
}
