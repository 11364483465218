import styled from "styled-components";

export const ContainerSidePanel = styled.div`
  width: calc(100vw - 60px);
  height: 100vh;
  display: flex;
`;

export const Container = styled.div`
  background-color: #efefef;
  width: 100%;
  height: 100%;

  .body {
    padding: 55px 0;
    height: calc(100vh - 96px);
    overflow: auto;

    .dashboard {
      margin: 30px 30px;
      display: flex;
      flex-direction: row;

      .dashboardHr {
        width: 100%;
        display: flex;
        flex-direction: column;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 12px;
            font-weight: bold;
            color: #999999;
            letter-spacing: 0.2em;
          }
        }

        hr {
          height: 0.2em;
          width: 100%;
        }
      }
    }
  }
`;

export const ToolArea = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Header = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderAvatar = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;

  img {
    margin-left: 21px;
    max-width: 60px;
    border-radius: 50%;
    margin-right: 10px;
  }

  p {
    color: #8b0304;
    font-weight: 700;
    font-size: 18px;
    word-spacing: 100vw;
    padding: 0;
    margin: 0;
  }

  .welcome {
    font-size: 12px;
    color: #333;
    word-spacing: 100vw;
    padding: 0;
    margin: 0;
    font-weight: 700;
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
`;

export const ListContainer = styled.div`
  .text__do__dont {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  .lista__externa {
    list-style-type: upper-roman;
    margin-left: 1.5em;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .lista__interna {
    margin-left: 2.25em;
    font-size: 0.85rem;
    font-weight: 500;
  }
  li::marker {
    font-weight: bold;
  }
`;
