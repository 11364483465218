// ReportTypes.js
import { useMemo } from "react";

export default function useReportTypes({
  section,
  cycle,
  cycles,
  areaType,
  lastCycles,
  skillType,
  year,
}) {
  return useMemo(() => {
    switch (section) {
      case "evaluations":
        return [
          {
            type: "All Evaluations",
            blobApi: `api/reports/all-evaluations?idCycle=${cycle}`,
            cycleApi: `api/cycles?idAreaType=${areaType}`,
            fileName: "report-all-evaluations",
          },
          {
            type: "Evaluation Statuses",
            cycleApi: `api/cycles?idAreaType=${areaType}`,
            blobApi: `api/reports/evaluations-status?idCycle=[${cycles?.join(
              ","
            )}]`,
            fileName: "report-evaluation-statuses",
          },
          {
            type: "Evaluation Conclusions",
            cycleApi: `api/cycles?idAreaType=${areaType}`,
            blobApi: `api/reports/evaluations-conclusions?idCycle=[${cycles?.join(
              ","
            )}]`,
            fileName: "report-evaluation-conclusions",
          },
          {
            type: "Evaluation Legacy",
            cycleApi: `api/cycles?idAreaType=${areaType}`,
            blobApi: `api/legacy-evaluations?type=${
              areaType == 1 ? 3 : 2
            }&idCycle=${cycle}`,
            fileName: "report-evaluation-legacy",
          },
          {
            type: "Senior Projects",
            blobApi: `api/reports/senior-projects?idCycle=${cycle}`,
            cycleApi: `api/cycles?idAreaType=${areaType}`,
            fileName: "report-senior-projects",
          },
          {
            type: "Senior Evaluation Delivery",
            cycleApi: `api/cycles?idAreaType=${areaType}`,
            blobApi: `api/reports/senior-evaluation-delivery?idCycle=${cycle}`,
            fileName: "report-senior-evaluation-delivery",
          },
        ];
      case "promotions":
        return [
          {
            type: "All Promotions",
            cycleApi: `api/promotioncycle?idAreaType=${areaType}`,
            blobApi: `api/reports/all-promotions?idCycle=${cycle}`,
            fileName: "report-all-promotions",
          },
          {
            type: "Action Plans",
            cycleApi: `api/promotioncycle?idAreaType=${areaType}`,
            blobApi: `api/reports/action-plans?idCycle=${cycle}`,
            fileName: "report-action-plans",
          },
          {
            type: "Pre-Promotion",
            cycleApi: `api/promotioncycle?idAreaType=${areaType}`,
            blobApi: `api/reports/prepromotion?idCurrentCycle=${cycle}&idLastCycles=[${lastCycles?.join(
              ","
            )}]`,
            fileName: "report-pre-promotion",
          },
        ];
      case "dosanddonts":
        return [
          {
            type: "All Do's & Don'ts",
            blobApi: `api/reports/all-dosdonts`,
            fileName: "report-all-dosdonts",
          },
          {
            type: "Do's & Don'ts skills",
            blobApi: `api/reports/dodontskills?idSkillType=${skillType}`,
            fileName: `report-dodont-${skillType}`,
          },
        ];
      case "counseling":
        return [
          {
            type: "All Counselings",
            blobApi: `api/reports/all-counselings?year=${year}`,
            fileName: `report-all-counselings-${year}`,
          },
          {
            type: "Counseling Status",
            blobApi: `api/reports/counseling-status?year=${year}`,
            fileName: `report-counseling-status-${year}`,
          },
        ];
      case "mentorint":
        return [
          {
            type: "All Mentorints",
            blobApi: `api/reports/mentorint`,
            fileName: `report-all-mentorints`,
          },
          {
            type: "Inactive Mentorints",
            blobApi: `api/reports/mentorint/past`,
            fileName: `report-inactive-mentorints`,
          },
          {
            type: "Mentorint Sessions",
            blobApi: `api/reports/mentorint/sessions`,
            fileName: `report-mentorint-sessions`,
          },
        ];
      case "tutorship":
        return [
          {
            type: "All Tutorships",
            blobApi: `api/reports/tutorship`,
            fileName: `report-all-tutorships`,
          },
          {
            type: "Tutorship Sessions",
            blobApi: `api/reports/tutorship/sessions`,
            fileName: `report-tutorship-sessions`,
          },
        ];
      default:
        return [];
    }
  }, [cycle, cycles, areaType, lastCycles, skillType, year, section]);
}
