import React, { useState, useCallback, useEffect } from "react";

import CustomStore from "devextreme/data/custom_store";

import api from "../../../services/api";
import { DataGrid } from "devextreme-react";
import { Column, Editing } from "devextreme-react/data-grid";
import { DataGridWrapper } from "../styles";

export default function AdminPromotionField() {
  const [promotionFieldSource, setPromotionFieldSource] = useState([]);

  const loadPromotionFields = useCallback(async () => {
    const promotionStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/promotionfield");
        return response.data;
      },
      insert: async (data) => {
        await api.post("/api/promotionfield", data);
      },
      update: async (id, data) => {
        await api.put(`/api/promotionfield/${id}`, {
          id,
          descriptionName: data.descriptionName,
          isActive: data.isActive,
          listType: data.listType,
        });
      },
    });

    setPromotionFieldSource(promotionStore);
  }, []);

  useEffect(() => {
    loadPromotionFields();
  }, [loadPromotionFields]);

  return (
    <DataGridWrapper>
      <DataGrid
        height={550}
        dataSource={promotionFieldSource}
        onRowUpdating={(options) => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <Editing mode="row" allowAdding allowUpdating />
        <Column dataField="descriptionName" caption="Description" />
        <Column dataField="listType" caption="List Type" />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption="Is Active"
          alignment="center"
        />
      </DataGrid>
    </DataGridWrapper>
  );
}
