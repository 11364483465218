import { CircularProgress } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import React, { useCallback, useState, useMemo } from "react";
import * as BoxIcons from "react-icons/bi";
import {
  FiAlertTriangle,
  FiBookOpen,
  FiChevronDown,
  FiEdit,
  FiTrash2,
} from "react-icons/fi";
import { useParams } from "react-router-dom";
import pdsGroups from "../../../config/pdsGroups";
import { useAuth } from "../../../hooks/auth";
import DialogAddDoDont from "../../../pages/DoDont/View/DialogAddDoDont";
import DialogDeleteDoDont from "../../../pages/DoDont/View/DialogDeleteDoDont";
import DialogEditDoDont from "../../../pages/DoDont/View/DialogEditDoDont";
import {
  AddDoDont,
  Container,
  SectionBody,
  Timeline,
  TimelineItem,
} from "./styles";

const DoDontList = ({
  items,
  isDo,
  handleOpen,
  handleToggleContent,
  activeItemId,
  allowEdit,
}) => (
  <div className="dosDontsList">
    {allowEdit && (
      <AddDoDont onClick={() => handleOpen({ action: "add", isDo })}>
        <h2>{`NEW ${isDo ? "DO" : "DON'T"}`}</h2>
      </AddDoDont>
    )}
    <Timeline>
      {items?.map((item) => (
        <TimelineItem key={item.id} iconBackgroud="#8b0304">
          <div className="item__title">
            <div className="project__tag">
              {item.classification.icon ? (
                React.createElement(BoxIcons[item.classification.icon])
              ) : (
                <FiBookOpen />
              )}
              <h4 className="project__title">
                {item.classification.description}
              </h4>
            </div>
            {allowEdit && (
              <>
                <div className="icons">
                  <span
                    className="link"
                    onClick={() =>
                      handleOpen({
                        action: "edit",
                        isDo: item.isDo,
                        id: item.id,
                      })
                    }
                  >
                    <FiEdit color="#8b0304" />
                  </span>
                  <span
                    className="link"
                    onClick={() =>
                      handleOpen({
                        action: "delete",
                        id: item.id,
                      })
                    }
                  >
                    <FiTrash2 color="#8b0304" />
                  </span>
                  <span>
                    <FiChevronDown
                      key={item.id}
                      className="link"
                      size={24}
                      color="#CCC"
                      onClick={() => handleToggleContent(item.id)}
                      style={
                        activeItemId === item.id
                          ? { transform: "rotate(0.5turn)" }
                          : { transform: "rotate(0turn)" }
                      }
                    />
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="updateAt__dodont">
            <span>
              {item.createdAt &&
                format(
                  parseISO(item.updatedAt || item.createdAt),
                  "dd MMM yyyy HH:mm"
                )}
            </span>
          </div>
          <div
            className={
              activeItemId === item.id ? "item__text" : "item__text closed"
            }
          >
            <p>{item.subject}</p>
          </div>
        </TimelineItem>
      ))}
    </Timeline>
  </div>
);

export default function DoDontTab({
  doDont,
  isSidePanelFullScreen,
  doDontIsLoading,
  reload,
}) {
  const { user, hasScope } = useAuth();
  const { id } = useParams();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDo, setIsDo] = useState(false);
  const [dodontClassificationId, setDodontClassificationId] = useState(false);
  const [activeDoItemId, setActiveDoItemId] = useState(null);
  const [activeDontItemId, setActiveDontItemId] = useState(null);

  const isUpdatedLongerThan90DaysAgo = useMemo(() => {
    return new Date() - new Date(doDont?.updatedAt) > 7776000000;
  }, [doDont]);

  const handleOpen = useCallback(({ action, isDo, id }) => {
    if (isDo !== undefined) setIsDo(isDo);
    if (id !== undefined) setDodontClassificationId(id);

    switch (action) {
      case "add":
        setOpenAdd(true);
        break;
      case "edit":
        setOpenEdit(true);
        break;
      case "delete":
        setOpenDelete(true);
        break;
      default:
        break;
    }
  }, []);

  const allowEdit =
    hasScope([pdsGroups.Admin]) || user.userId === doDont.idProfessional;

  const handleToggleDoContent = (itemId) => {
    setActiveDoItemId(itemId === activeDoItemId ? null : itemId);
  };
  const handleToggleDontContent = (itemId) => {
    setActiveDontItemId(itemId === activeDontItemId ? null : itemId);
  };

  return (
    <>
      <Container isSidePanelFullScreen={isSidePanelFullScreen}>
        {doDontIsLoading ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <>
            {isUpdatedLongerThan90DaysAgo && (
              <div className="updateDate">
                <FiAlertTriangle
                  color="#C7A634"
                  width="0.798rem"
                  height="0.75rem"
                />
                <h3 className="h3__updateDate">
                  This list has not been updated in over 90 days.
                </h3>
              </div>
            )}
            <SectionBody>
              <div className="dosdonts">
                <DoDontList
                  items={doDont?.doDontClassifications?.filter((x) => x.isDo)}
                  isDo={true}
                  handleOpen={handleOpen}
                  handleToggleContent={handleToggleDoContent}
                  activeItemId={activeDoItemId}
                  allowEdit={allowEdit}
                />
                <DoDontList
                  items={doDont?.doDontClassifications?.filter((x) => !x.isDo)}
                  isDo={false}
                  handleOpen={handleOpen}
                  handleToggleContent={handleToggleDontContent}
                  activeItemId={activeDontItemId}
                  allowEdit={allowEdit}
                />
              </div>
            </SectionBody>
          </>
        )}
      </Container>
      {openAdd && (
        <DialogAddDoDont
          dodontId={id}
          handleClose={() => {
            setOpenAdd(false);
            reload();
          }}
          isDo={isDo}
          open={openAdd}
        />
      )}

      {openEdit && (
        <DialogEditDoDont
          dodontClassificationId={dodontClassificationId}
          dodontId={id}
          handleClose={() => {
            setOpenEdit(false);
            reload();
          }}
          isDo={isDo}
          open={openEdit}
        />
      )}

      {openDelete && (
        <DialogDeleteDoDont
          dodontClassificationId={dodontClassificationId}
          handleClose={() => {
            setOpenDelete(false);
            reload();
          }}
          open={openDelete}
        />
      )}
    </>
  );
}
