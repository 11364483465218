import { parseISO } from "date-fns";
import jwt from "jsonwebtoken";
import React, { createContext, useCallback, useState, useContext } from "react";

import { config } from "../config/msAuth";

import api from "../services/master";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [data] = useState(() => {
    const token = localStorage.getItem("accessToken");

    if (token && token !== "undefined" && token !== "null") {
      const {
        scopes,
        userName,
        userEmail,
        exp,
        practiceId,
        comanagementId,
        email,
        businessUnits,
        professionalId,
        officeId,
      } = jwt.decode(token.split(" ")[1]);

      return {
        token: {
          token,
          expiredAt: new Date(exp),
        },
        user: {
          userName,
          userEmail,
          scopes: scopes,
          comanagementId: comanagementId && parseInt(comanagementId),
          login: email.split("@")[0],
          userId: parseInt(professionalId),
          practiceId: practiceId && parseInt(practiceId),
        },
      };
    }

    return {};
  });

  const handleSignInMicrosoft = useCallback(() => {
    const baseUri = `https://login.microsoftonline.com/${config.tenantId}/oauth2/v2.0`;

    const uriPieces = {
      client_id: config.clientId,
      response_type: "token",
      redirect_uri: config.redirectUri,
      scope: config.scopes,
      response_mode: "fragment",
      nonce: config.nonce,
      prompt: "select_account",
    };

    const params = new URLSearchParams(uriPieces).toString();
    const url = `${baseUri}/authorize?${params}`;

    window.location.href = url;
  }, []);

  const handleSignIn = useCallback(async (accessToken) => {
    try {
      const response = await api.post(
        "/auth/signin",
        {
          scopes: ["pds"],
        },
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );

      localStorage.setItem(
        "accessToken",
        "Bearer " + response.data.accessToken
      );
    } catch (err) {
      throw new Error(err);
    }
  }, []);

  const handleSignOut = useCallback(() => {
    localStorage.clear();

    const baseUri = `https://login.microsoftonline.com/${config.tenantId}/oauth2/v2.0`;
    const uriPieces = {
      post_logout_redirect_uri: config.redirectUriSignout,
    };

    const params = new URLSearchParams(uriPieces).toString();
    window.location.href = `${baseUri}/logout?${params}`;
  }, []);

  const isAuthenticated = useCallback(() => {
    const accessToken = localStorage.getItem("accessToken");

    return !!accessToken;
  }, []);

  const hasScope = useCallback(
    (scopes) => {
      return data.user && data.user.scopes.some((x) => scopes.includes(x));
    },
    [data.user]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        redirectMicrosoft: handleSignInMicrosoft,
        signInAuthorize: handleSignIn,
        signOut: handleSignOut,
        isAuthenticated,
        hasScope,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
