import React, { useState } from "react";
import { Container } from "./styles";

function CustomCheckbox({
  id,
  label,
  checked,
  onChange,
  children,
  disabled,
  style,
}) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <Container style={style}>
      <label htmlFor={`checkbox-${id}`}>
        {label} {children}
      </label>

      <input
        type="checkbox"
        id={`checkbox-${id}`}
        checked={isChecked}
        onChange={handleCheckboxChange}
        disabled={disabled}
      />
    </Container>
  );
}

export default CustomCheckbox;
