import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useCallback, useContext, useState } from "react";
import Button from "../../../../components/Button";
import { TriggerContext } from "../../../../context/TriggerProvider";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import { Confirmation } from "./styles";

export default function DialogDeleteDoDont({
  dodontClassificationId,
  handleClose,
  open,
}) {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const { triggerFunction } = useContext(TriggerContext);

  const handleConfirm = useCallback(() => {
    setLoading(true);

    api
      .delete(`api/dodontclassification/${dodontClassificationId}`)
      .then(() => {
        triggerFunction();
        addToast({
          type: "success",
          title: "Do/Don't deleted successfully",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          title: "Ops",
          description: "Something went wrong",
        });
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  }, [dodontClassificationId, handleClose]);

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <Confirmation>
          <p>Are you sure you want to delete this?</p>
        </Confirmation>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleConfirm}
          disabled={loading}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#8b0304",
            color: "#fff",
            gap: 5,
          }}
        >
          {loading && <CircularProgress size="1em" />}
          Delete
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
