import React, { useState, useContext, useMemo } from "react";
import PhotoCell from "../../EvaluationList/CellRender/PhotoCell";
import { ValueContext } from "../../../context/ValueProvider";
import { Container } from "./styles";
import TextArea from "../../TextArea";
import { Tooltip } from "@material-ui/core";
import PDCIcon from "../../../assets/images/pdc_icon.svg";

export default function TimelineItem({
  comment,
  professionalId,
  handleTalkEdit,
  reverse,
  disabled,
}) {
  const { master } = useContext(ValueContext);
  const [professional, setProfessional] = useState();

  const photo = useMemo(() => {
    if (professionalId) {
      const commentProfessional = master.professionals?.find(
        (x) => x.ID === professionalId
      );
      setProfessional(commentProfessional);

      return <PhotoCell data={commentProfessional} />;
    } else {
      return (
        <Tooltip title="Professional Development Cell" placement="top">
          <img
            style={{
              borderRadius: "50%",
              width: 24,
              height: 24,
              background: "#EEE",
            }}
            src={PDCIcon}
            alt="PDC"
          />
        </Tooltip>
      );
    }
  }, [master.professionals, professionalId]);

  return (
    <Container reverse={reverse}>
      <TextArea
        caption={
          professionalId ? professional?.Name : "Professional Development Cell"
        }
        value={comment}
        onChanged={(value) => handleTalkEdit(value)}
        disabled={disabled}
        // disabled
      />
      <div className="timeline-item-commands">
        <div className="photo">{photo}</div>
      </div>
    </Container>
  );
}
