import React, { useCallback } from "react";
import { Container } from "./styles";
import TextArea from "../../TextArea";
import useUpdateField from "../../../hooks/useUpdateField";
import { useAuth } from "../../../hooks/auth";
import pdsGroups from "../../../config/pdsGroups";

export default function Overall({
  evaluation,
  idEvaluation,
  isSidePanelFullScreen,
}) {
  const { user, hasScope } = useAuth();
  const { updateField } = useUpdateField();

  const handleTextChange = useCallback(
    (key, value) => {
      updateField(
        "put",
        `/api/evaluations/${idEvaluation}`,
        {
          [key]: value,
        },
        "updateText"
      );
    },
    [idEvaluation, updateField]
  );

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {evaluation.idProfessional !== user.userId ||
      (evaluation.idProfessional === user.userId &&
        (evaluation.evaluationStatus.id === 4 ||
          evaluation.evaluationStatus.id === 5)) ? (
        <>
          {(evaluation?.idAreaType === 2 ||
            evaluation?.cycle?.idAreaType === 2) && (
            <TextArea
              caption="Project Context"
              value={evaluation.projectContext}
              onChanged={(value) => handleTextChange("projectContext", value)}
              disabled={
                (((evaluation.idEvaluator !== user.userId &&
                  evaluation.idValidator !== user.userId &&
                  evaluation.idPartner !== user.userId) ||
                  evaluation.evaluationStatus.id === 4 ||
                  evaluation.evaluationStatus.id === 5 ||
                  user.userId === evaluation.idProfessional) &&
                  !hasScope(pdsGroups.Admin)) ||
                evaluation.isLocked === true
              }
            />
          )}
          <TextArea
            caption="Overview"
            value={evaluation?.overview}
            onChanged={(value) => handleTextChange("overview", value)}
            disabled={
              (((evaluation.idEvaluator !== user.userId &&
                evaluation.idValidator !== user.userId &&
                evaluation.idPartner !== user.userId) ||
                evaluation.evaluationStatus.id === 4 ||
                evaluation.evaluationStatus.id === 5 ||
                user.userId === evaluation.idProfessional) &&
                !hasScope(pdsGroups.Admin)) ||
              evaluation.isLocked === true
            }
          />
        </>
      ) : (
        <p>
          You can not see your overall yet. You have to wait until your
          evaluation is finished.
        </p>
      )}
    </Container>
  );
}
