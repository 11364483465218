import React, { useState, useContext } from "react";
import { Container } from "./styles";
import { SelectBox } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import api from "../../../services/api";
import useUpdateField from "../../../hooks/useUpdateField";
import { useMemo } from "react";
import { useAuth } from "../../../hooks/auth";
import pdsGroups from "../../../config/pdsGroups";
import TextArea from "../../TextArea";
import { useCallback } from "react";
import { useToast } from "../../../hooks/toast";
import master from "../../../services/master";
import { useEffect } from "react";
import { TriggerContext } from "../../../context/TriggerProvider";

export default function Details({ promotion, isSidePanelFullScreen }) {
  const { updateField } = useUpdateField();
  const { hasScope } = useAuth();
  const { addToast } = useToast();
  const [promotionFields, setPromotionFields] = useState([]);
  const { trigger, triggerFunction } = useContext(TriggerContext);

  const allowEdit = useMemo(() => hasScope(pdsGroups.Admin), [hasScope]);

  const handleSelectBoxChange = async (key, value) => {
    await updateField("put", `/api/promotion/${promotion.id}`, {
      [key]: value,
    });
    triggerFunction();
  };

  const [professionals] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await master.get("master/professionals");
        return response.data;
      },
    }),
    paginate: true,
  });

  const [promotionStatuses] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("api/promotion-status");
        const cyclesWithDisplayName = data.map((cycle) => ({
          ...cycle,
          displayName: `${cycle.period}Q - ${cycle.currentYear}`,
        }));

        return cyclesWithDisplayName;
      },
    }),
    paginate: true,
  });

  const [clusterSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("/api/clusters");
        return response.data;
      },
    }),
    paginate: true,
  });

  const loadPromotionFields = useCallback(async () => {
    const response = await api.get("/api/promotionfield");
    setPromotionFields(response.data.filter((x) => x.isActive));
    return response.data;
  }, []);

  useEffect(() => {
    loadPromotionFields();
  }, [loadPromotionFields]);

  const handleSavePromotionFieldText = useCallback(
    async (fieldId, value) => {
      await api.patch(`/api/promotions/${promotion.id}/fields/${fieldId}`, {
        fieldText: value,
      });

      addToast({
        type: "success",
        title: "Saved",
      });
    },
    [addToast, promotion.id]
  );

  const handleSavePromotionFieldValue = useCallback(
    async (fieldId, value) => {
      await api.patch(`/api/promotions/${promotion.id}/fields/${fieldId}`, {
        fieldValue: parseInt(value),
      });

      addToast({
        type: "success",
        title: "Saved",
      });
    },
    [addToast, promotion.id]
  );

  return (
    <Container isSidePanelFullScreen={isSidePanelFullScreen}>
      {promotion.idStatus !== 0 && (
        <div className="details">
          <strong>Decision</strong>
          <SelectBox
            style={{ width: "100%" }}
            dataSource={promotionStatuses}
            searchEnabled
            displayExpr="description"
            valueExpr="id"
            placeholder="Select status"
            itemRender={(data) => <p>{data.description}</p>}
            required={true}
            defaultValue={promotion.idStatus}
            onValueChanged={(e) => handleSelectBoxChange("idStatus", e.value)}
            disabled={!allowEdit}
          />
        </div>
      )}
      {promotionFields.map((pField) => (
        <div
          key={pField.id}
          className="details"
          style={{
            flexDirection:
              pField.listType !== 1 && pField.listType !== 2 ? "column" : "row",
          }}
        >
          {pField.listType === 1 || pField.listType === 2 ? (
            <>
              <strong>{pField.descriptionName}</strong>

              <SelectBox
                style={{ width: "100%" }}
                searchEnabled
                key={pField.id}
                displayExpr={pField.listType === 1 ? "Name" : "description"}
                valueExpr={pField.listType === 1 ? "ID" : "id"}
                dataSource={
                  pField.listType === 1 ? professionals : clusterSource
                }
                defaultValue={Number(
                  promotion.promotionFieldValue.find(
                    (x) => x.idField === pField.id
                  )?.fieldValue
                )}
                onValueChange={(value) =>
                  handleSavePromotionFieldValue(pField.id, value)
                }
                disabled={!allowEdit}
              />
            </>
          ) : (
            <TextArea
              caption={pField.descriptionName}
              key={pField.id}
              disabled={!allowEdit}
              onChanged={(value) =>
                handleSavePromotionFieldText(pField.id, value)
              }
              value={
                promotion.promotionFieldValue.find(
                  (x) => x.idField === pField.id
                )?.fieldText
              }
            />
          )}
        </div>
      ))}
    </Container>
  );
}
