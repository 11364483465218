import React, { useState, useCallback, useEffect } from "react";

import { Container, TextAreaComponent } from "./styles";

const TextArea = ({
  value: defaultValue,
  onChanged,
  readOnly: _readOnly,
  maxLength,
  disabled,
  style,
  caption,
}) => {
  const [readOnly, setReadOnly] = useState(false);
  const [value, setValue] = useState("");
  const [typingTimeout, setTypingTimeout] = useState();

  const paragraphs = defaultValue?.split("\n").map((paragraph, index) => {
    if (/^[-*+]/.test(paragraph)) {
      return (
        <p key={index} className="list-item">
          {paragraph.substring(1)}
        </p>
      );
    } else {
      return <p key={index}>{paragraph}</p>;
    }
  });

  useEffect(() => {
    setReadOnly(_readOnly);
  }, [_readOnly]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (v) => {
      const newValue = v;
      setValue(newValue || "");

      if (newValue === value) return; // Evita a chamada se o valor não mudou

      if (typingTimeout) clearTimeout(typingTimeout);

      setTypingTimeout(
        setTimeout(() => {
          if (!readOnly && onChanged && newValue !== defaultValue) {
            onChanged(newValue || "");
          }
        }, 15000) // 15 segundos
      );
    },
    [onChanged, readOnly, typingTimeout, value, defaultValue]
  );

  const handleBlur = useCallback(
    (v) => {
      const newValue = v;

      if (typingTimeout) clearTimeout(typingTimeout);

      if (!readOnly && onChanged && newValue !== defaultValue) {
        onChanged(newValue || "");
      }
    },
    [onChanged, readOnly, typingTimeout, defaultValue]
  );

  return (
    <Container style={style} className="int-text-area">
      {disabled ? (
        <fieldset className="textarea disabled">
          <legend>{caption}</legend>
          <div className="faux-textarea">{paragraphs}</div>
        </fieldset>
      ) : (
        <fieldset className="textarea">
          <legend>{caption}</legend>

          <textarea
            disabled={disabled}
            maxLength={maxLength}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={(e) => handleBlur(e.target.value)}
            value={value}
            readOnly={readOnly}
          />
        </fieldset>
      )}
    </Container>
  );
};

export default TextArea;
