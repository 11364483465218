import DataGrid, { Column } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import React, { useCallback, useContext, useMemo, useRef } from "react";
import { TriggerContext } from "../../context/TriggerProvider";
import { ValueContext } from "../../context/ValueProvider";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import PhotoCell from "./CellRender/PhotoCell";
import { Container } from "./styles";
import { useAuth } from "../../hooks/auth";
import pdsGroups from "../../config/pdsGroups";

export default function MenteeEvaluationList({
  isSidePanelFullScreen,
  evaluation,
  idEvaluation,
}) {
  const { master } = useContext(ValueContext);
  const { addToast } = useToast();
  const dataGridRef = useRef(null);
  const { triggersFunction } = useContext(TriggerContext);
  const { hasScope } = useAuth();
  const isAdmin = useMemo(() => hasScope([pdsGroups.Admin]), [hasScope]);

  const evaluationMenteeSourceByTeamDataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          const { data: menteeData } = await api.get(`api/professional-mentor`);

          const mentees = menteeData.filter(
            (item) =>
              item?.professionalMentor?.idMentor === evaluation.idProfessional
          );

          const detailedMentees = await Promise.all(
            mentees.map(async (mentee) => {
              const { data: evaluationData } = await api.get(
                `api/evaluations/${idEvaluation}/mentee/${mentee.id}`
              );

              return {
                ...mentee,
                ...evaluationData,
              };
            })
          );

          return detailedMentees;
        } catch (err) {
          addToast({
            type: "error",
            title: "Mentee's evaluations couldn't be loaded. Try again later",
          });
          throw err;
        }
      },

      update: async (key, values) => {
        try {
          await api.put(
            `api/evaluations/${idEvaluation}/mentee/${key.idMentee}`,
            values
          );

          triggersFunction("updateGrade");
        } catch (err) {
          addToast({
            type: "error",
            title: "Error updating mentee evaluation",
          });
          throw err;
        }
      },

      reshapeOnPush: true,
      loadMode: "raw",
      cacheRawData: true,
    });
  }, [addToast, evaluation.idProfessional, idEvaluation, triggersFunction]);

  const renderPhotoCell = useCallback(
    (e) => {
      const matchedProfessional = master?.professionals?.find(
        (professional) => professional.ID === (e.data.idMentee || e.data.id)
      );

      return <PhotoCell data={matchedProfessional} />;
    },
    [master]
  );

  return (
    <Container
      style={{ width: !isSidePanelFullScreen ? "550px" : "100%", height: 450 }}
    >
      <DataGrid
        dataSource={evaluationMenteeSourceByTeamDataSource}
        ref={dataGridRef}
        key="id"
        height="100%"
        width="100%"
        headerFilter={{ visible: true, allowSearch: true }}
        onRowClick={(e) => {
          if (e.rowType === "data" && isAdmin) {
            dataGridRef.current.instance.editRow(e.rowIndex);
          }
        }}
        editing={{
          mode: "popup",
          allowUpdating: true,
          form: {
            items: [
              {
                dataField: "idMentee",
                colSpan: 2,
              },
              {
                dataField: "isApplicable",
                editorType: "dxCheckBox",
                editorOptions: {
                  allowIndeterminateState: false,
                },
              },
              {
                dataField: "doYouFeelComfortable",
                editorType: "dxCheckBox",
                editorOptions: {
                  allowIndeterminateState: false,
                },
              },
              {
                dataField: "menteeEvaluation",
                editorType: "dxTextArea",
                colSpan: 2,
              },
            ],
          },
          popup: {
            title: "Edit Evaluation",
            showTitle: true,
            width: 700,
            height: 525,
          },
        }}
      >
        <Column
          dataField="idMentee"
          caption="Mentee"
          alignment="center"
          cellRender={renderPhotoCell}
          lookup={{
            dataSource: master.professionals,
            displayExpr: "Name",
            valueExpr: "ID",
          }}
        />
        <Column
          dataField="isActive"
          caption="Is Active?"
          dataType="boolean"
          alignment="center"
        />
        <Column
          dataField="isApplicable"
          caption="Considered?"
          dataType="boolean"
          alignment="center"
          visible={false}
        />
        <Column
          dataField="doYouFeelComfortable"
          caption="Do you feel comfortable to evaluate this professional?"
          dataType="boolean"
          alignment="center"
          visible={false}
        />
        <Column
          dataField="menteeEvaluation"
          caption="Has the professional contributed as a mentor to your professional development during this cycle? Please give examples."
          dataType="string"
          editorType="dxTextArea"
          visible={false}
        />
      </DataGrid>
    </Container>
  );
}
