import React, { useEffect, useState, useCallback, useMemo } from "react";
import CustomStore from "devextreme/data/custom_store";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import useReportTypes from "./useReportTypes";
import ReportGenerator from "./ReportGenerator";

const currentYear = new Date().getFullYear();
const yearsSource = Array.from({ length: 21 }, (_, i) => currentYear - 10 + i);

export default function Report({ section }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [cycle, setCycle] = useState(null);
  const [cycles, setCycles] = useState(null);
  const [lastCycles, setLastCycles] = useState(null);
  const [areaType, setAreaType] = useState(null);
  const [skillType, setSkillType] = useState(null);

  const [year, setYear] = useState(null);

  const { addToast } = useToast();

  const areaTypeSource = useMemo(() => {
    return new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("api/area-types");

        if (selectedType === "Senior Projects")
          return response.data.filter((x) => x.id !== 1 && x.id !== 2);

        if (selectedType === "Senior Evaluation Delivery")
          return response.data.filter((x) => x.isWeightedAverage);

        return response.data;
      },
    });
  }, [selectedType]);

  const cycleSource = useMemo(() => {
    return new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        if (!areaType) return [];
        const selectedTypeObject = reportTypes.find(
          (x) => x.type === selectedType
        );
        const response = await api.get(selectedTypeObject.cycleApi);
        const cycles = response.data;
        const cyclesWithDisplaytype = cycles.map((cycle) => ({
          ...cycle,
          displaytype: `${cycle.period}Q - ${cycle.year ?? cycle.currentYear}`,
        }));

        return cyclesWithDisplaytype;
      },
    });
  }, [areaType]);

  const lastCycleSource = useMemo(() => {
    return new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const data = await cycleSource.load();
        return data.filter((item) => item.id < cycle);
      },
    });
  }, [cycle, cycleSource]);

  const skillTypeSource = useMemo(() => {
    return new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("api/skill-type");
        return response.data.filter((x) => x.isActive);
      },
    });
  }, []);

  useEffect(() => {
    setLastCycles(null);
    setCycle(null);
    setCycles(null);
    setLastCycles(null);
    setSkillType(null);
  }, [areaType]);

  useEffect(() => {
    setAreaType(null);
    setLastCycles(null);
    setCycle(null);
    setCycles(null);
    setSkillType(null);
    setYear(null);
  }, [selectedType]);

  const reportTypes = useReportTypes({
    section,
    cycle,
    cycles,
    areaType,
    lastCycles,
    skillType,
    year,
  });

  const handleGenerate = useCallback(async () => {
    try {
      setIsGenerating(true);
      addToast({
        type: "info",
        title: "We are generating your report. It could take a while",
      });

      const selectedTypeObject = reportTypes.find(
        (x) => x.type === selectedType
      );
      const response = await api.get(selectedTypeObject.blobApi, {
        responseType: "blob",
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${selectedTypeObject.fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: "success",
        title: "Your report is ready.",
      });
    } catch (err) {
      addToast({
        type: "error",
        title:
          "Something went wrong when generating your report. Try again please.",
      });
    } finally {
      setIsGenerating(false);
    }
  }, [areaType, cycle, selectedType, reportTypes]);

  return (
    <ReportGenerator
      reportTypes={reportTypes}
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      section={section}
      yearsSource={yearsSource}
      year={year}
      setYear={setYear}
      areaTypeSource={areaTypeSource}
      areaType={areaType}
      setAreaType={setAreaType}
      cycleSource={cycleSource}
      cycle={cycle}
      setCycle={setCycle}
      cycles={cycles}
      setCycles={setCycles}
      skillTypeSource={skillTypeSource}
      skillType={skillType}
      setSkillType={setSkillType}
      lastCycleSource={lastCycleSource}
      lastCycles={lastCycles}
      setLastCycles={setLastCycles}
      handleGenerate={handleGenerate}
      isGenerating={isGenerating}
    />
  );
}
