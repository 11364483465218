import React from "react";
import ContentLoader from "react-content-loader";

export default function SidePanelLoader({ width }) {
  return (
    <div style={{ width: "100%", height: "100%", padding: "20px" }}>
      <ContentLoader
        speed={2}
        width={width}
        viewBox="0 0 695 800"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="52" rx="7" ry="7" width="695" height="97" />
        <rect x="348" y="9" rx="7" ry="7" width="262" height="19" />
        <circle cx="19" cy="19" r="19" />
        <rect x="43" y="9" rx="7" ry="7" width="59" height="19" />
        <circle cx="130" cy="19" r="19" />
        <rect x="154" y="9" rx="7" ry="7" width="59" height="19" />
        <circle cx="241" cy="19" r="19" />
        <rect x="265" y="9" rx="7" ry="7" width="59" height="19" />
        <rect x="0" y="313" rx="7" ry="7" width="256" height="24" />
        <rect x="0" y="512" rx="7" ry="7" width="256" height="24" />
        <rect x="0" y="712" rx="7" ry="7" width="256" height="24" />
        <rect x="578" y="712" rx="7" ry="7" width="117" height="24" />
        <rect x="612" y="668" rx="7" ry="7" width="83" height="24" />
        <rect x="578" y="634" rx="7" ry="7" width="117" height="24" />
        <rect x="612" y="595" rx="7" ry="7" width="83" height="24" />
        <rect x="578" y="556" rx="7" ry="7" width="117" height="24" />
        <rect x="612" y="474" rx="7" ry="7" width="83" height="24" />
        <rect x="578" y="435" rx="7" ry="7" width="117" height="24" />
        <rect x="612" y="396" rx="7" ry="7" width="83" height="24" />
        <rect x="578" y="352" rx="7" ry="7" width="117" height="24" />
        <rect x="0" y="358" rx="7" ry="7" width="135" height="24" />
        <rect x="0" y="556" rx="7" ry="7" width="135" height="24" />
        <rect x="0" y="756" rx="7" ry="7" width="135" height="24" />
        <rect x="0" y="396" rx="7" ry="7" width="256" height="24" />
        <rect x="0" y="595" rx="7" ry="7" width="256" height="24" />
        <rect x="0" y="474" rx="7" ry="7" width="256" height="24" />
        <rect x="0" y="672" rx="7" ry="7" width="256" height="24" />
        <rect x="0" y="435" rx="7" ry="7" width="367" height="24" />
        <rect x="0" y="634" rx="7" ry="7" width="367" height="24" />
        <rect x="3" y="163" rx="17" ry="17" width="105" height="33" />
        <rect x="121" y="163" rx="17" ry="17" width="105" height="33" />
        <rect x="238" y="163" rx="17" ry="17" width="105" height="33" />
        <rect x="356" y="163" rx="17" ry="17" width="105" height="33" />
        <rect x="473" y="163" rx="17" ry="17" width="105" height="33" />
        <rect x="591" y="163" rx="17" ry="17" width="105" height="33" />
        <rect x="618" y="9" rx="7" ry="7" width="77" height="19" />
      </ContentLoader>
    </div>
  );
}

export function ListLoader({ width }) {
  return (
    <div style={{ width: "100%", height: "100%", padding: "20px" }}>
      <ContentLoader
        speed={2}
        width={width}
        viewBox="0 0 1200 400"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
        <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
        <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
        <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
        <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
        <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
        <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />

        <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
        <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
        <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
        <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
        <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
        <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
        <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />

        <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
        <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
        <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
        <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
        <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
        <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
        <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />

        <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
        <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
        <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
        <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
        <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
        <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
        <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />

        <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
        <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
        <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
        <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
        <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
        <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
        <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />

        <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
        <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
        <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
        <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
        <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />

        <circle cx="37" cy="97" r="11" />
        <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
        <circle cx="77" cy="96" r="11" />
      </ContentLoader>
    </div>
  );
}
