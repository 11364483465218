import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import DataGrid, { Column, Editing, Sorting } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button";

import api from "../../../../../services/api";

export default function DialogPermission({ type, id, handleClose, open }) {
  const [permissionSource, setPermissionSource] = useState();

  useEffect(() => {
    const permissionStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("/api/evaluations-permissions", {
          params: {
            [type]: id,
          },
        });
        return [data];
      },
      insert: async (data) => {
        await api.post("/api/evaluations-permissions", {
          ...data,
          [type]: id,
        });
      },
      update: async (id, data) => {
        await api.put(`/api/evaluations-permissions/${id}`, data);
      },
      remove: async (id) => {
        await api.delete(`/api/evaluations-permissions/${id}`);
      },
    });

    setPermissionSource(
      new DataSource({
        store: permissionStore,
        paginate: true,
        reshapeOnPush: true,
      })
    );
  }, [id, type]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>Permissions</DialogTitle>
      <DialogContent>
        <DataGrid dataSource={permissionSource} showBorders={false} key="id">
          <Sorting mode="none" />
          <Editing mode="cell" allowUpdating />
          <Column dataField="id" allowEditing={false} visible={false} />
          <Column
            dataField="isActive"
            dataType="boolean"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="pillar.name"
            caption="Pillar"
            visible={type === "idPillar"}
            allowEditing={false}
          />
          <Column
            dataField="competence.description"
            caption="Competence"
            visible={type === "idCompetence"}
            allowEditing={false}
          />
          <Column
            dataField="competenceSenior.description"
            caption="Sub-Competence"
            visible={type === "idCompetenceSenior"}
            allowEditing={false}
          />
          <Column dataField="isSales" caption="Sales access" />
          <Column dataField="isDelivery" caption="Delivery access" />
          <Column dataField="isKNM" caption="KNM access" />
          <Column dataField="isCoLeader" caption="CoLeader access" />
          <Column
            dataField="isSelfEvaluation"
            caption="Self evaluation access"
          />
          <Column
            dataField="isEvaluationCommittee"
            caption="Evaluation Committee access"
          />
          <Column
            dataField="isCultureAndProfessionalCommittee"
            caption="CPC access"
          />
          <Column
            dataField="isWeeklyManagementMeeting"
            caption="Weekly Management access"
          />
        </DataGrid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
