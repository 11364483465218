import DataGrid, { Column } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import PhotoCell from "../EvaluationList/CellRender/PhotoCell";
import { Container, StatusCell } from "../EvaluationList/styles";
import OfficeIcon from "../OfficeIcons";
import { useAuth } from "../../hooks/auth";

export default function DosAndDontsList() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const dataGridRef = useRef(null);
  const [activeSkillTypes, setActiveSkillTypes] = useState(null);
  const [doesHaveDosDontsEntry, setDoesHaveDosDontsEntry] = useState(true);

  useEffect(() => {
    const fetchActiveSkillTypes = async () => {
      try {
        const response = await api.get("/api/skill-type");
        setActiveSkillTypes(
          response.data.filter((skillType) => skillType.isActive)
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchActiveSkillTypes();
  }, []);

  const dataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          const { data } = await api.get("/api/dodont");
          if (!data.find((entry) => entry.idProfessional === user.userId)) {
            setDoesHaveDosDontsEntry(false);
          }
          return data;
        } catch (err) {
          addToast({
            type: "error",
            title: "Do's and Dont's couldn't be loaded. Try again later",
          });
          throw err;
        }
      },
      insert: async (values) => {
        try {
          await api.post("/api/dodont", values);
        } catch (err) {
          addToast({
            type: "error",
            title: "Error inserting evaluation",
          });
          throw err;
        }
      },
      update: async (key, values) => {
        try {
          await api.put(`/api/dodont/${key}`, values);
        } catch (err) {
          addToast({
            type: "error",
            title: "Error updating evaluation",
          });
          throw err;
        }
      },
      loadMode: "raw",
      cacheRawData: true,
      paginate: true,
    });
  }, [addToast, user.userId]);

  const handleRowClick = (e) => {
    history.push(`${e?.data?.id}`);
  };

  const renderSkillCount = (skillType) => (e) => {
    const skills = e.data.skills || [];
    const skillsOfType = skills.filter(
      (skill) => skill.idSkillType === skillType
    );
    const countOfType = skillsOfType.length;
    return <>{countOfType}</>;
  };

  const handleCreateDoDontEntry = useCallback(async () => {
    await api.post("/api/dodont", {
      idProfessional: user.userId,
      travelAgreement: false,
      agreeWithTerms: false,
    });
    dataSource.reload();
  }, [dataSource, user.userId]);

  const onToolbarPreparing = useCallback(
    (e) => {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          widget: "dxButton",
          options: {
            text: "Clear Filters",
            elementAttr: { class: "clear-filter" },
            icon: "filter",
            onClick: () => {
              e.component.clearFilter();
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            visible: !doesHaveDosDontsEntry,
            hint: "Create",
            icon: "add",
            onClick: () => {
              handleCreateDoDontEntry();
            },
          },
        }
      );
    },
    [doesHaveDosDontsEntry, handleCreateDoDontEntry]
  );

  return (
    <Container>
      <DataGrid
        dataSource={dataSource}
        ref={dataGridRef}
        width="100%"
        height="100%"
        headerFilter={{ visible: true, allowSearch: true }}
        sorting={{ mode: "multiple" }}
        editing={{ mode: "popup" }}
        onRowClick={(e) => handleRowClick(e)}
        allowColumnResizing
        columnAutoWidth
        onToolbarPreparing={(e) => onToolbarPreparing(e)}
      >
        <Column
          dataField="professional.id"
          caption="Has Don't"
          cellRender={(e) => (
            <StatusCell status={e.data.hasDont === "Yes" ? false : 6}>
              <PhotoCell data={e.data.professional} />
              {e.data.hasDont === "Yes" ? "Yes" : "No"}
            </StatusCell>
          )}
          width={100}
          alignment="left"
        />
        <Column
          dataField="professional.name"
          caption="Professional"
          alignment="left"
          width={120}
        />

        <Column
          dataField="professional.jobTitle.name"
          caption="Position"
          alignment="left"
          width={100}
        />
        <Column
          dataField="professional.practices[0].abbreviation"
          caption="Practice"
          alignment="center"
          width={100}
        />

        <Column
          dataField="professional.offices[0].name"
          cellRender={(e) => (
            <OfficeIcon
              office={
                e.data.professional?.offices[0]?.name
                  ? e.data.professional?.offices[0]?.name
                  : null
              }
              className="office-icon"
            />
          )}
          caption="Office"
          alignment="center"
          width={100}
        />

        {activeSkillTypes?.map((skillType) => (
          <Column
            dataField="skills"
            key={skillType.id}
            name={skillType.description}
            cellRender={renderSkillCount(skillType.id)}
            caption={skillType.description}
            alignment="center"
          />
        ))}

        <Column
          dataField="updatedAt"
          dataType="date"
          format="dd MMM yyyy"
          alignment="center"
          width={130}
        />
      </DataGrid>
    </Container>
  );
}
