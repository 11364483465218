import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: left;
  justify-content: flex-start;

  img {
    max-width: 35px;
    max-height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    filter: brightness(95%);
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;

    p {
      margin: 0;
      font-size: 12px;
      color: #999;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 9.375rem;
      flex-shrink: 0;
    }

    .name {
      color: #333;

      font-family: Roboto Condensed;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.81038rem;
    }
    .jobtitle {
      width: 9.375rem;
      flex-shrink: 0;
      color: #333;
      font-family: Roboto Condensed;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.81038rem;
    }
  }
`;
